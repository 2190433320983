import React, { useEffect, useState } from 'react';
import {
  AppLayout,
  Button,
  Container,
  Flashbar,
  FlashbarProps,
  Header,
  Link,
  Pagination,
  SpaceBetween,
  Table,
  TableProps
} from '@amzn/awsui-components-react';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from '../GenericComponents/ErrorFallback';
import { MappingsTable } from './MappingsTable';
import { MappingsToolInfo } from './MappingsTool';
import { MappingSideNavigation } from './MappingSideNavigation';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ENV_CONSTANTS, useAuth } from 'src/utils/AuthProvider';
import { useAppContext } from '../AppContextProvider';
import { AppBreadcrumb } from '../Navigation/AppBreadcrumb';
import { BaseBreadcrumbs } from 'src/constants/FDAConstants';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { MAPPINGS_CONFIG_COLUMN_DEFINITIONS } from './MappingsConfigurationConfig';
import { getMappingsData, postMapData } from 'src/services/FDAServices';
import { MappingDataEntity } from './Models/MappingModel';
import { MappingsConfigurationContext } from './MappingsConfigContext';
import { logger } from 'src/utils/FDALogger';
import {
  MAPPING_CONFIG_MASTER_ALIAS,
  MAPPING_CONFIG_MASTER_TABLE_NAME,
  MAPPING_CONFIG_STATUS_TABLE_NAME,
  MAPPING_CONFIGURATIONS_BETA,
  MAPPING_CONFIGURATIONS_PROD
} from './MappingsConfigValidations';

export const getUserBreadcrumbs = (mappingConfigTableAlias: any) => {
  if (mappingConfigTableAlias) {
    return [
      ...BaseBreadcrumbs,
      {
        text: 'Mapping Configuration',
        href: '/mappings/mappings-configuration'
      },
      {
        text: mappingConfigTableAlias,
        href: '#!'
      }
    ];
  } else {
    return [
      ...BaseBreadcrumbs,
      {
        text: 'Mapping Configuration',
        href: '/mappings/mappings-configuration'
      }
    ];
  }
};

export const MappingsConfiguration = () => {
  const [toolsOpen, setToolsOpen] = useState(false);
  const userDetails = useAuth();
  const appContext = useAppContext();
  const stage = ENV_CONSTANTS.ENVIRONMENT_VARIABLES.Stage;
  const [flashbarItems, setFlashbarItems] = useState<FlashbarProps.MessageDefinition[]>([]);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { mappingConfigTableAlias } = useParams();

  const [breadcrumbs, setBreadcrumbs] = useState<any[]>([]);

  const [mappingConfigurationsLoading, setMappingConfigurationsLoading] = useState(false);
  const [mappingConfigurations, setMappingConfigurations] = useState<MappingDataEntity[]>([]);
  const [selectedMappingConfigurations, setSelectedMappingConfigurations] = useState<MappingDataEntity[]>([]);

  // Table column definitions and column widths
  const [columnDefinitions, setColumnDefinitions] = useState(MAPPINGS_CONFIG_COLUMN_DEFINITIONS);

  useEffect(() => {
    setSelectedMappingConfigurations([]);
    fetchMappingsConfigurations();
  }, []);

  useEffect(() => {
    setBreadcrumbs(getUserBreadcrumbs(mappingConfigTableAlias));
  }, [mappingConfigTableAlias]);

  const notificationHandler = (status: boolean, notificationMessage: string, notificationType: FlashbarProps.Type) => {
    displayFlashMessage(status, notificationMessage, notificationType);
  };

  const displayFlashMessage = (loading: boolean, content: string, flashbarType: FlashbarProps.Type) => {
    setFlashbarItems([
      {
        loading: loading,
        type: flashbarType,
        content: content,
        dismissible: true,
        dismissLabel: 'Dismiss message',
        onDismiss: () => setFlashbarItems([])
      }
    ]);
  };

  const fetchMappingsConfigurations = async () => {
    setMappingConfigurationsLoading(true);
    getMappingsData(MAPPING_CONFIG_MASTER_ALIAS)
      .then((mappingsResponse) => {
        logger.info('Fetching Mappings Configuration Master - Response', { info: mappingsResponse?.mappingData });
        const mappings = mappingsResponse?.mappingData?.filter(
          (mapping: any) => mapping.table_nm !== MAPPING_CONFIG_MASTER_TABLE_NAME && mapping.table_nm !== MAPPING_CONFIG_STATUS_TABLE_NAME
        );
        setMappingConfigurations(mappings);
        setMappingConfigurationsLoading(false);
      })
      .catch((error: any) => {
        setMappingConfigurationsLoading(false);
        logger.error('Unable to fetch the Mappings Configurations.', error);
        notificationHandler(false, 'Unable to fetch the Mappings Configurations.', 'error');
      });
  };

  const refreshMappingsConfigurations = () => {
    setSelectedMappingConfigurations([]);
    fetchMappingsConfigurations();
  };

  const newMapping = () => {
    navigate('/mappings/mappings-configuration' + '/new-mapping');
  };

  const removeSelected = () => {
    notificationHandler(true, 'Removing selected mappings', 'info');

    let updatedMappingsEntity: MappingDataEntity[] = mappingConfigurations.filter(
      (mappingConfiguration) => mappingConfiguration.table_nm !== selectedMappingConfigurations[0].table_nm
    );

    updatedMappingsEntity =
      stage !== 'prod' ? MAPPING_CONFIGURATIONS_BETA.concat(updatedMappingsEntity) : MAPPING_CONFIGURATIONS_PROD.concat(updatedMappingsEntity);
    logger.info('Removing selected mappings ', { info: JSON.stringify(updatedMappingsEntity) });
    postMapData(updatedMappingsEntity, MAPPING_CONFIG_MASTER_ALIAS)
      .then((response) => {
        notificationHandler(false, 'Request added to the job queue. You will receive email once it is completed.', 'success');
        logger.info(`Submitting the request to remove mapping configuration - Response - ${selectedMappingConfigurations[0].table_nm} `, {
          info: response
        });
        refreshMappingsConfigurations();
      })
      .catch((error: any) => {
        logger.error(`Submitting the request to remove mapping configuration - Response - ${selectedMappingConfigurations[0].table_nm} `, error);
        notificationHandler(false, 'Request failed. Please try again or reach out to admin.', 'error');
      });
  };

  // When user selects a row using Radio button
  const tableSelectionChanged = (detail: any) => {
    setSelectedMappingConfigurations(detail.selectedItems);
  };

  // When user selects a row by clicking anywhere in the row
  const onRowClickChanged = (detail: any) => {
    setSelectedMappingConfigurations([detail.item]);
  };

  const { items, actions, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCollection(mappingConfigurations, {
    sorting: {},
    selection: {}
  });

  return (
    <>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => {
          window.location.reload();
        }}
      >
        <MappingsConfigurationContext.Provider
          value={{
            mappingConfigurations,
            setMappingConfigurations,
            notificationHandler
          }}
        >
          <AppLayout
            notifications={<Flashbar items={flashbarItems} />}
            navigationHide={false}
            navigation={<MappingSideNavigation />}
            breadcrumbs={<AppBreadcrumb items={breadcrumbs} />}
            tools={<MappingsToolInfo />}
            toolsOpen={toolsOpen}
            onToolsChange={({ detail }) => setToolsOpen(detail.open)}
            content={
              <>
                {mappingConfigTableAlias && (
                  <Outlet /> // Outlet for selected Campaign
                )}
                {!mappingConfigTableAlias && (
                  <Table
                    header={
                      <Header
                        actions={
                          <SpaceBetween size="m" direction="horizontal">
                            <Button disabled={selectedMappingConfigurations.length === 0} onClick={removeSelected}>
                              Remove
                            </Button>
                            <Button onClick={newMapping}>New Mapping</Button>
                            <Button onClick={refreshMappingsConfigurations}>Refresh</Button>
                          </SpaceBetween>
                        }
                        info={
                          <Link variant="info" onFollow={() => setToolsOpen(true)}>
                            Info
                          </Link>
                        }
                      >
                        {'Mappings Configuration'}
                      </Header>
                    }
                    {...collectionProps}
                    selectionType="single"
                    onSelectionChange={({ detail }) => tableSelectionChanged(detail)}
                    onRowClick={({ detail }) => onRowClickChanged(detail)}
                    selectedItems={selectedMappingConfigurations}
                    resizableColumns={false}
                    loading={mappingConfigurationsLoading}
                    loadingText="Loading"
                    columnDefinitions={columnDefinitions as TableProps.ColumnDefinition<any>[]}
                    items={items}
                    variant="full-page"
                  />
                )}
              </>
            }
          />
        </MappingsConfigurationContext.Provider>
      </ErrorBoundary>
    </>
  );
};
