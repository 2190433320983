import * as React from 'react';
import { AppLayout, Flashbar, FlashbarProps } from '@amzn/awsui-components-react';
import { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from '../GenericComponents/ErrorFallback';
import { MappingsTable } from './MappingsTable';
import { MappingsToolInfo } from './MappingsTool';
import { MappingSideNavigation } from './MappingSideNavigation';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from 'src/utils/AuthProvider';
import { useAppContext } from '../AppContextProvider';

export const Mappings = () => {
  const [toolsOpen, setToolsOpen] = useState(false);
  const userDetails = useAuth();
  const appContext = useAppContext();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  React.useEffect(() => {
    if (pathname === '/mappings') {
      if (userDetails.isAdmin) {
        navigate('/mappings' + '/mappings-configuration');
      } else {
        navigate('/mappings' + '/' + appContext.mappings[0]?.table_alias);
      }
    }
  }, [pathname]);

  return (
    <>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => {
          window.location.reload();
        }}
      >
        <AppLayout
          navigationHide={false}
          navigation={<MappingSideNavigation />}
          tools={<MappingsToolInfo />}
          toolsOpen={toolsOpen}
          onToolsChange={({ detail }) => setToolsOpen(detail.open)}
          content={<>{'Hello WOrld'}</>}
        />
      </ErrorBoundary>
    </>
  );
};
