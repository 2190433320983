import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Amplify, Auth } from 'aws-amplify';
import { createContext, useContext, useEffect, useState } from 'react';
import { LoadingSpinner } from 'src/components/GenericComponents/Spinner';
import { AD3_FDA_LDAP_GROUP, AD3_LDAP_GROUP, FDA_PORTAL_BUSINESS_TEAM } from 'src/constants/FDAConstants';
import { LoadingStatus, UserAuthContext } from 'src/models/AppModel';
import { configureTheLogger, logger } from './FDALogger';

export const ENV_CONSTANTS = Object.freeze({
  ENVIRONMENT_VARIABLES: {
    Region: 'us-east-1',
    Stage: 'beta',
    CloudFrontProtocol: 'https',
    CloudFrontDomain: 'dy7qfpfdv13i0.cloudfront.net'
  }
});

const initialData: UserAuthContext = { userAuthDataLoadingStatus: LoadingStatus.Loading } as UserAuthContext;
export const AuthContextDetails = createContext(initialData);
export const useAuth = () => useContext(AuthContextDetails);

// Provider component that wraps your app and makes auth object
export const AuthProvider = ({ children }: any) => {
  const userAuthData: UserAuthContext = useAuthProvider();
  if (userAuthData?.userAuthDataLoadingStatus === LoadingStatus.Loading) {
    return <LoadingSpinner />;
  } else {
    configureTheLogger(userAuthData.Alias, userAuthData);
    logger.info('User authenticated successfully!');
    return <AuthContextDetails.Provider value={userAuthData}>{children}</AuthContextDetails.Provider>;
  }
};

/*
  Note: logger isn't configured by this time, do not use logger in this method
*/
const useAuthProvider = () => {
  const [userSession, setUserSession] = useState<any>();
  const [userCognitoAuthData, setUserCognitoAuthData] = useState<UserAuthContext>({
    ...initialData,
    error: '',
    userAuthDataLoadingStatus: LoadingStatus.Loading
  });

  useEffect(() => {
    authenticateAndSetUserDetails();
  }, []);

  const authenticateAndSetUserDetails = () => {
    Auth.configure(getAuthConfig());

    const configureAppSync = async () => {
      try {
        const apiConfig = await getApiConfig();
        Amplify.configure(apiConfig);
      } catch (error: any) {
        console.error('Unable to fetch AppSync Config', error);
      }
    };

    const signInWithAmazonFederate = async () => {
      try {
        const userSessionDetails = await Auth.federatedSignIn({ customProvider: 'AmazonFederate' });
        setUserSession(userSessionDetails);
        const session = await Auth.currentSession();
        setUserCognitoAuthData(getSessionDetails(session));
      } catch (error: any) {
        console.error('Unable to sign in with AmazonFederate', error);
        setUserCognitoAuthData({
          ...userCognitoAuthData,
          error: 'Unable to sign in with AmazonFederate',
          userAuthDataLoadingStatus: LoadingStatus.Loading
        });
      }
    };

    Auth.currentAuthenticatedUser()
      .then(async (userSessionDetails) => {
        await configureAppSync();
        setUserSession(userSessionDetails);
        const session = await Auth.currentSession();
        setUserCognitoAuthData(getSessionDetails(session));
      })
      .catch(() => {
        signInWithAmazonFederate();
      });
  };

  const getSessionDetails = (credentials: CognitoUserSession) => {
    const authLdapGroup: any = credentials.getIdToken().payload['custom:FDA_LDAP_GROUPS'];
    let UserLdapGroups: string[] = authLdapGroup ? authLdapGroup.split(',') : [];
    UserLdapGroups = UserLdapGroups.map((ldap) => {
      return ldap.toLowerCase();
    });
    return {
      Alias: credentials.getIdToken().payload['identities'][0].userId,
      DisplayName: credentials.getIdToken().payload['custom:DISPLAY_NAME'],
      Email: credentials.getIdToken().payload['custom:EMAIL'],
      UserLDAPGroups: UserLdapGroups,
      isAdmin: UserLdapGroups?.includes(AD3_FDA_LDAP_GROUP) || UserLdapGroups?.includes(AD3_LDAP_GROUP),
      isBusinessUser: UserLdapGroups?.includes(FDA_PORTAL_BUSINESS_TEAM),
      userAuthDataLoadingStatus: LoadingStatus.Completed,
      error: ''
    };
  };

  return userCognitoAuthData;
};

export const getAuthConfig = () => {
  return {
    Auth: {
      region: 'us-east-1',
      userPoolId: 'us-east-1_j1ReQZqae',
      userPoolWebClientId: '4eup2ooidq3ute441pm3d53fhi',
      identityPoolId: 'us-east-1:143a392d-afb8-47f3-b0e8-c2fba0594d5b',
      awsAccountId: '883121193262',
      mandatorySignIn: true,
      authenticationFlowType: 'USER_SRP_AUTH',
      oauth: {
        domain: 'fda-portal-beta.auth.us-east-1.amazoncognito.com',
        scope: ['openid'],
        redirectSignIn: 'https://beta.fdaportal.device.finance.amazon.dev',
        redirectSignOut: 'https://beta.fdaportal.device.finance.amazon.dev',
        responseType: 'code'
      }
    }
  };
};

const getApiConfig = async () => {
  const apiConfig = {
    endpoints: [
      {
        name: 'FDAService',
        endpoint: getAPI(),
        custom_header: async () => {
          return {
            username: (await Auth.currentSession()).getIdToken().payload['identities'][0].userId,
            Authorization: (await Auth.currentSession()).getIdToken().getJwtToken()
          };
        }
      }
    ]
  };
  return apiConfig;
};

export function getAPI() {
  const stage = ENV_CONSTANTS.ENVIRONMENT_VARIABLES.Stage;
  const devAndBetaSecretKey = 'https://beta.api.fdaportal.device.finance.amazon.dev';
  switch (stage) {
    case 'dev':
      return devAndBetaSecretKey;
    case 'beta':
      return devAndBetaSecretKey;
    case 'prod':
      return 'https://api.fdaportal.device.finance.amazon.dev';
    default:
      throw new Error('API not configured');
  }
}
