import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { LandingPage } from './LandingPage';
import { FDAPortalTopNavBar } from './FDATopNavBar';
import { PageNotFound } from '../GenericComponents/PageNotFound';
import { useAppContext } from '../AppContextProvider';
import { Mappings } from '../Mappings/Mappings';
import { PrivateRoutes } from './PrivateRoutes';
import { MappingsConfiguration } from '../Mappings/MappingsConfiguration';
import { MappingsTable } from '../Mappings/MappingsTable';
import { MappingsConfigurationDetails } from '../Mappings/MappingsConfigurationDetails';

export const AppRouter = () => {
  const appContext = useAppContext();
  return (
    <BrowserRouter>
      <FDAPortalTopNavBar />
      <Routes>
        {/* LandingPage is accessible to all types of users */}
        <Route path="/" element={<LandingPage />} />

        {/* Private routes are available based on user type, and user status (active or in-active) */}
        <Route element={<PrivateRoutes />}>
          <Route path="/mappings" element={<Mappings />} />
          <Route path="/mappings/mappings-configuration" element={<MappingsConfiguration />}>
            <Route path="/mappings/mappings-configuration/:mappingConfigTableAlias" element={<MappingsConfigurationDetails />} />
          </Route>

          <Route path="/mappings/:tableAliasParam" element={<MappingsTable />} />

          {appContext.projects.map((project, index) => {
            return <Route key={index} path={project.ProjectRoute} element={project.ProjectComponent} />;
          })}
        </Route>

        {/* Redirect all 404's to PageNotFound */}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
};
