import React, { useContext } from 'react';
import { AppLayoutProps, Box, Button, Form, FormField, Input, NonCancelableCustomEvent, Select, SpaceBetween } from '@amzn/awsui-components-react';
import { isEmpty } from '@aws-amplify/core';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { ASINDataEntity, ASINDataFormModel, ASINGlobalValues, DropdownModel, ProjectsEntity } from 'src/models/AccessoryASIN';
import * as Yup from 'yup';
import { useNumberPref, useObjectPref } from '../../../utils/UserPrefs';

import { AuthContextDetails } from 'src/utils/AuthProvider';
import { getCurrentUserLocalTime } from 'src/utils/DateTimeUtils';

export function useSplitPanelProps() {
  const [splitPanelPrefs, setSplitPanelPrefs] = useObjectPref<AppLayoutProps.SplitPanelPreferences>(location.pathname, 'splitPanelPrefs', {
    position: 'side'
  });
  const [splitPanelSize, setSplitPanelSize] = useNumberPref(location.pathname, 'splitPanelSize');
  const [splitPanelOpen, setSplitPanelOpen] = useState<boolean>(false);

  const onSplitPanelPreferencesChange = (event: NonCancelableCustomEvent<AppLayoutProps.SplitPanelPreferences>) => {
    setSplitPanelPrefs(event.detail);
  };

  const onSplitPanelResize = (event: NonCancelableCustomEvent<AppLayoutProps.SplitPanelResizeDetail>) => {
    setSplitPanelSize(event.detail.size);
  };

  const onSplitPanelToggle = (event: NonCancelableCustomEvent<AppLayoutProps.ChangeDetail>) => {
    setSplitPanelOpen(event.detail.open);
  };

  const splitPanelOpenByEdit = (splitPanelOpen: boolean) => {
    setSplitPanelOpen(splitPanelOpen);
  };

  useEffect(() => {
    let updatedSplitPanelPrefs: AppLayoutProps.SplitPanelPreferences = { position: 'side' };
    setSplitPanelPrefs(updatedSplitPanelPrefs);
  }, []);

  return {
    splitPanelPrefs,
    onSplitPanelPreferencesChange,
    splitPanelSize,
    onSplitPanelResize,
    splitPanelOpen,
    splitPanelOpenByEdit,
    onSplitPanelToggle
  };
}

export const getPanelContent = (
  asinEnteredByASIN: string,
  selectedItems: ASINDataEntity[],
  type: 'single' | 'multiple',
  projectMetadata: ProjectsEntity,
  eventType: 'edit' | 'new',
  globalValues: ASINGlobalValues,
  updateTheTable: (formValues: ASINDataEntity, globalValues: ASINGlobalValues) => void
) => {
  if (type === 'single') {
    return ASINSplitPanelContent(asinEnteredByASIN, selectedItems, projectMetadata, eventType, globalValues, updateTheTable);
  } else if (type === 'multiple') {
    return getPanelContentMultiple(selectedItems);
  } else {
    return EMPTY_PANEL_CONTENT;
  }
};

const REQUIRED_FIELD = 'Required field';
const yupRequiredDropdownField = () => {
  return Yup.object().shape({
    label: Yup.string().required(REQUIRED_FIELD)
  });
};

const ASINEditFormSchema = Yup.object().shape({
  asin: Yup.string()
    .required(REQUIRED_FIELD)
    .matches(/^[a-zA-Z0-9]+$/, 'Must be only alphanumeric'),
  marketplace_id: Yup.string().required(REQUIRED_FIELD),
  compatible_screen: yupRequiredDropdownField(),
  compatible_device: yupRequiredDropdownField(),
  gl: yupRequiredDropdownField(),
  product_line: yupRequiredDropdownField(),
  product_desc: yupRequiredDropdownField(),
  // accessory_program: yupRequiredDropdownField(),
  merchant_type: yupRequiredDropdownField(),
  product_category: yupRequiredDropdownField(),
  is_generic: yupRequiredDropdownField(),
  is_refurb: yupRequiredDropdownField()
});

const InitialValues: ASINDataFormModel = {
  unique_id: '',
  asin: '', // Required
  marketplace_id: '', // Required
  compatible_screen: { label: '', value: '' }, // Required // Cascading with compatible_device
  compatible_device: { label: '', value: '' }, // Required
  item_name: '', // Optional
  parent_asin: '', // Optional
  brand: '', // Optional
  color: '', // Optional
  gl: { label: '', value: '' }, // Required // Cascading with product_line, product_desc
  product_line: { label: '', value: '' }, // Required
  product_desc: { label: '', value: '' }, // Required
  accessory_program: { label: '', value: '' }, // Global
  merchant_type: { label: '', value: '' }, // Global
  product_category: { label: '', value: '' }, // Global
  is_generic: { label: '', value: '' }, // Global
  is_refurb: { label: '', value: '' } // Global
};

export const ASINSplitPanelContent = (
  asinEnteredByASIN: string,
  selectedItems: ASINDataEntity[],
  projectMetadata: ProjectsEntity,
  eventType: 'edit' | 'new',
  originalGlobalValues: ASINGlobalValues,
  updateTheTable: (formValues: ASINDataEntity, globalValues: ASINGlobalValues) => void
) => {
  const userContext = useContext(AuthContextDetails);
  const asinFormRef = useRef<FormikProps<ASINDataFormModel>>(null);
  let selectedASINRow: ASINDataEntity = selectedItems[0];
  const [asinFormInitialValues, setAsinFormInitialValues] = useState<ASINDataFormModel>(InitialValues);

  const [updatedGlobalValues, setUpdatedGlobalValues] = useState<ASINGlobalValues>(originalGlobalValues);

  useEffect(() => {
    selectedASINRow = selectedItems[0];
    asinFormRef.current?.resetForm();
    if (eventType === 'edit') {
      setAsinFormInitialValues({
        unique_id: selectedASINRow.unique_id,
        asin: selectedASINRow.asin, // Required
        marketplace_id: selectedASINRow.marketplace_id, // Required
        compatible_screen: stringToPolarisDropdown(selectedASINRow.compatible_screen), // Required // Cascading with compatible_device
        compatible_device: stringToPolarisDropdown(selectedASINRow.compatible_device), // Required
        item_name: selectedASINRow.item_name, // Optional
        parent_asin: selectedASINRow.parent_asin, // Optional
        brand: selectedASINRow.brand, // Optional
        color: selectedASINRow.color, // Optional
        gl: stringToPolarisDropdown(selectedASINRow.gl), // Required // Cascading with product_line, product_desc
        product_line: stringToPolarisDropdown(selectedASINRow.product_line), // dropdownFormat(selectedASINRow.product_line), // Required
        product_desc: stringToPolarisDropdown(selectedASINRow.product_desc), // Required
        accessory_program: stringToPolarisDropdown(selectedASINRow.accessory_program), // Global
        merchant_type: stringToPolarisDropdown(selectedASINRow.merchant_type), // Global
        product_category: stringToPolarisDropdown(selectedASINRow.product_category), // Global
        is_generic: stringToPolarisDropdown(selectedASINRow.is_generic), // Global
        is_refurb: stringToPolarisDropdown(selectedASINRow.is_refurb) // Global
      });
      setUpdatedGlobalValues({
        asin: asinEnteredByASIN,
        accessory_program: selectedASINRow.accessory_program,
        product_category: selectedASINRow.product_category,
        merchant_type: selectedASINRow.merchant_type,
        is_generic: selectedASINRow.is_generic,
        is_refurb: selectedASINRow.is_refurb
      } as ASINGlobalValues);
    } else {
      setUpdatedGlobalValues(originalGlobalValues);
      let newObj = {
        ...InitialValues,
        asin: asinEnteredByASIN,
        unique_id: asinEnteredByASIN,
        accessory_program: stringToPolarisDropdown(originalGlobalValues.accessory_program),
        merchant_type: stringToPolarisDropdown(originalGlobalValues.merchant_type),
        product_category: stringToPolarisDropdown(originalGlobalValues.product_category),
        is_generic: stringToPolarisDropdown(originalGlobalValues.is_generic),
        is_refurb: stringToPolarisDropdown(originalGlobalValues.is_refurb)
      };
      setAsinFormInitialValues(newObj);
    }
  }, [eventType, selectedItems, originalGlobalValues]);

  const compatibleScreenDropdowns = () => {
    return projectMetadata?.dropdown_fields?.compatible_group?.map((compatibleGroup) => ({
      value: compatibleGroup.compatible_screen,
      label: compatibleGroup.compatible_screen
    }));
  };

  const compatibleDevicesDropdowns = (compatibleScreen: { value: string; label: string }) => {
    if (!isEmpty(compatibleScreen.label)) {
      return projectMetadata?.dropdown_fields?.compatible_group
        ?.find((compatibleGroup) => compatibleGroup.compatible_screen === compatibleScreen.label)
        ?.compatible_device?.map((compatibleDevice) => ({
          label: compatibleDevice,
          value: compatibleDevice
        }));
    }
    return [];
  };

  const glDropdowns = () => {
    return projectMetadata?.dropdown_fields?.gl_group?.map((glGroup) => ({
      label: glGroup.gl,
      value: glGroup.gl
    }));
  };

  const onChangeOfGlDropdown = (detail: any) => {
    const selectedGLValue = detail.selectedOption;
    asinFormRef.current?.setFieldValue('gl', selectedGLValue);

    const selectedGlObject = projectMetadata?.dropdown_fields?.gl_group?.find((glGroupEntity) => glGroupEntity.gl === selectedGLValue.label);

    // gl, product_line && product_desc fields are interdependent
    // Setting the value to product_line
    asinFormRef.current?.setFieldValue('product_line', stringToPolarisDropdown(selectedGlObject?.product_line));
    // Setting the value to product_desc
    asinFormRef.current?.setFieldValue('product_desc', stringToPolarisDropdown(selectedGlObject?.product_desc));
  };

  const productLineDropdowns = () => {
    return projectMetadata?.dropdown_fields?.gl_group?.map((glGroup) => ({
      label: glGroup.product_line,
      value: glGroup.product_line
    }));
  };

  const onChangeOfProductLineDropdown = (detail: any) => {
    const productLineValue = detail.selectedOption;
    asinFormRef.current?.setFieldValue('product_line', productLineValue);

    const selectedGlObject = projectMetadata?.dropdown_fields?.gl_group?.find(
      (glGroupEntity) => glGroupEntity.product_line === productLineValue.label
    );

    // gl, product_line && product_desc fields are interdependent
    // Setting the value to gl
    asinFormRef.current?.setFieldValue('gl', stringToPolarisDropdown(selectedGlObject?.gl));
    // Setting the value to product_desc
    asinFormRef.current?.setFieldValue('product_desc', stringToPolarisDropdown(selectedGlObject?.product_desc));
  };

  const productDescDropdowns = () => {
    return projectMetadata?.dropdown_fields?.gl_group?.map((glGroup) => ({
      label: glGroup.product_desc,
      value: glGroup.product_desc
    }));
  };

  const onChangeOfProductDescDropdown = (detail: any) => {
    const productDescValue = detail.selectedOption;
    asinFormRef.current?.setFieldValue('product_desc', productDescValue);

    const selectedGlObject = projectMetadata?.dropdown_fields?.gl_group?.find(
      (glGroupEntity) => glGroupEntity.product_desc === productDescValue.label
    );

    // gl, product_line && product_desc fields are interdependent
    // Setting the value to gl
    asinFormRef.current?.setFieldValue('gl', stringToPolarisDropdown(selectedGlObject?.gl));
    // Setting the value to product_line
    asinFormRef.current?.setFieldValue('product_line', stringToPolarisDropdown(selectedGlObject?.product_line));
  };

  const polarisDropdownToString = (dropdownData: DropdownModel): string => {
    return dropdownData.label;
  };

  const stringToPolarisDropdown = (objectValue: string | undefined) => {
    if (objectValue)
      return {
        value: objectValue,
        label: objectValue
      };
    else
      return {
        value: '',
        label: ''
      };
  };

  const handleSubmit = (formValues: ASINDataFormModel, formikHelpers: FormikHelpers<ASINDataFormModel>) => {
    const formattedASINRow = formatFormValuesToASINRow(formValues);
    updateTheTable(formattedASINRow, updatedGlobalValues);
    setTimeout(() => {
      formikHelpers.setSubmitting(false);
    }, 2000);
  };

  const formatFormValuesToASINRow = (formValues: ASINDataFormModel): ASINDataEntity => {
    return {
      unique_id: formValues.unique_id,
      asin: formValues.asin,
      marketplace_id: formValues.marketplace_id,
      compatible_screen: polarisDropdownToString(formValues.compatible_screen),
      compatible_device: polarisDropdownToString(formValues.compatible_device),
      item_name: formValues.item_name,
      parent_asin: formValues.parent_asin,
      brand: formValues.brand,
      color: formValues.color,
      gl: polarisDropdownToString(formValues.gl),
      product_line: polarisDropdownToString(formValues.product_line),
      product_desc: polarisDropdownToString(formValues.product_desc),
      accessory_program: polarisDropdownToString(formValues.accessory_program),
      merchant_type: polarisDropdownToString(formValues.merchant_type),
      product_category: polarisDropdownToString(formValues.product_category),
      is_generic: polarisDropdownToString(formValues.is_generic),
      is_refurb: polarisDropdownToString(formValues.is_refurb),
      is_locked: 'Y',
      updated_time: getCurrentUserLocalTime(),
      updated_user: userContext.Alias
    };
  };

  const formikCustomValidate = (formValues: ASINDataFormModel | undefined) => {
    if (!formValues) {
      return;
    }

    let errors: any = {};

    if (!isValidCompatibleScreenDeviceCombination(formValues.compatible_screen.label, formValues.compatible_device.label)) {
      let compatible = {
        compatible_screen: {
          label: 'Not compatible with device'
        },
        compatible_device: {
          label: 'Not compatible with screen'
        }
      };
      errors = { ...compatible };
    }

    if (
      !isValidGlCombo(
        asinFormRef.current?.values?.gl?.label,
        asinFormRef.current?.values?.product_line?.label,
        asinFormRef.current?.values?.product_desc?.label
      )
    ) {
      let glGroupError = {
        gl: {
          label: 'Not compatible with product_line & product_desc'
        },
        product_line: {
          label: 'Not compatible with gl & product_desc'
        },
        product_desc: {
          label: 'Not compatible with product_line & gl'
        }
      };
      errors = { ...glGroupError };
    }
    return errors;
  };

  const isValidGlCombo = (formValueGL: string | undefined, formValueProductLine: string | undefined, formValueProductDesc: string | undefined) => {
    const glObjectFound = projectMetadata.dropdown_fields?.gl_group?.find((glGroup) => glGroup.gl === formValueGL);
    if (glObjectFound) {
      return glObjectFound.product_line === formValueProductLine && glObjectFound.product_desc === formValueProductDesc;
    } else return false;
  };

  const isValidCompatibleScreenDeviceCombination = (formValueScreen: string, formValueDevice: string) => {
    const compatibleScreenFound = projectMetadata.dropdown_fields?.compatible_group?.find(
      (compatibleGroup) => compatibleGroup.compatible_screen === formValueScreen
    );
    const matchedWithCompatibleDevice = compatibleScreenFound?.compatible_device.find((compatibleDevice) => compatibleDevice === formValueDevice);
    return matchedWithCompatibleDevice ? true : false;
  };

  const globalValuesChanged = (globalField: keyof ASINGlobalValues, value: string) => {
    let updatedGlobalValue = { ...updatedGlobalValues, [globalField]: value };

    setUpdatedGlobalValues(updatedGlobalValue);
  };

  const updateUniqueKey = (formValues: ASINDataFormModel) => {
    const ASIN = formValues.asin;
    const MARKETPLACE_ID = formValues.marketplace_id;
    const COMPATIBLE_DEVICE = formValues.compatible_device.label;
    asinFormRef.current?.setFieldValue('unique_id', ASIN + '#' + MARKETPLACE_ID + '#' + COMPATIBLE_DEVICE, true);
  };

  return {
    header: eventType.toUpperCase(),
    body: (
      <>
        <SpaceBetween size="m">
          <Formik<ASINDataFormModel>
            innerRef={asinFormRef}
            enableReinitialize
            initialValues={asinFormInitialValues}
            validate={formikCustomValidate}
            validationSchema={ASINEditFormSchema}
            onSubmit={handleSubmit}
          >
            {({ values, touched, errors, setFieldValue, handleSubmit, isSubmitting }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Form>
                    <SpaceBetween size="xl" direction="vertical">
                      <SpaceBetween size="m" direction="vertical">
                        <FormField label="ASIN" errorText={touched.asin && errors.asin}>
                          <Input disabled value={values.asin} onChange={(event) => setFieldValue('asin', event.detail.value)} />
                        </FormField>

                        <FormField label="Marketplace Id" errorText={touched.marketplace_id && errors.marketplace_id}>
                          <Input
                            disabled={eventType === 'edit'}
                            value={values.marketplace_id}
                            onChange={(event) => setFieldValue('marketplace_id', event.detail.value)}
                            onBlur={() => updateUniqueKey(values)}
                          />
                        </FormField>

                        <FormField label="Compatible Screen" errorText={touched.compatible_screen?.label && errors.compatible_screen?.label}>
                          <Select
                            disabled={eventType === 'edit'}
                            selectedAriaLabel="Selected"
                            filteringType="auto"
                            selectedOption={values.compatible_screen}
                            onChange={({ detail }) => setFieldValue('compatible_screen', detail.selectedOption)}
                            options={compatibleScreenDropdowns()}
                          />
                        </FormField>

                        <FormField label="Compatible Device" errorText={touched.compatible_device?.label && errors.compatible_device?.label}>
                          <Select
                            disabled={eventType === 'edit'}
                            selectedAriaLabel="Selected"
                            filteringType="auto"
                            selectedOption={values.compatible_device}
                            onChange={({ detail }) => setFieldValue('compatible_device', detail.selectedOption)}
                            onBlur={() => updateUniqueKey(values)}
                            options={compatibleDevicesDropdowns(values.compatible_screen)}
                          />
                        </FormField>

                        <FormField label="Item Name" errorText={touched.item_name && errors.item_name}>
                          <Input value={values.item_name} onChange={(event) => setFieldValue('item_name', event.detail.value)} />
                        </FormField>

                        <FormField label="Parent ASIN" errorText={touched.parent_asin && errors.parent_asin}>
                          <Input value={values.parent_asin} onChange={(event) => setFieldValue('parent_asin', event.detail.value)} />
                        </FormField>

                        <FormField label="Brand" errorText={touched.brand && errors.brand}>
                          <Input value={values.brand} onChange={(event) => setFieldValue('brand', event.detail.value)} />
                        </FormField>

                        <FormField label="Color" errorText={touched.color && errors.color}>
                          <Input value={values.color} onChange={(event) => setFieldValue('color', event.detail.value)} />
                        </FormField>

                        <FormField label="Gl" errorText={touched.gl?.label && errors.gl?.label}>
                          <Select
                            selectedAriaLabel="Selected"
                            filteringType="auto"
                            selectedOption={values.gl}
                            onChange={({ detail }) => {
                              onChangeOfGlDropdown(detail);
                            }}
                            options={glDropdowns()}
                          />
                        </FormField>

                        <FormField label="Product Line" errorText={touched.product_line?.label && errors.product_line?.label}>
                          <Select
                            selectedAriaLabel="Selected"
                            filteringType="auto"
                            selectedOption={values.product_line}
                            onChange={({ detail }) => onChangeOfProductLineDropdown(detail)}
                            options={productLineDropdowns()}
                          />
                        </FormField>

                        <FormField label="Product Description" errorText={touched.product_desc?.label && errors.product_desc?.label}>
                          <Select
                            selectedAriaLabel="Selected"
                            filteringType="auto"
                            selectedOption={values.product_desc}
                            onChange={({ detail }) => onChangeOfProductDescDropdown(detail)}
                            options={productDescDropdowns()}
                          />
                        </FormField>

                        <FormField label="Accessory Program" errorText={touched.accessory_program?.label && errors.accessory_program?.label}>
                          <Select
                            selectedAriaLabel="Selected"
                            filteringType="auto"
                            selectedOption={values.accessory_program}
                            onChange={({ detail }) => {
                              setFieldValue('accessory_program', detail.selectedOption);
                              globalValuesChanged('accessory_program', detail.selectedOption.label ? detail.selectedOption.label : '');
                            }}
                            options={projectMetadata?.dropdown_fields?.accessory_program?.map((accessory) => ({
                              label: accessory,
                              value: accessory
                            }))}
                          />
                        </FormField>

                        <FormField label="Merchant Type" errorText={touched.merchant_type?.label && errors.merchant_type?.label}>
                          <Select
                            selectedAriaLabel="Selected"
                            filteringType="auto"
                            selectedOption={values.merchant_type}
                            onChange={({ detail }) => {
                              setFieldValue('merchant_type', detail.selectedOption);
                              globalValuesChanged('merchant_type', detail.selectedOption.label ? detail.selectedOption.label : '');
                            }}
                            options={projectMetadata?.dropdown_fields?.merchant_type?.map((merchant) => ({
                              label: merchant,
                              value: merchant
                            }))}
                          />
                        </FormField>

                        <FormField label="Product Category" errorText={touched.product_category?.label && errors.product_category?.label}>
                          <Select
                            selectedAriaLabel="Selected"
                            filteringType="auto"
                            selectedOption={values.product_category}
                            onChange={({ detail }) => {
                              setFieldValue('product_category', detail.selectedOption);
                              globalValuesChanged('product_category', detail.selectedOption.label ? detail.selectedOption.label : '');
                            }}
                            options={projectMetadata?.dropdown_fields?.product_category?.map((productCategory) => ({
                              label: productCategory,
                              value: productCategory
                            }))}
                          />
                        </FormField>

                        <FormField label="Is Generic" errorText={touched.is_generic?.label && errors.is_generic?.label}>
                          <Select
                            selectedAriaLabel="Selected"
                            filteringType="auto"
                            selectedOption={values.is_generic}
                            onChange={({ detail }) => {
                              setFieldValue('is_generic', detail.selectedOption);
                              globalValuesChanged('is_generic', detail.selectedOption.label ? detail.selectedOption.label : '');
                            }}
                            // onBlur={(event) => }
                            options={projectMetadata?.dropdown_fields?.is_generic?.map((isGeneric) => ({
                              label: isGeneric,
                              value: isGeneric
                            }))}
                          />
                        </FormField>

                        <FormField label="Is Refurb" errorText={touched.is_refurb?.label && errors.is_refurb?.label}>
                          <Select
                            selectedAriaLabel="Selected"
                            filteringType="auto"
                            selectedOption={values.is_refurb}
                            onChange={({ detail }) => {
                              setFieldValue('is_refurb', detail.selectedOption);
                              globalValuesChanged('is_refurb', detail.selectedOption.label ? detail.selectedOption.label : '');
                            }}
                            options={[
                              {
                                label: 'Y',
                                value: 'Y'
                              },
                              {
                                label: 'N',
                                value: 'N'
                              }
                            ]}
                          />
                        </FormField>
                      </SpaceBetween>

                      <SpaceBetween size="l">
                        <Button variant="primary" onClick={() => handleSubmit} disabled={isSubmitting}>
                          {eventType === 'edit' ? 'Save changes' : 'Create new record'}
                        </Button>
                      </SpaceBetween>
                    </SpaceBetween>
                  </Form>
                </form>
              );
            }}
          </Formik>
        </SpaceBetween>
      </>
    )
  };
};

export const getPanelContentMultiple = (selectedItems: ASINDataEntity[]) => {
  return {
    header: '',
    body: (
      <SpaceBetween size="s" direction="vertical">
        <Box variant="h4">Select only 1 ASIN</Box>;
      </SpaceBetween>
    )
  };
};

export const EMPTY_PANEL_CONTENT = {
  header: 'ASIN not selected!',
  body: 'Select an ASIN to edit.'
};
