import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  AppLayout,
  AttributeEditor,
  Box,
  Button,
  ButtonDropdown,
  Checkbox,
  Container,
  Flashbar,
  FlashbarProps,
  Form,
  FormField,
  Header,
  Input,
  Select,
  SpaceBetween,
  StatusIndicator,
  TagEditor
} from '@amzn/awsui-components-react';
import * as Yup from 'yup';
import { MappingDataEntity } from './Models/MappingModel';

export const MAPPING_CONFIG_MASTER_ALIAS = 'Mapper Configuration Master';
export const MAPPING_CONFIG_STATUS_ALIAS = 'FDA Mapper Status';

export const MAPPING_CONFIG_MASTER_TABLE_NAME = 'fda_mapper.fda_config_master';
export const MAPPING_CONFIG_STATUS_TABLE_NAME = 'fda_mapper.fda_map_job_status_log';

export const MAPPING_CONFIGURATIONS_BETA = [
  {
    table_nm: MAPPING_CONFIG_MASTER_TABLE_NAME,
    table_alias: MAPPING_CONFIG_MASTER_ALIAS,
    table_cols: 'table_nm,table_alias,table_cols,table_dtype,unique_key,table_source',
    table_dtype: 'text,text,text,text,text,text',
    unique_key: 'table_nm',
    table_source: 'ad3_dev'
  },
  {
    table_nm: MAPPING_CONFIG_STATUS_TABLE_NAME,
    table_alias: MAPPING_CONFIG_STATUS_ALIAS,
    table_cols: 'job_id,table_name,table_alias,job_status,updated_user,updated_dt',
    table_dtype: 'text,text,text,text,text,text',
    unique_key: 'job_id',
    table_source: 'ad3_dev'
  }
];

export const MAPPING_CONFIGURATIONS_PROD = [
  {
    table_nm: MAPPING_CONFIG_MASTER_TABLE_NAME,
    table_alias: MAPPING_CONFIG_MASTER_ALIAS,
    table_cols: 'table_nm,table_alias,table_cols,table_dtype,unique_key,table_source',
    table_dtype: 'text,text,text,text,text,text',
    unique_key: 'table_nm',
    table_source: 'ad3_adhoc'
  },
  {
    table_nm: MAPPING_CONFIG_STATUS_TABLE_NAME,
    table_alias: MAPPING_CONFIG_STATUS_ALIAS,
    table_cols: 'job_id,table_name,table_alias,job_status,updated_user,updated_dt',
    table_dtype: 'text,text,text,text,text,text',
    unique_key: 'job_id',
    table_source: 'ad3_adhoc'
  }
];

export interface MappingConfigurationForm {
  table_source: { label: string; value: string };
  table_nm: string;
  table_alias: string;
  columnDefinitions: ColumnDefinitionsAttributes[];
  unique_key: any[];
}
export interface ColumnDefinitionsAttributes {
  columnName: string;
  columnDataType: { label: string; value: string };
  ColumnDropdownDetails: string;
}

export const FormInitialValues: MappingConfigurationForm = {
  table_source: { label: '', value: '' },
  table_nm: '',
  table_alias: '',
  columnDefinitions: [] as ColumnDefinitionsAttributes[],
  unique_key: []
};

const REQUIRED_FIELD = 'Required field';
const yupRequiredDropdownField = () => {
  return Yup.object().shape({
    label: Yup.string().required(REQUIRED_FIELD)
  });
};

export const validationSchema = () => {
  return Yup.object({
    table_source: yupRequiredDropdownField(),
    table_nm: Yup.string().required(REQUIRED_FIELD),
    table_alias: Yup.string().required(REQUIRED_FIELD),
    columnDefinitions: Yup.array()
      .of(
        Yup.object({
          columnName: Yup.string().required(REQUIRED_FIELD),
          columnDataType: yupRequiredDropdownField(),
          ColumnDropdownDetails: Yup.string().when('columnDataType', {
            is: (columnDataType: any) => isAmongCustomValues(columnDataType),
            then: Yup.string().required(REQUIRED_FIELD)
          })
        })
      )
      .min(1, 'At least 1 column is required')
  });
};

const DROPDOWN_FROM_SOURCE = { label: 'DROPDOWN_FROM_SOURCE', value: 'dropdown-source-' };
const DROPDOWN_FROM_QUERY = { label: 'DROPDOWN_FROM_QUERY', value: 'dropdown-query-' };
const DROPDOWN_FROM_CUSTOM_VALUES = { label: 'DROPDOWN_FROM_CUSTOM_VALUES', value: 'dropdown-custom-' };

export const isAmongCustomValues = (columnDataType: any): boolean => {
  return (
    JSON.stringify(columnDataType) === JSON.stringify(DROPDOWN_FROM_SOURCE) ||
    JSON.stringify(columnDataType) === JSON.stringify(DROPDOWN_FROM_QUERY) ||
    JSON.stringify(columnDataType) === JSON.stringify(DROPDOWN_FROM_CUSTOM_VALUES)
  );
};

export const COLUMN_DATA_TYPE_DROPDOWNS = [
  {
    label: 'Generic',
    options: [
      { label: 'text', value: 'text' },
      { label: 'numeric', value: 'numeric' },
      { label: 'boolean', value: 'boolean' },
      { label: 'datetime', value: 'datetime' },
      { label: 'date', value: 'date' }
    ]
  },
  {
    label: 'Auto Generated',
    options: [
      { label: 'CURRENT_USER', value: 'CURRENT_USER' },
      { label: 'CURRENT_DATE', value: 'CURRENT_DATE' },
      { label: 'CURRENT_DATE_TIME', value: 'CURRENT_DATE_TIME' }
    ]
  },
  {
    label: 'Custom',
    options: [DROPDOWN_FROM_SOURCE, DROPDOWN_FROM_QUERY, DROPDOWN_FROM_CUSTOM_VALUES]
  }
];

export const FDA_TABLE_SOURCES = [
  { label: 'ad3_dev', value: 'ad3_dev' },
  { label: 'ad3_adhoc', value: 'ad3_adhoc' },
  { label: 'ad3_source', value: 'ad3_source' }
];

export const mapToColumnDataTypeDropdowns = (dataType: string) => {
  let parsedDataType = '';

  if (dataType.startsWith('dropdown-source-')) {
    parsedDataType = 'dropdown-source-';
  } else if (dataType.startsWith('dropdown-query-')) {
    parsedDataType = 'dropdown-query-';
  } else if (dataType.startsWith('dropdown-custom-')) {
    parsedDataType = 'dropdown-custom-';
  } else {
    parsedDataType = dataType;
  }

  switch (parsedDataType) {
    // Generic
    case 'text':
      return { label: 'text', value: 'text' };
    case 'numeric':
      return { label: 'numeric', value: 'numeric' };
    case 'boolean':
      return { label: 'boolean', value: 'boolean' };
    case 'datetime':
      return { label: 'datetime', value: 'datetime' };
    case 'date':
      return { label: 'date', value: 'date' };

    // Auto Generated
    case 'CURRENT_USER':
      return { label: 'CURRENT_USER', value: 'CURRENT_USER' };
    case 'CURRENT_DATE':
      return { label: 'CURRENT_DATE', value: 'CURRENT_DATE' };
    case 'CURRENT_DATE_TIME':
      return { label: 'CURRENT_DATE_TIME', value: 'CURRENT_DATE_TIME' };

    // Custom
    case 'dropdown-source-':
      return DROPDOWN_FROM_SOURCE;
    case 'dropdown-query-':
      return DROPDOWN_FROM_QUERY;
    case 'dropdown-custom-':
      return DROPDOWN_FROM_CUSTOM_VALUES;

    default:
      return { label: 'text', value: 'text' };
  }
};

const getDropdownValues = (dataType: string): string => {
  if (dataType.startsWith('dropdown-source-')) {
    return dataType.replace('dropdown-source-', '');
  } else if (dataType.startsWith('dropdown-query-')) {
    return dataType.replace('dropdown-query-', '');
  } else if (dataType.startsWith('dropdown-custom-')) {
    return dataType.replace('dropdown-custom-', '');
  } else {
    return '';
  }
};

export const parseMappingConfigToFormValues = (mappingConfigFound: MappingDataEntity): MappingConfigurationForm => {
  let combinedColumnDetails: ColumnDefinitionsAttributes[] = [];
  mappingConfigFound.table_cols.split(',').forEach((col: string, index: number) => {
    combinedColumnDetails.push({
      columnName: col,
      columnDataType: mapToColumnDataTypeDropdowns(mappingConfigFound.table_dtype.split(',')[index]),
      ColumnDropdownDetails: mappingConfigFound.table_dtype.split(',')[index].startsWith('dropdown')
        ? getDropdownValues(mappingConfigFound.table_dtype.split(',')[index])
        : ''
    });
  });

  let uniqueKeyColumns: any =
    mappingConfigFound.unique_key !== ''
      ? mappingConfigFound.unique_key?.split(',').map((uniqueKey: string, index: number) => {
          return {
            label: uniqueKey,
            value: uniqueKey
          };
        })
      : [];

  return {
    ...mappingConfigFound,
    table_source: {
      value: mappingConfigFound.table_source,
      label: mappingConfigFound.table_source
    },
    unique_key: uniqueKeyColumns,
    columnDefinitions: combinedColumnDetails
  } as MappingConfigurationForm;
};

export const parseFormValuesToMappingConfig = (formValues: MappingConfigurationForm): MappingDataEntity => {
  let mappingDataEntity: MappingDataEntity = {
    table_source: formValues.table_source.label,
    table_nm: formValues.table_nm,
    table_alias: formValues.table_alias,
    table_cols: formValues.columnDefinitions.map((columnDefinition) => columnDefinition.columnName).toString(),
    table_dtype: getDataTypeObject(formValues),
    unique_key: formValues.unique_key.map((uniquekey) => uniquekey.label).toString()
  };

  return mappingDataEntity;
};

const getDataTypeObject = (formValues: MappingConfigurationForm) => {
  const dataTypes = formValues.columnDefinitions.map((columnDefinition) => columnDefinition.columnDataType.value);

  let parsedDataTypes = dataTypes.map((dataType: string, index: number) => {
    if (dataType === 'dropdown-source-' || dataType === 'dropdown-query-' || dataType === 'dropdown-custom-') {
      return dataType + formValues.columnDefinitions[index].ColumnDropdownDetails;
    } else {
      return dataType;
    }
  });
  return parsedDataTypes.toString();
};
