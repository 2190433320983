import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  AppLayout,
  Box,
  Button,
  ButtonDropdown,
  Container,
  Flashbar,
  FlashbarProps,
  Grid,
  Header,
  Pagination,
  Popover,
  SpaceBetween,
  SplitPanel,
  StatusIndicator,
  Table,
  TextFilter,
  Toggle
} from '@amzn/awsui-components-react';
import { RestAPI } from '@aws-amplify/api-rest';
import React, { useContext, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { EmptyStateMessage } from 'src/components/GenericComponents/EmptyState';
import { ErrorFallback } from 'src/components/GenericComponents/ErrorFallback';
import { FDA_BACKEND_SERVICE, PROJECT_DATA } from 'src/constants/APIConstants';
import { FILE_DOWNLOAD_OPTIONS, NBD_COUPON_REDEMPTION_TITLE, SPLIT_PANEL_I18NSTRINGS } from 'src/constants/FDAConstants';
import { ProjectMetadata, ProjectsEntity } from 'src/models/AccessoryASIN';
import { sendNBDUpdateEmail } from 'src/services/EmailService';
import { fetchProjectMetadata, postProjectData } from 'src/services/FDAServices';
import updateAppTitle from 'src/services/PortalTitleService';
import { AuthContextDetails } from 'src/utils/AuthProvider';
import * as Time from 'src/utils/DateTimeUtils';
import { logger } from 'src/utils/FDALogger';
import { exportAsCSVFile, exportAsExcelFile } from 'src/utils/FileServices';
import { CouponData, CouponsDataEntity, SubmitRequestForNBD } from '../../../models/CouponRedemptionModel';
import { NBDConfirmModal } from './CouponConfirmModal';
import { couponRedemptionPanel, useNBDSplitPanelProps } from './CouponRedemptionTableSplitPanel';
import { CouponMoreInfoToolBar } from './CouponRedemptionToolInfo';
import {
  DEFAULT_VISIBLE_CONTENT,
  getMatchesCountText,
  NBD_COUPON_COLUMN_DEFINITIONS,
  NBD_COUPON_DEFAULT_PREFERENCES,
  paginationLabels
} from './CouponRedemptionTableDefinition';
import { NBDCouponUploadModal } from './CouponRedemptionUploadModal';
import { useAppContext } from 'src/components/AppContextProvider';
import { LoadingStatus } from 'src/models/AppModel';

interface NBDCouponState {
  nbdDataLoading: boolean;
  nbdDataFetched: boolean;
  nbdDataChanged: boolean;
  nbdDataSubmitInProgress: boolean;
}

export const CouponRedemption = () => {
  updateAppTitle(NBD_COUPON_REDEMPTION_TITLE);
  const userDetails = useContext(AuthContextDetails);
  const appContext = useAppContext();

  const [projectMetadata, setProjectMetadata] = React.useState<ProjectsEntity>({} as ProjectsEntity);

  const [nbdCouponData, setNbdCouponData] = React.useState<CouponsDataEntity[]>([]);
  const [deletePAWSIds, setDeletePAWSIds] = React.useState<string[]>([]);
  const [couponApprovalMode, setCouponApprovalMode] = React.useState(false);
  const [nbdUploadFileModal, setNbdUploadFileModal] = React.useState(false);
  const [nbdConfirmModal, setNbdConfirmModal] = React.useState(false);

  const [toolsOpen, setToolsOpen] = useState(false);
  const [eventType, setEventType] = useState<'edit' | 'new'>('new');
  const [flashbarItems, setFlashbarItems] = useState<FlashbarProps.MessageDefinition[]>([]);

  const [preferences, setPreferences] = useState(NBD_COUPON_DEFAULT_PREFERENCES);
  const [selectedItems, setSelectedItems] = useState<CouponsDataEntity[]>([]);

  const [panelItem, setPanelItem] = useState<CouponsDataEntity>({} as CouponsDataEntity);
  const isOnlyOneSelected = selectedItems.length === 1;
  const [nbdCouponState, setNbdCouponState] = useState<NBDCouponState>({
    nbdDataLoading: false,
    nbdDataFetched: false,
    nbdDataChanged: false,
    nbdDataSubmitInProgress: false
  });

  const { splitPanelPrefs, splitPanelOpen, onSplitPanelResize, onSplitPanelPreferencesChange, onSplitPanelToggle, splitPanelOpenByEdit } =
    useNBDSplitPanelProps();

  const updateTheTable = (updatedRow: CouponsDataEntity) => {
    if (eventType === 'edit') {
      logger.info('Row edited in NBD ' + JSON.stringify(updatedRow));
      const updatedNBDData = nbdCouponData.map((nbdROw) => {
        return nbdROw.unique_id === updatedRow.unique_id ? { ...updatedRow } : nbdROw;
      });
      setNbdCouponState({ ...nbdCouponState, nbdDataChanged: true });
      setNbdCouponData(updatedNBDData);
      splitPanelOpenByEdit(false);
    } else {
      logger.info('Row added in NBD ' + JSON.stringify(updatedRow));
      setNbdCouponState({ ...nbdCouponState, nbdDataChanged: true });
      nbdCouponData.splice(0, 0, updatedRow);
      splitPanelOpenByEdit(false);
    }
  };

  const { header: panelHeader, body: panelBody } = couponRedemptionPanel(
    panelItem,
    nbdCouponData,
    'single',
    projectMetadata,
    eventType,
    updateTheTable
  );

  useEffect(() => {
    if (appContext.contextLoadingStatus === LoadingStatus.Completed) {
      fetchProjectMetadataInformation();
      setPreferences({
        ...preferences,
        visibleContent: userDetails.isAdmin ? ['approval_flag'].concat(DEFAULT_VISIBLE_CONTENT) : DEFAULT_VISIBLE_CONTENT
      });
    }
  }, [appContext.contextLoadingStatus]);

  const updateProjectMetadata = (projectsEntity: ProjectsEntity[] | []) => {
    const accessoryProjectMetadata = projectsEntity.find((data) => data.table_alias === 'Coupon Redemption');
    if (accessoryProjectMetadata) {
      setProjectMetadata(accessoryProjectMetadata);
      getNBDCouponData(accessoryProjectMetadata.table_alias);
    } else {
      displayFlashMessage('Coupon Redemption metadata not found', 'error');
    }
  };

  const fetchProjectMetadataInformation = () => {
    fetchProjectMetadata().then(({ projects: ProjectsEntity }: ProjectMetadata) => {
      updateProjectMetadata(ProjectsEntity);
    });
  };

  const displayFlashMessage = (content: string, flashBarType: FlashbarProps.Type) => {
    setFlashbarItems([
      {
        type: flashBarType,
        content: content,
        dismissible: true,
        dismissLabel: 'Dismiss message',
        onDismiss: () => setFlashbarItems([])
      }
    ]);
  };

  const getNBDCouponData = async (tableAlias: string) => {
    logger.info('Project Metadata loaded in NBD Coupon ');
    setNbdCouponState({ ...nbdCouponState, nbdDataLoading: true });
    // Naming convention of request body fields as per backend API
    const requestBody = {
      table_alias: tableAlias,
      search_key_field: {},
      search_fields: {}
    };
    await RestAPI.post(FDA_BACKEND_SERVICE, PROJECT_DATA, {
      body: requestBody
    })
      .then((response: CouponData) => {
        setNbdCouponData(response.data);
        setNbdCouponState({
          ...nbdCouponState,
          nbdDataChanged: false,
          nbdDataFetched: true,
          nbdDataLoading: false
        });
      })
      .catch((err) => {
        setNbdCouponState({ ...nbdCouponState, nbdDataLoading: false });
        displayFlashMessage('Unable to fetch NBD Coupon data.', 'error');
      });
  };

  const changeApprovalMode = (detail: any) => {
    // Approval Mode
    if (detail.checked) {
      const approvedRecords = nbdCouponData.filter((nbdRow) => nbdRow.approval_flag === 'Y');
      setSelectedItems(approvedRecords);
      setCouponApprovalMode(detail.checked);
    }
    // Normal Mode
    else {
      nbdCouponData.map((nbdRow) => {
        let nbdRowFound: CouponsDataEntity | undefined = selectedItems.find((selectedRows) => selectedRows.paws_id === nbdRow.paws_id);
        if (nbdRowFound) {
          nbdRowFound.approval_flag = 'Y';
          return { ...nbdRowFound };
        } else {
          nbdRow;
        }
      });
      setSelectedItems([]);
      setCouponApprovalMode(detail.checked);
      setNbdCouponState({ ...nbdCouponState, nbdDataChanged: true });
    }
  };

  const tableSelectionChanged = (detail: any) => {
    setSelectedItems(detail.selectedItems);
  };

  const onRowClickChanged = (detail: any) => {
    setSelectedItems([detail.item]);
  };

  const updatePageSize = (pageSize: string) => {
    setPreferences({ ...preferences, pageSize: +pageSize });
  };

  const downloadAsFile = (fileType: any) => {
    let downloadData = nbdCouponData.map((couponRow: CouponsDataEntity) => {
      return {
        paws_id: couponRow.paws_id,
        company_code: couponRow.company_code,
        coupons_issued: couponRow.coupons_issued,
        selling_price: couponRow.selling_price,
        bundle: couponRow.bundle,
        partner_company_name: couponRow.partner_company_name,
        program_description: couponRow.program_description,
        poc_business: couponRow.poc_business,
        poc_accounting: couponRow.poc_accounting
      };
    });
    logger.info('NBD data downloaded as file - ' + fileType);
    if (fileType === 'excel') exportAsExcelFile(downloadData, 'CouponsMapping', 'Coupons');
    else exportAsCSVFile(downloadData, 'CouponsMapping', 'Coupons');
  };

  const uploadNBDCouponFile = () => {
    setNbdUploadFileModal(true);
  };

  // Cancelling the upload
  const onCancel = () => {
    setNbdUploadFileModal(false);
  };

  // Confirming the upload
  const onConfirm = (uploadedData: CouponsDataEntity[]) => {
    logger.info('NBD data file uploaded ');
    const updatedData = Array.from(uploadedData.concat(nbdCouponData));
    const distinctPAWS = updatedData.filter((value, index, self) => self.map((x) => x.unique_id).indexOf(value.unique_id) == index);
    setNbdCouponData(distinctPAWS);
    setNbdCouponState({ ...nbdCouponState, nbdDataChanged: true });
    setNbdUploadFileModal(false);
  };

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(nbdCouponData || [], {
    filtering: {
      empty: <EmptyStateMessage title={'No data'} subtitle="" />,
      noMatch: (
        <EmptyStateMessage
          title="No matches"
          subtitle="We can’t find a match."
          action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
        />
      )
    },
    pagination: {
      pageSize: preferences.pageSize
    },
    sorting: {},
    selection: {}
  });

  // Edit the row
  const handleEdit = () => {
    setPanelItem(selectedItems[0]);
    setEventType('edit');
    splitPanelOpenByEdit(true);
  };

  const handleAdd = () => {
    setEventType('new');
    splitPanelOpenByEdit(true);
  };

  const handleRemove = () => {
    setNbdConfirmModal(true);
  };

  const onConfirmingTheDelete = () => {
    let deletePAWSIdList = deletePAWSIds;
    deletePAWSIdList.push(selectedItems[0].unique_id);
    const uniqueDeletePAWSIDs = deletePAWSIdList.filter((x, i, a) => {
      return a.indexOf(x) === i;
    });
    setDeletePAWSIds(uniqueDeletePAWSIDs);
    const updatedAsinData = nbdCouponData.filter((couponRow) => couponRow.unique_id !== selectedItems[0].unique_id);
    setNbdCouponState({ ...nbdCouponState, nbdDataChanged: true });
    setNbdCouponData(updatedAsinData);
    setNbdConfirmModal(false);
  };

  const submitNBDData = () => {
    setNbdCouponState({ ...nbdCouponState, nbdDataSubmitInProgress: true });

    displayFlashMessage('Submitting the request.', 'info');
    logger.info('Submitting the request in NBD');
    let postBody: SubmitRequestForNBD = {
      delete_data: deletePAWSIds,
      table_alias: projectMetadata.table_alias,
      post_data: nbdCouponData
    };

    postProjectData(postBody)
      .then(() => {
        displayFlashMessage('Request submitted successfully.', 'success');
        logger.info('Request submitted successfully in NBD');
        sendNBDUpdateEmail(userDetails.Alias);
        getNBDCouponData(projectMetadata?.table_alias);
        fetchProjectMetadataInformation();
        setNbdCouponState({ ...nbdCouponState, nbdDataSubmitInProgress: false });
      })
      .catch(() => {
        displayFlashMessage('Unable to submit request.', 'error');
        logger.error('Unable to submit request in NBD');
        setNbdCouponState({ ...nbdCouponState, nbdDataSubmitInProgress: false });
      });
  };

  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => window.location.reload()}>
        <NBDCouponUploadModal
          nbdOriginalData={nbdCouponData}
          showModal={nbdUploadFileModal}
          onCancel={onCancel}
          onConfirm={onConfirm}
        ></NBDCouponUploadModal>
        <NBDConfirmModal
          couponConfirmModal={nbdConfirmModal}
          onConfirmingTheCancel={() => {
            setNbdConfirmModal(false);
          }}
          onConfirmingTheDelete={onConfirmingTheDelete}
        ></NBDConfirmModal>
        <AppLayout
          notifications={<Flashbar items={flashbarItems} />}
          navigationHide={true}
          tools={<CouponMoreInfoToolBar />}
          toolsOpen={toolsOpen}
          onToolsChange={({ detail }) => setToolsOpen(detail.open)}
          splitPanelOpen={splitPanelOpen}
          splitPanelPreferences={splitPanelPrefs}
          onSplitPanelPreferencesChange={onSplitPanelPreferencesChange}
          onSplitPanelResize={onSplitPanelResize}
          onSplitPanelToggle={onSplitPanelToggle}
          splitPanelSize={300}
          splitPanel={
            <SplitPanel className="fda-split-panel" hidePreferencesButton header={panelHeader} i18nStrings={SPLIT_PANEL_I18NSTRINGS}>
              {panelBody}
            </SplitPanel>
          }
          maxContentWidth={Number.MAX_VALUE}
          contentType="table"
          content={
            <>
              <SpaceBetween size="m" direction="vertical">
                <Container>
                  <Grid gridDefinition={[{ colspan: 4 }, { colspan: 1 }, { colspan: 2 }, { colspan: 3 }, { colspan: 2 }]}>
                    <Header>
                      <Box fontWeight="normal" variant="h2">
                        {NBD_COUPON_REDEMPTION_TITLE}
                      </Box>
                    </Header>
                    <div>
                      {userDetails.isAdmin && (
                        <Toggle
                          onChange={({ detail }) => {
                            changeApprovalMode(detail);
                          }}
                          checked={couponApprovalMode}
                          disabled={splitPanelOpen}
                        >
                          {couponApprovalMode ? 'Approval Mode' : 'Normal Mode'}
                        </Toggle>
                      )}
                    </div>

                    <div>
                      <Box variant="awsui-key-label">Redshift Sync Status</Box>
                      <StatusIndicator
                        type={projectMetadata?.redshift_sync === 'Y' ? 'success' : 'pending'}
                        colorOverride={projectMetadata?.redshift_sync === 'Y' ? 'green' : 'blue'}
                      >
                        {projectMetadata?.redshift_sync === 'Y' ? 'In sync' : 'Out of sync' + ' with Redshift'}
                      </StatusIndicator>
                    </div>

                    <div>
                      <Box variant="awsui-key-label">Last Updated</Box>
                      <div>{Time.getCurrentUserLocalTimeReadableFormat(projectMetadata?.updated_time)}</div>
                    </div>

                    <div>
                      <Box variant="awsui-key-label">Updated by</Box>
                      <div>{projectMetadata?.updated_user}</div>
                    </div>
                  </Grid>
                </Container>
                <Table
                  className="fda-table"
                  variant="container"
                  header={
                    <Header
                      counter={
                        nbdCouponData && (selectedItems!.length ? `(${selectedItems!.length}/${nbdCouponData.length})` : `(${nbdCouponData.length})`)
                      }
                      actions={
                        <>
                          {!couponApprovalMode && (
                            <SpaceBetween direction="horizontal" size="xs">
                              <Button
                                onClick={() => getNBDCouponData(projectMetadata.table_alias)}
                                disabled={!nbdCouponState.nbdDataFetched || nbdCouponState.nbdDataSubmitInProgress || nbdCouponState.nbdDataLoading}
                              >
                                Reset changes
                              </Button>
                              <ButtonDropdown
                                disabled={!nbdCouponState.nbdDataFetched || nbdCouponState.nbdDataSubmitInProgress}
                                onItemClick={({ detail }) => {
                                  downloadAsFile(detail.id);
                                }}
                                items={FILE_DOWNLOAD_OPTIONS}
                              >
                                Download As
                              </ButtonDropdown>
                              <Button
                                onClick={uploadNBDCouponFile}
                                disabled={nbdCouponState.nbdDataSubmitInProgress || nbdCouponState.nbdDataLoading}
                              >
                                Upload from a file
                              </Button>
                              <Button
                                onClick={() => handleEdit()}
                                disabled={
                                  !isOnlyOneSelected ||
                                  !nbdCouponState.nbdDataFetched ||
                                  nbdCouponState.nbdDataSubmitInProgress ||
                                  nbdCouponState.nbdDataLoading
                                }
                              >
                                Edit
                              </Button>
                              <Button
                                onClick={() => handleAdd()}
                                disabled={!nbdCouponState.nbdDataFetched || nbdCouponState.nbdDataSubmitInProgress || nbdCouponState.nbdDataLoading}
                              >
                                Add
                              </Button>
                              <Button
                                disabled={
                                  selectedItems?.length === 0 ||
                                  !nbdCouponState.nbdDataFetched ||
                                  nbdCouponState.nbdDataSubmitInProgress ||
                                  nbdCouponState.nbdDataLoading
                                }
                                onClick={() => handleRemove()}
                              >
                                Remove
                              </Button>
                              <Button
                                variant="primary"
                                onClick={submitNBDData}
                                disabled={!nbdCouponState.nbdDataChanged || nbdCouponState.nbdDataSubmitInProgress || nbdCouponState.nbdDataLoading}
                              >
                                Submit
                              </Button>
                            </SpaceBetween>
                          )}
                        </>
                      }
                    ></Header>
                  }
                  {...collectionProps}
                  visibleColumns={preferences.visibleContent}
                  trackBy={'paws_id'}
                  // resizableColumns
                  wrapLines={preferences.wrapLines}
                  loading={nbdCouponState.nbdDataLoading}
                  loadingText="Loading NBD Coupon Data"
                  columnDefinitions={NBD_COUPON_COLUMN_DEFINITIONS}
                  items={items}
                  selectionType={couponApprovalMode ? 'multi' : 'single'}
                  stickyHeader
                  onSelectionChange={({ detail }) => tableSelectionChanged(detail)}
                  onRowClick={couponApprovalMode ? undefined : ({ detail }) => onRowClickChanged(detail)}
                  selectedItems={selectedItems}
                  filter={
                    <TextFilter
                      {...filterProps}
                      countText={getMatchesCountText(filteredItemsCount!)}
                      filteringPlaceholder="Search"
                      filteringAriaLabel="Search"
                    />
                  }
                  pagination={
                    <SpaceBetween size="m" direction="horizontal">
                      <Pagination {...paginationProps} ariaLabels={paginationLabels} />
                      <ButtonDropdown
                        expandToViewport
                        onItemClick={(item) => {
                          updatePageSize(item.detail.id);
                        }}
                        items={[
                          { text: '50', id: '50' },
                          { text: '100', id: '100' },
                          { text: '150', id: '150' },
                          { text: '200', id: '200' }
                        ]}
                      >
                        {preferences.pageSize}
                      </ButtonDropdown>
                    </SpaceBetween>
                  }
                />
              </SpaceBetween>
            </>
          }
        />
      </ErrorBoundary>
    </>
  );
};
