import React from 'react';

// This is used for Loading Status
export enum LoadingStatus {
  NotInitiated = 'Not Initiated',
  Loading = 'Loading',
  Completed = 'Completed',
  Failed = 'Failed'
}

// Strictly limiting the UserAuthenticationDetails to Cognito Auth Details
export interface UserAuthContext {
  DisplayName: string;
  Email: string;
  Alias: string;
  UserLDAPGroups: string[];
  isAdmin: boolean;
  isBusinessUser: boolean;
  error: string;
  userAuthDataLoadingStatus: LoadingStatus;
}

// App Application Level Context
export interface AppContext {
  mappings: MappingConfiguration[];
  setMappings: (mappings: MappingConfiguration[]) => void;
  projects: Projects[];
  setProjects: (projects: Projects[]) => void;
  agGridLicenseConfigurationStatus: LoadingStatus;
  contextLoadingError: string | null;
  contextLoadingStatus: LoadingStatus;
}

export interface MappingConfiguration {
  table_nm: string;
  table_alias: string;
  table_cols: string;
  table_dtype: string;
  unique_key: string;
  table_source: string;
}

export interface Projects {
  Project: string;
  ProjectRoute: string;
  ProjectComponent: React.ReactNode;
}
