import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { KatSpinner } from '@amzn/katal-react';
import AppInitWrapper from './components/AppInitWrapper';
import { App } from './components/App';

// All SCSS imports at one place
import './styles/ag-grid-override.scss';
import './styles/FDAStyles.scss';
import './styles/landing-page.scss';

const rootElement = document.getElementById('root');
if (rootElement) {
  const root = ReactDOMClient.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <AppInitWrapper>
        <React.Suspense fallback={<KatSpinner size="large" />}>
          <App />
        </React.Suspense>
      </AppInitWrapper>
    </React.StrictMode>
  );
}
