import { Alert, Box, Button, Modal, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';

interface AccessoryASINModalProps {
  showModal: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}
export const AccessoryASINModal = ({ showModal, onCancel, onConfirm }: AccessoryASINModalProps) => {
  return (
    <Modal
      visible={showModal}
      onDismiss={onCancel}
      header={'Delete ASIN record'}
      closeAriaLabel="Close dialog"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onCancel}>
              Cancel
            </Button>
            <Button variant="primary" onClick={onConfirm}>
              Confirm
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween size="m">
        <Box variant="span">Delete ASIN record permanently? This action cannot be undone.</Box>

        <Alert>
          <Box variant="span" fontWeight="bold">
            Confirm
          </Box>{' '}
          with this action will remove from the view and after{' '}
          <Box variant="span" fontWeight="bold">
            Submit
          </Box>{' '}
          request it will permanently delete from the ASIN.
        </Alert>
      </SpaceBetween>
    </Modal>
  );
};
