import React from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from 'src/utils/AuthProvider';

/*
  PrivateRoutes checks multiple conditions like active, in-active and user type..etc
  For now, we are only checking if user is active or not. 
*/
export const PrivateRoutes = () => {
  const userAuthDetails = useAuth();

  // If user is from Admin or Business team, then user can access entire portal.
  if (userAuthDetails.isAdmin || userAuthDetails.isBusinessUser) {
    return <Outlet />;
  } else {
    return <Navigate to="/" />;
  }
};
