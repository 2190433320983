import React from 'react';
import { Checkbox, CollectionPreferences, CollectionPreferencesProps, Input, StatusIndicator, TableProps } from '@amzn/awsui-components-react';
import { Link } from 'react-router-dom';

export const MAPPINGS_CONFIG_COLUMN_DEFINITIONS: TableProps.ColumnDefinition<any>[] = [
  {
    id: 'table_source',
    sortingField: 'table_source',
    header: 'Source',
    cell: (item: any) => item.table_source
  },
  {
    id: 'table_nm',
    sortingField: 'table_nm',
    header: 'Table Name',
    // cell: (item: any) => item.table_nm,
    cell: (item: any) => <Link to={'/mappings/mappings-configuration/' + item.table_nm}>{item.table_nm}</Link>
  },
  {
    id: 'table_alias',
    sortingField: 'table_alias',
    header: 'Table Alias',
    cell: (item: any) => item.table_alias
  },
  {
    id: 'unique_key',
    sortingField: 'unique_key',
    header: 'Primary Key',
    cell: (item: any) => item.unique_key || '-'
  },
  {
    id: 'table_cols',
    sortingField: 'table_cols',
    header: 'Column Names',
    cell: (item: any) => item.table_cols
  },
  {
    id: 'table_dtype',
    sortingField: 'table_dtype',
    header: 'Column Data Types',
    cell: (item: any) => item.table_dtype
  }
];
