import * as React from 'react';
import { APPLICATION_TITLE, eSIMLinks, PHONE_TOOL_LINK } from 'src/constants/FDAConstants';
import { useNavigate } from 'react-router-dom';
import { AuthContextDetails } from 'src/utils/AuthProvider';
import { useAppContext } from '../AppContextProvider';
import { TopNavigation } from '@amzn/awsui-components-react';

export const FDAPortalTopNavBar = () => {
  const navigate = useNavigate();
  const userContext = React.useContext(AuthContextDetails);
  const appContext = useAppContext();

  const onItemClicked = (w: any) => {
    if (w?.detail?.id) navigate(w?.detail?.id);
  };

  const appTitleClicked = (e: any) => {
    e.preventDefault();
    navigate('/');
  };

  const getProjectsList = () => {
    return appContext.projects
      .sort((a, b) => (a.Project < b.Project ? -1 : 1))
      .map((projectMetadata) => {
        return { id: projectMetadata.ProjectRoute, text: projectMetadata.Project };
      }) as [];
  };

  return (
    <>
      <TopNavigation
        className="top-navigation"
        identity={{
          href: '#',
          title: APPLICATION_TITLE,
          onFollow: appTitleClicked
        }}
        utilities={[
          {
            type: 'button',
            text: 'Mappings',
            external: false,
            onClick: () => navigate('/mappings'),
            externalIconAriaLabel: 'opens Mappings'
          },
          {
            type: 'menu-dropdown',
            title: 'Projects',
            text: 'Projects',
            ariaLabel: 'Projects',
            onItemClick: onItemClicked,
            items: getProjectsList()
          },
          {
            type: 'menu-dropdown',
            text: userContext.DisplayName,
            iconName: 'user-profile',
            items: [
              {
                id: 'user-phone-tool',
                text: userContext.Email,
                href: PHONE_TOOL_LINK + userContext.Alias,
                external: true,
                externalIconAriaLabel: ' (opens in new tab)'
              }
            ]
          },
          {
            type: 'menu-dropdown',
            iconName: 'status-info',
            ariaLabel: 'Info',
            title: 'Raise a ticket',
            items: [
              {
                id: 'feature_request',
                text: 'Feature request',
                href: eSIMLinks.FEATURE_REQUEST,
                external: true,
                externalIconAriaLabel: ' (opens in new tab)'
              },
              {
                id: 'request_access',
                text: 'Request Access',
                href: eSIMLinks.REQUEST_ACCESS,
                external: true,
                externalIconAriaLabel: ' (opens in new tab)'
              },
              {
                id: 'REPORT_AN_ISSUE',
                text: 'Report an issue',
                href: eSIMLinks.REPORT_AN_ISSUE,
                external: true,
                externalIconAriaLabel: ' (opens in new tab)'
              }
            ]
          }
        ]}
        i18nStrings={{
          searchIconAriaLabel: 'Search',
          searchDismissIconAriaLabel: 'Close search',
          overflowMenuTriggerText: 'More',
          overflowMenuTitleText: 'All',
          overflowMenuBackIconAriaLabel: 'Back',
          overflowMenuDismissIconAriaLabel: 'Close menu'
        }}
      />
    </>
  );
};
