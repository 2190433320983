import { SESClient, SESClientConfig, SendEmailCommand, SendEmailCommandInput, SendEmailCommandOutput } from '@aws-sdk/client-ses';
import { getCurrentPSTLocalTimeReadableFormat } from 'src/utils/DateTimeUtils';
import { getCredentialsFromAWSService } from './AuthServices';
import { ENV_CONSTANTS } from 'src/utils/AuthProvider';

type SESEmailFormat = {
  Destination: {
    ToAddresses: string[];
  };
  Source: string;
  Message: {
    Subject: { Data: string };
    Body: MailableBody;
  };
};
type MailableBody = { Text: { Data: string } } | { Html: { Data: string } };

const FDA_INPUT_NOTIFICATION_EMAIL = 'fda-input-tool-notifications@amazon.com';
const FDA_ADMIN_EMAIL = 'fda-input-tool-admins@amazon.com';

const stage: any = 'beta';
const stageInfo = ` (${stage})`;
const TO_ADDRESSES = stage === 'dev' ? [FDA_ADMIN_EMAIL] : [FDA_INPUT_NOTIFICATION_EMAIL];

export const sendNBDUpdateEmail = async (userAlias: string): Promise<SendEmailCommandOutput> => {
  const nbdSubject = 'NBD Coupon Redemption Information Intake Portal has updates awaiting approval' + stageInfo;
  const nbdBody = `Hello Team,

            The data has been updated in NBD Coupon Redemption Information Intake portal by the ${userAlias} at ${getCurrentPSTLocalTimeReadableFormat()}.

            Please review and verify the changes in approval mode and approve the verified edited records.

        Thanks,
        FDA Portal`;
  return sendEmail(nbdSubject, nbdBody);
};

export const sendEmail = async (subject: string, body: string): Promise<SendEmailCommandOutput> => {
  const REGION = ENV_CONSTANTS.ENVIRONMENT_VARIABLES.Region;
  const sesConfig: SESClientConfig = {
    region: REGION,
    credentials: await getCredentialsFromAWSService()
  };
  const sesClient = new SESClient(sesConfig);
  let sendEmailCommand: SendEmailCommandInput = {
    Destination: {
      ToAddresses: TO_ADDRESSES
    },
    Source: FDA_INPUT_NOTIFICATION_EMAIL,
    Message: {
      Subject: { Data: subject },
      Body: { Text: { Data: body } }
    }
  };
  const command = new SendEmailCommand(sendEmailCommand);
  return await sesClient.send(command);
};
