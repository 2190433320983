import { RestAPI } from '@aws-amplify/api-rest';
import * as FDAServices from 'src/constants/APIConstants';
import { SubmitRequestForASIN } from 'src/models/AccessoryASIN';
import { SubmitRequestForNBD } from 'src/models/CouponRedemptionModel';
import { MecPost, PostApproveMEC } from 'src/models/MECAdjustments';

export const fetchProjectMetadata = async () => {
  return await RestAPI.get(FDAServices.FDA_BACKEND_SERVICE, FDAServices.PROJECTS_METADATA_API, {});
};

export const fetchProjectData = async (tableAlias: string, userEnteredASIN?: string) => {
  // Naming convention of request body fields as per backend API
  let requestBody = {
    table_alias: tableAlias,
    search_key_field: {},
    search_fields: {}
  };

  if (userEnteredASIN) {
    requestBody.search_key_field = {
      asin: userEnteredASIN
    };
  }

  return await RestAPI.post(FDAServices.FDA_BACKEND_SERVICE, FDAServices.PROJECT_DATA, {
    body: requestBody
  });
};

export const toggleRedshiftSync = async (redshiftSyncStatus: boolean, tableName: string) => {
  const postBody = { redshift_sync_status: redshiftSyncStatus, table_alias: tableName };
  return await RestAPI.post(FDAServices.FDA_BACKEND_SERVICE, FDAServices.ACCESSORY_ASIN_REDSHIFT_SYNC, {
    body: postBody
  });
};

export const postProjectData = async (_postProjectDataRequestBody: SubmitRequestForASIN | SubmitRequestForNBD) => {
  return await RestAPI.post(FDAServices.FDA_BACKEND_SERVICE, FDAServices.PROJECT_DATA_SUBMIT, { body: _postProjectDataRequestBody });
};

export const getMECMonthData = async (plMonth: string) => {
  return await RestAPI.post(FDAServices.FDA_BACKEND_SERVICE, FDAServices.MEC_DATA, { body: { pl_month: plMonth } });
};

export const getMECMetadata = async () => {
  return await RestAPI.get(FDAServices.FDA_BACKEND_SERVICE, FDAServices.MEC_RETAIL_SCREEN_COUNTRY, {});
};

export const postMECMetadata = async (postBody: MecPost) => {
  return await RestAPI.post(FDAServices.FDA_BACKEND_SERVICE, FDAServices.POST_MEC_DATA, { body: postBody });
};

export const approveMECMetadata = async (postBody: PostApproveMEC) => {
  return await RestAPI.post(FDAServices.FDA_BACKEND_SERVICE, FDAServices.POST_APPROVED_MEC_DATA, { body: postBody });
};

export const clearMECMetadata = async (plMonth: string) => {
  return await RestAPI.post(FDAServices.FDA_BACKEND_SERVICE, FDAServices.CLEAR_APPROVED_MEC_DATA, { body: { pl_month: plMonth } });
};

export const mapMECAdjustmentData = async (plMonth: string) => {
  return await RestAPI.post(FDAServices.FDA_BACKEND_SERVICE, FDAServices.MAP_MEC_APPROVED_MEC_DATA, { body: { pl_month: plMonth } });
};

export const getUserMappings = async () => {
  return await RestAPI.get(FDAServices.FDA_BACKEND_SERVICE, FDAServices.USER_MAPPINGS, {});
};

export const getMappingsData = async (mappingTableAlias: string) => {
  const requestBody = { table_alias: mappingTableAlias };
  return await RestAPI.post(FDAServices.FDA_BACKEND_SERVICE, FDAServices.MAPPINGS_DATA, { body: requestBody });
};

export const postMapData = async (postBody: any[], tableAlias: string) => {
  const requestBody = [postBody, { table_alias: tableAlias }];
  return await RestAPI.post(FDAServices.FDA_BACKEND_SERVICE, FDAServices.SUBMIT_MAPPINGS_DATA, { body: requestBody });
};
