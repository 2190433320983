import {
  AppLayout,
  Button,
  Container,
  Flashbar,
  FlashbarProps,
  Header,
  SpaceBetween,
  StatusIndicator,
  StatusIndicatorProps
} from '@amzn/awsui-components-react';
import { KatFileUploadStatus } from '@amzn/katal-components/es/file-upload';
import { KatFileUpload } from '@amzn/katal-react';
import { isEmpty } from '@aws-amplify/core';

import React, { useContext, useEffect, useState } from 'react';
import { LoadingSpinner } from 'src/components/GenericComponents/Spinner';
import { AccessoryFileValidation, ValidationEntity, VALIDATION_NOT_INITIATED, VALIDATION_STARTED } from 'src/constants/FDAConstants';
import { ASINDataEntity, ASINGlobalValues, ProjectsEntity, SubmitRequestForASIN } from 'src/models/AccessoryASIN';
import { postProjectData } from 'src/services/FDAServices';
import { AuthContextDetails } from 'src/utils/AuthProvider';
import { getCurrentUserLocalTime } from 'src/utils/DateTimeUtils';
import { logger } from 'src/utils/FDALogger';
import { exportAsCSVFile, getDataFromCsvFile, getHeaderFromCsvFile } from 'src/utils/FileServices';

const INITIAL_VALIDATION_STATUS: AccessoryFileValidation = {
  HeadersMatching: { ...VALIDATION_NOT_INITIATED, validationMessage: 'Header validation' },
  DuplicateRecords: { ...VALIDATION_NOT_INITIATED, validationMessage: 'Duplicate records validation' },
  NonSyncGlobalValues: { ...VALIDATION_NOT_INITIATED, validationMessage: 'Global values validation' },
  RequiredFiledMissing: { ...VALIDATION_NOT_INITIATED, validationMessage: 'Required field validation' },
  InterdependentColumns: {
    ...VALIDATION_NOT_INITIATED,
    validationMessage: 'Interdependent dropdowns validation'
  },
  DropdownsNotMatched: { ...VALIDATION_NOT_INITIATED, validationMessage: 'Dropdowns validation' }
};

interface MultiASINUploadProps {
  projectMetadata: ProjectsEntity;
}

interface FileState {
  fileUploadState: FileUploadState;
}

interface FileUploadState {
  status: KatFileUploadStatus;
  error?: string;
}

export const MultiASINUpload = (props: MultiASINUploadProps) => {
  const UPLOAD_FILE_HEADERS_FORMAT = [
    'asin',
    'marketplace_id',
    'compatible_screen',
    'compatible_device',
    'item_name',
    'parent_asin',
    'brand',
    'color',
    'gl',
    'product_line',
    'product_desc',
    'accessory_program',
    'merchant_type',
    'product_category',
    'is_generic',
    'is_refurb'
  ];

  const [projectMetadata, setProjectMetadata] = React.useState<ProjectsEntity>({} as ProjectsEntity);
  const [file, setFile] = useState<File | null>(null);
  const [fileState, setFileState] = useState<FileState>({
    fileUploadState: { status: KatFileUploadStatus.READY, error: '' }
  });
  const [globalAsins, setGlobalAsins] = useState<any[]>([]);
  const [multiAccessoryASINData, setMultiAccessoryASINData] = useState<ASINDataEntity[]>([]);
  const [fileValidation, setFileValidation] = useState<AccessoryFileValidation>(INITIAL_VALIDATION_STATUS);
  const userDetails = useContext(AuthContextDetails);
  const [flashbarItems, setFlashbarItems] = useState<FlashbarProps.MessageDefinition[]>([]);
  const [uploadingFile, setUploadingFile] = useState<boolean>(false);
  const accessoryCsvParseConfig = { header: true, skipEmptyLines: true };

  useEffect(() => {
    setProjectMetadata(props.projectMetadata);
  }, [props.projectMetadata]);

  const handleOnFileUpload = async (event: KatFileUpload.FilesAttachedEvent) => {
    setFile(event.detail.files[0]);
    const fileInfo = await Promise.all([
      getHeaderFromCsvFile(event.detail.files[0]),
      getDataFromCsvFile(event.detail.files[0], accessoryCsvParseConfig)
    ]);
    const fileHeader: string[] = fileInfo[0];
    const fileData: ASINDataEntity[] = fileInfo[1];
    ValidateMultiASINData(fileHeader, fileData);
  };

  const handleOnFileReplacement = async (event: KatFileUpload.FilesReplacedEvent) => {
    setFile(event.detail.newFiles[0]);
    const fileInfo = await Promise.all([
      getHeaderFromCsvFile(event.detail.newFiles[0]),
      getDataFromCsvFile(event.detail.newFiles[0], accessoryCsvParseConfig)
    ]);
    const fileHeader: string[] = fileInfo[0];
    const fileData: ASINDataEntity[] = fileInfo[1];
    ValidateMultiASINData(fileHeader, fileData);
  };

  const handleOnFileRemove = (event: KatFileUpload.FilesRemovedEvent) => {
    setFile(null);
    setMultiAccessoryASINData([]);
  };

  const ValidateMultiASINData = async (fileHeader: string[], multiASINData: ASINDataEntity[]) => {
    setFileValidation(INITIAL_VALIDATION_STATUS);

    validateHeaders(fileHeader).then(async (headerValidation) => {
      if (headerValidation.validationStatus === 'success') {
        setFileValidation({
          ...fileValidation,
          HeadersMatching: headerValidation,
          DuplicateRecords: { ...VALIDATION_STARTED, validationMessage: 'Validating duplicate records' },
          NonSyncGlobalValues: { ...VALIDATION_STARTED, validationMessage: 'Validating global records' },
          RequiredFiledMissing: { ...VALIDATION_STARTED, validationMessage: 'Validating required fields' },
          InterdependentColumns: { ...VALIDATION_STARTED, validationMessage: 'Validating interdependent dropdowns' },
          DropdownsNotMatched: { ...VALIDATION_STARTED, validationMessage: 'Validating dropdown values' }
        });

        // Adding unique_id, is_locked, updated_user and updated_time to the uploaded file
        multiASINData.forEach((excelRow, index) => {
          excelRow.unique_id = getUniqueId(excelRow);
          excelRow.is_locked = 'Y';
          excelRow.updated_user = userDetails.Alias;
          excelRow.updated_time = getCurrentUserLocalTime();
        });

        setMultiAccessoryASINData(multiASINData);

        // Validating the uploaded file data
        const validations = await Promise.all([
          validateRequiredFields(multiASINData),
          validateDropdownColumns(multiASINData),
          validateInterdependentColumnValues(multiASINData),
          validateGlobalValues(multiASINData),
          validateDuplicateRecords(multiASINData)
        ]);

        // Updating the UI about the validation status
        setFileValidation({
          ...fileValidation,
          HeadersMatching: headerValidation,
          RequiredFiledMissing: validations[0],
          DropdownsNotMatched: validations[1],
          InterdependentColumns: validations[2],
          NonSyncGlobalValues: validations[3],
          DuplicateRecords: validations[4]
        });
      }
    });
  };

  const validateRequiredFields = (multiASINData: ASINDataEntity[]): Promise<ValidationEntity> => {
    return new Promise((resolve, reject) => {
      multiASINData.forEach((excelRow, index) => {
        Object.keys(excelRow).forEach((column) => {
          if (isRequiredField(column)) {
            if (!excelRow[column]) {
              return resolve({
                colorOverride: 'red',
                validationMessage: column + ' is required at row ' + excelRowNumber(index),
                validationStatus: 'error'
              });
            }
            if (column == 'is_generic') {
              if (excelRow[column] != 'Y' && excelRow[column] != 'N') {
                return resolve({
                  colorOverride: 'red',
                  validationMessage: column + " only allows Y/N values. '" + excelRow[column] + "' is invalid at row " + excelRowNumber(index),
                  validationStatus: 'error'
                });
              }
            }
            if (column == 'is_refurb') {
              if (excelRow[column] != 'Y' && excelRow[column] != 'N') {
                return resolve({
                  colorOverride: 'red',
                  validationMessage: column + " only allows Y/N values. '" + excelRow[column] + "' is invalid at row " + excelRowNumber(index),
                  validationStatus: 'error'
                });
              }
            }
          }
        });
      });
      return resolve({
        colorOverride: 'green',
        validationMessage: 'Required Fields are valid',
        validationStatus: 'success'
      });
    });
  };

  const validateDropdownColumns = (multiASINData: ASINDataEntity[]): Promise<ValidationEntity> => {
    return new Promise((resolve, reject) => {
      multiASINData.forEach((excelRow, index) => {
        Object.keys(excelRow).forEach((column) => {
          if (!isEmpty(excelRow.accessory_program)) {
            const accessory_programFound = projectMetadata.dropdown_fields?.accessory_program?.find((_data) => _data == excelRow.accessory_program);

            if (!accessory_programFound) {
              return resolve({
                colorOverride: 'red',
                validationMessage: 'Invalid Accessory Program - ' + excelRow.accessory_program + ' at row ' + excelRowNumber(index),
                validationStatus: 'error'
              });
            }
          }

          const merchant_typeFound = projectMetadata.dropdown_fields?.merchant_type?.find((_data) => _data == excelRow.merchant_type);
          if (!merchant_typeFound) {
            return resolve({
              colorOverride: 'red',
              validationMessage: 'Invalid Merchant Type - ' + excelRow.merchant_type + ' at row ' + excelRowNumber(index),
              validationStatus: 'error'
            });
          }
        });
      });
      return resolve({
        colorOverride: 'green',
        validationMessage: 'Dropdown fields are valid',
        validationStatus: 'success'
      });
    });
  };

  const validateInterdependentColumnValues = (multiASINData: ASINDataEntity[]): Promise<ValidationEntity> => {
    return new Promise((resolve, reject) => {
      multiASINData.forEach((excelRow, index) => {
        Object.keys(excelRow).forEach((column) => {
          // Checking whether the combination of GL, Product Line and Product Description are valid
          const glFound = projectMetadata.dropdown_fields?.gl_group?.find((_gl) => _gl.gl == excelRow.gl);

          if (isEmpty(glFound)) {
            return resolve({
              colorOverride: 'red',
              validationMessage: 'Invalid GL - ' + excelRow.gl + ' at row ' + excelRowNumber(index),
              validationStatus: 'error'
            });
          }

          if (glFound?.product_line != excelRow.product_line) {
            return resolve({
              colorOverride: 'red',
              validationMessage: 'Invalid Product Line - ' + excelRow.product_line + ' at row ' + excelRowNumber(index),
              validationStatus: 'error'
            });
          }

          if (glFound?.product_desc != excelRow.product_desc) {
            return resolve({
              colorOverride: 'red',
              validationMessage: 'Invalid Product Description - ' + excelRow.product_desc + ' at row ' + excelRowNumber(index),
              validationStatus: 'error'
            });
          }

          // Checking whether the combination of Screen and Device are valid
          const screenFound = projectMetadata.dropdown_fields?.compatible_group?.find(
            (_screen) => _screen.compatible_screen == excelRow.compatible_screen
          );

          if (screenFound) {
            if (excelRow.compatible_device != '' && excelRow.compatible_device != null) {
              const deviceFound = screenFound.compatible_device.find((_device) => _device == excelRow.compatible_device);
              if (deviceFound) {
                return 'valid';
              } else {
                return resolve({
                  colorOverride: 'red',
                  validationMessage: 'Invalid combination of Compatible Screen & Device ' + ' at row ' + excelRowNumber(index),
                  validationStatus: 'error'
                });
              }
            } else {
              return resolve({
                colorOverride: 'red',
                validationMessage: 'Compatible Device is required ' + ' at row ' + excelRowNumber(index),
                validationStatus: 'error'
              });
            }
          } else {
            return resolve({
              colorOverride: 'red',
              validationMessage: 'Invalid Compatible Screen - ' + excelRow.compatible_screen + ' at row ' + excelRowNumber(index),
              validationStatus: 'error'
            });
          }
        });
      });
      return resolve({
        colorOverride: 'green',
        validationMessage: 'Interdependent columns are valid',
        validationStatus: 'success'
      });
    });
  };

  const validateGlobalValues = (multiASINData: ASINDataEntity[]): Promise<ValidationEntity> => {
    return new Promise((resolve, reject) => {
      const asin_globals: ASINGlobalValues[] = [];

      multiASINData.forEach((excelRow, index) => {
        Object.keys(excelRow).forEach((column) => {
          const distinctASINS = multiASINData.map((item) => item.asin).filter((value, index, self) => self.indexOf(value) === index);

          distinctASINS.forEach((_distinctASIN) => {
            let distinctASINArray: any = {
              asin: '',
              accessory_program: null,
              merchant_type: null,
              product_category: null,
              is_generic: null,
              is_refurb: null,
              is_locked: 'Y'
            };

            distinctASINArray.asin = _distinctASIN;

            let accessoryProgram = multiASINData
              .filter((data) => data.asin == _distinctASIN)
              .map((item) => item.accessory_program)
              .filter((value, index, self) => self.indexOf(value) === index);

            if (accessoryProgram.length > 1) {
              return resolve({
                colorOverride: 'red',
                validationMessage: "ASIN '" + _distinctASIN + "' has multiple values for accessory_program column",
                validationStatus: 'error'
              });
            } else {
              distinctASINArray.accessory_program = isEmpty(accessoryProgram[0]) ? null : accessoryProgram[0];
            }

            let merchantType = multiASINData
              .filter((data) => data.asin == _distinctASIN)
              .map((item) => item.merchant_type.trim())
              .filter((value, index, self) => self.indexOf(value) === index);

            if (merchantType.length > 1) {
              return resolve({
                colorOverride: 'red',
                validationMessage: "ASIN '" + _distinctASIN + "' has multiple values for merchant_type column",
                validationStatus: 'error'
              });
            } else {
              distinctASINArray.merchant_type = merchantType[0];
            }

            let productCategory = multiASINData
              .filter((data) => data.asin == _distinctASIN)
              .map((item) => item.product_category.trim())
              .filter((value, index, self) => self.indexOf(value) === index);

            if (productCategory.length > 1) {
              return resolve({
                colorOverride: 'red',
                validationMessage: "ASIN '" + _distinctASIN + "' has multiple values for product_category column",
                validationStatus: 'error'
              });
            } else {
              distinctASINArray.product_category = productCategory[0];
            }

            let isGeneric = multiASINData
              .filter((data) => data.asin == _distinctASIN)
              .map((item) => item.is_generic)
              .filter((value, index, self) => self.indexOf(value) === index);

            if (isGeneric.length > 1) {
              return resolve({
                colorOverride: 'red',
                validationMessage: "ASIN '" + _distinctASIN + "' has multiple values for is_generic column",
                validationStatus: 'error'
              });
            } else {
              distinctASINArray.is_generic = isGeneric[0];
            }

            let isRefurb = multiASINData
              .filter((data) => data.asin == _distinctASIN)
              .map((item) => item.is_refurb)
              .filter((value, index, self) => self.indexOf(value) === index);
            if (isRefurb.length > 1) {
              return resolve({
                colorOverride: 'red',
                validationMessage: "ASIN '" + _distinctASIN + "' has multiple values for isRefurb column",
                validationStatus: 'error'
              });
            } else {
              distinctASINArray.is_refurb = isRefurb[0];
            }

            if (!asin_globals.find((globals) => globals.asin === distinctASINArray.asin)) asin_globals.push(distinctASINArray);
          });
        });
      });
      setGlobalAsins(asin_globals);
      return resolve({
        colorOverride: 'green',
        validationMessage: 'Global fields are valid',
        validationStatus: 'success'
      });
    });
  };

  const validateDuplicateRecords = (multiASINData: ASINDataEntity[]): Promise<ValidationEntity> => {
    return new Promise((resolve, reject) => {
      multiASINData.forEach((excelRow, index) => {
        Object.keys(excelRow).forEach((column) => {
          if (multiASINData.filter((thing, i, arr) => arr.findIndex((t) => t.unique_id === thing.unique_id) === i).length != multiASINData.length) {
            return resolve({
              colorOverride: 'red',
              validationMessage: 'Duplicate records found',
              validationStatus: 'error'
            });
          }
        });
      });
      return resolve({
        colorOverride: 'green',
        validationMessage: 'No duplicates found',
        validationStatus: 'success'
      });
    });
  };

  const validateHeaders = (fileHeader: string[]): Promise<ValidationEntity> => {
    return new Promise((resolve, reject) => {
      if (JSON.stringify(UPLOAD_FILE_HEADERS_FORMAT) !== JSON.stringify(fileHeader)) {
        setFileState({ fileUploadState: { status: KatFileUploadStatus.ERROR, error: "Headers didn't match" } });
        return resolve({
          colorOverride: 'red',
          validationMessage: "Headers didn't match",
          validationStatus: 'error'
        });
      } else
        return resolve({
          colorOverride: 'green',
          validationMessage: 'Valid File',
          validationStatus: 'success'
        });
    });
  };

  const isRequiredField = (column: string) => {
    return column != 'item_name' && column != 'parent_asin' && column != 'brand' && column != 'color' && column != 'accessory_program';
  };

  const excelRowNumber = (index: number) => index + 2;

  const getUniqueId = (excelRow: ASINDataEntity) => {
    return excelRow.asin.trim() + '#' + excelRow.marketplace_id.trim() + '#' + excelRow.compatible_device.trim();
  };

  const isValidFile = () => {
    return (
      fileValidation.DropdownsNotMatched.validationStatus === 'success' &&
      fileValidation.DuplicateRecords.validationStatus === 'success' &&
      fileValidation.HeadersMatching.validationStatus === 'success' &&
      fileValidation.InterdependentColumns.validationStatus === 'success' &&
      fileValidation.NonSyncGlobalValues.validationStatus === 'success' &&
      fileValidation.RequiredFiledMissing.validationStatus === 'success'
    );
  };

  const downloadSampleFile = () => {
    const excelDataToBe: any[] = [
      {
        asin: '',
        marketplace_id: '',
        compatible_screen: '',
        compatible_device: '',
        item_name: '',
        parent_asin: '',
        brand: '',
        color: '',
        gl: '',
        product_line: '',
        product_desc: '',
        accessory_program: '',
        merchant_type: '',
        product_category: '',
        is_generic: '',
        is_refurb: ''
      }
    ];
    exportAsCSVFile(excelDataToBe, 'Accessory-ASIN-Sample', 'Accessory ASIN Mappings');
  };

  const uploadMultiASINFile = () => {
    setUploadingFile(true);
    setFileState({ fileUploadState: { status: KatFileUploadStatus.UPLOADING, error: "Headers didn't match" } });
    setFlashbarItems([
      {
        type: 'info',
        content: 'Uploading the file',
        dismissible: true,
        dismissLabel: 'Dismiss message',
        onDismiss: () => setFlashbarItems([])
      }
    ]);

    let _postProjectDataRequestBody: SubmitRequestForASIN = {
      table_alias: projectMetadata?.table_alias,
      post_data: multiAccessoryASINData,
      asin_globals: globalAsins,
      delete_data: []
    };

    postProjectData(_postProjectDataRequestBody)
      .then((postResponse) => {
        displayFlashMessage('Successfully submitted multi ASIN file', 'success');
        setUploadingFile(false);
        setFile(null);
        setFileValidation(INITIAL_VALIDATION_STATUS);
        setFileState({ fileUploadState: { status: KatFileUploadStatus.COMPLETE } });
      })
      .catch((err) => {
        logger.error(err);
        displayFlashMessage('Multi ASIN file upload failed', 'error');
        setUploadingFile(false);
      });
  };

  const displayFlashMessage = (content: string, flashBarType: FlashbarProps.Type) => {
    setFlashbarItems([
      {
        type: flashBarType,
        content: content,
        dismissible: true,
        dismissLabel: 'Dismiss message',
        onDismiss: () => setFlashbarItems([])
      }
    ]);
  };

  return (
    <>
      <SpaceBetween size="m" direction="vertical">
        <Flashbar items={flashbarItems} />
        <Container
          header={
            <Header
              actions={
                <SpaceBetween size="m">
                  <Button variant="normal" onClick={downloadSampleFile}>
                    Download Sample File
                  </Button>
                </SpaceBetween>
              }
            >
              File Selection
            </Header>
          }
        >
          <SpaceBetween size="m" direction="vertical">
            {!uploadingFile && (
              <KatFileUpload
                accept=".csv, text/csv"
                file-view="list-preview"
                variant="large"
                required
                disabled={uploadingFile}
                label="Accessory Multi ASIN File"
                name="ASIN"
                multiple={false}
                state={fileState}
                onFilesAttached={(e) => handleOnFileUpload(e)}
                onFilesReplaced={(e) => handleOnFileReplacement(e)}
                onFilesRemoved={(e) => handleOnFileRemove(e)}
              >
                <div slot="hint">{'Accepted file format: .csv'}</div>
                <div slot="constraints-message">{'Accepted file format: .csv'}</div>
                <div slot="error">{fileState.fileUploadState.error}</div>
              </KatFileUpload>
            )}
            {uploadingFile && <LoadingSpinner />}
          </SpaceBetween>
        </Container>
        <Container
          header={
            <Header
              actions={
                <SpaceBetween size="m">
                  <Button variant="primary" disabled={!isValidFile() || uploadingFile} onClick={uploadMultiASINFile}>
                    Upload
                  </Button>
                </SpaceBetween>
              }
            >
              {'File Validation & Upload'}
            </Header>
          }
        >
          <SpaceBetween size="m" direction="vertical">
            {Object.keys(fileValidation).map((key, index) => {
              return (
                <StatusIndicator key={index} type={fileValidation[key].validationStatus} colorOverride={fileValidation[key].colorOverride}>
                  {fileValidation[key].validationMessage}
                </StatusIndicator>
              );
            })}
          </SpaceBetween>
        </Container>
      </SpaceBetween>
    </>
  );
};
