import React from 'react';
import { HelpPanel, Icon, Link } from '@amzn/awsui-components-react';

export const MappingsToolInfo = () => {
  return (
    <HelpPanel header={<h2>More Info</h2>} footer={<></>}>
      <p>
        {
          'Allows FDA team to create or update the lookup/reference table dynamically in a batch process mode. Users are provided with an option to add custom dropdown fields with values or dropdowns by referencing another table values.'
        }
      </p>
    </HelpPanel>
  );
};
