import { useEffect, useState } from 'react';

function localPrefPath(pageName: string, prefName: string) {
  const realPageName = pageName.split('/')[1];
  return `${realPageName || pageName}_${prefName}`;
}

export function useObjectPref<T>(pageName: string, prefName: string, defaultValue?: any) {
  const state = useState<T | undefined>(() => {
    const value = getStringPref(pageName, prefName);
    if (value === null) return defaultValue;
    return JSON.parse(value);
  });
  const [objectValue, _] = state;

  useEffect(() => {
    if (objectValue !== undefined && objectValue != defaultValue) putStringPref(pageName, prefName, JSON.stringify(objectValue));
  }, [objectValue]);

  return state;
}

export function useNumberPref(pageName: string, prefName: string, defaultValue?: number) {
  const state = useState<number | undefined>(() => {
    const value = getStringPref(pageName, prefName);
    if (value === null) return defaultValue;
    return Number(value);
  });
  const [value, _] = state;

  useEffect(() => {
    if (value !== undefined) putStringPref(pageName, prefName, value.toString());
  }, [value]);

  return state;
}

export function clearPref(pageName: string, prefName: string) {
  localStorage.removeItem(localPrefPath(pageName, prefName));
}

function getStringPref(pageName: string, prefName: string) {
  return localStorage.getItem(localPrefPath(pageName, prefName));
}

function putStringPref(pageName: string, prefName: string, value: string) {
  localStorage.setItem(localPrefPath(pageName, prefName), value);
}
