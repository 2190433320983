import { CollectionPreferencesProps, RadioGroupProps, StatusIndicator, TableProps } from '@amzn/awsui-components-react';
import { MECAdjustments } from 'src/models/MECAdjustments';
import React from 'react';
import { convertDateTimeToReadableFormat, getCurrentUserLocalTimeReadableFormat } from 'src/utils/DateTimeUtils';

export const ACCESSORY_ASIN_COLUMN_DEFINITIONS: TableProps.ColumnDefinition<MECAdjustments>[] = [
  {
    id: 'mec_id',
    header: 'Mec Id',
    cell: (item) => item.mec_id || null,
    sortingField: 'mec_id'
  },
  {
    id: 'pl_month',
    header: 'PL Month',
    cell: (item) => item.pl_month || null,
    sortingField: 'pl_month'
  },
  {
    id: 'approved_flag',
    header: 'Approval Status',
    cell: (item) =>
      (
        <StatusIndicator colorOverride={item.approved_flag === 'Y' ? 'green' : 'blue'} type={item.approved_flag === 'Y' ? 'success' : 'pending'}>
          {item.approved_flag === 'Y' ? 'Approved' : 'Pending'}
        </StatusIndicator>
      ) || '',
    sortingField: 'approved_flag'
  },
  {
    id: 'start_date',
    header: 'Start Date',
    cell: (item) => item.start_date || null,
    sortingField: 'start_date'
  },
  {
    id: 'end_date',
    header: 'End Date',
    cell: (item) => item.end_date || null,
    sortingField: 'end_date'
  },
  {
    id: 'retailer_name',
    header: 'Retailer Name',
    cell: (item) => item.retailer_name || null,
    sortingField: 'retailer_name'
  },
  {
    id: 'country',
    header: 'Country',
    cell: (item) => item.country || null,
    sortingField: 'country'
  },
  {
    id: 'screen',
    header: 'Screen',
    cell: (item) => item.screen || null,
    sortingField: 'screen'
  },
  {
    id: 'asin',
    header: 'Asin',
    cell: (item) => item.asin || null,
    sortingField: 'asin'
  },
  {
    id: 'pos_units_adjustment',
    header: 'POS Units Adj',
    cell: (item) => item.pos_units_adjustment,
    sortingField: 'pos_units_adjustment'
  },
  {
    id: 'pos_pct_adjustment',
    header: 'POS PCT Adj',
    cell: (item) => item.pos_pct_adjustment,
    sortingField: 'pos_pct_adjustment'
  },
  {
    id: 'rgu_units_adjustment',
    header: 'RGU Units Adj',
    cell: (item) => item.rgu_units_adjustment,
    sortingField: 'rgu_units_adjustment'
  },
  {
    id: 'rgu_pct_adjustment',
    header: 'RGU PCT Adj',
    cell: (item) => item.rgu_pct_adjustment,
    sortingField: 'rgu_pct_adjustment'
  },
  {
    id: 'adjustment_type',
    header: 'Adjustment Type',
    cell: (item) => item.adjustment_type || null,
    sortingField: 'adjustment_type'
  },
  {
    id: 'channel',
    header: 'Channel',
    cell: (item) => item.channel || null,
    sortingField: 'channel'
  },
  {
    id: 'updated_time',
    header: 'Updated Time',
    cell: (item) => convertDateTimeToReadableFormat(item.updated_time) || null,
    sortingField: 'updated_time'
  },
  {
    id: 'updated_user',
    header: 'Updated By',
    cell: (item) => item.updated_user,
    sortingField: 'updated_user'
  }
];

export const MEC_SELECTOR_OPTIONS: CollectionPreferencesProps.VisibleContentOptionsGroup[] = [
  {
    label: 'MEC Properties',
    options: [
      {
        id: 'mec_id',
        label: 'Mec Id',
        editable: true
      },
      {
        id: 'pl_month',
        label: 'PL Month',
        editable: true
      },
      {
        id: 'approved_flag',
        label: 'Approval Status',
        editable: true
      },
      {
        id: 'start_date',
        label: 'Start Date',
        editable: true
      },
      {
        id: 'end_date',
        label: 'End Date',
        editable: true
      },
      {
        id: 'retailer_name',
        label: 'Retailer Name',
        editable: true
      },
      {
        id: 'country',
        label: 'Country',
        editable: true
      },
      {
        id: 'screen',
        label: 'Screen',
        editable: true
      },
      {
        id: 'asin',
        label: 'ASIN',
        editable: true
      },
      {
        id: 'pos_units_adjustment',
        label: 'POS Units Adj',
        editable: true
      },
      {
        id: 'pos_pct_adjustment',
        label: 'POS PCT Adj',
        editable: true
      },
      {
        id: 'rgu_units_adjustment',
        label: 'RGU Units Adj',
        editable: true
      },
      {
        id: 'rgu_pct_adjustment',
        label: 'RGU PCT Adj',
        editable: true
      },
      {
        id: 'adjustment_type',
        label: 'Adjustment Type',
        editable: true
      },
      {
        id: 'channel',
        label: 'Channel',
        editable: true
      },
      {
        id: 'updated_time',
        label: 'Last Updated Time',
        editable: true
      },
      {
        id: 'updated_user',
        label: 'Last Updated By',
        editable: true
      }
    ]
  }
];

export const MEC_PAGE_SELECTOR_OPTIONS: CollectionPreferencesProps.PageSizeOption[] = [
  { value: 50, label: '50' },
  { value: 100, label: '100' },
  { value: 150, label: '150' },
  { value: 200, label: '200' }
];

export const paginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page'
};

export function getMatchesCountText(count: number) {
  return count === 1 ? `1 match` : `${count} matches`;
}

export const CUSTOM_PREFERENCE_OPTIONS: RadioGroupProps.RadioButtonDefinition[] = [{ value: 'table', label: 'Table' }];

export const MEC_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences = {
  pageSize: 150,
  wrapLines: false,
  visibleContent: [
    // "mec_id",
    'start_date',
    'end_date',
    'retailer_name',
    'country',
    'screen',
    'asin',
    'pos_units_adjustment',
    'pos_pct_adjustment',
    'rgu_units_adjustment',
    'rgu_pct_adjustment',
    'adjustment_type',
    'channel',
    'approved_flag'
  ],
  custom: CUSTOM_PREFERENCE_OPTIONS[0].value
};
