import { AppLayout, Box, Button, ButtonDropdown, Container, Flashbar, FlashbarProps, Header, SpaceBetween } from '@amzn/awsui-components-react';
import { CellValueChangedEvent, ColDef, SelectionChangedEvent, ValueParserParams } from 'ag-grid-community';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FILE_DOWNLOAD_OPTIONS } from 'src/constants/FDAConstants';
import { LoadingStatus, MappingConfiguration } from 'src/models/AppModel';
import { getMappingsData, postMapData } from 'src/services/FDAServices';
import { logger } from 'src/utils/FDALogger';
import { exportAsCSVFile, exportAsExcelFile } from 'src/utils/FileServices';
import { useAppContext } from '../AppContextProvider';
import { LoadingSpinner } from '../GenericComponents/Spinner';
import { MappingsUploadModal } from './MappingsUploadModal';
import { dateParser, nanCellClassRules, nonDateCellClassRules, numberParser } from './MappingValidations';
import { RowData } from './Models/MappingModel';
import { WarningEventType, WarningModal } from './WarningModal';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from '../GenericComponents/ErrorFallback';
import { MappingSideNavigation } from './MappingSideNavigation';
import { MappingsToolInfo } from './MappingsTool';
import { useAuth } from 'src/utils/AuthProvider';
import { getPolarisDateFormat, getSimpleTimeFormat } from 'src/utils/DateTimeUtils';
import { MAPPING_CONFIG_STATUS_ALIAS, MAPPING_CONFIG_STATUS_TABLE_NAME } from './MappingsConfigValidations';

interface TemplateStatus {
  dataChanged: boolean;
  mappingDataLoading: LoadingStatus;
  submitStatus: LoadingStatus;
}
const InitialTemplateState: TemplateStatus = {
  dataChanged: false,
  mappingDataLoading: LoadingStatus.NotInitiated,
  submitStatus: LoadingStatus.NotInitiated
};

export const MappingsTable = () => {
  const { tableAliasParam } = useParams();
  const appContext = useAppContext();
  const userDetails = useAuth();
  const [toolsOpen, setToolsOpen] = useState(false);

  const [flashbarItems, setFlashbarItems] = useState<FlashbarProps.MessageDefinition[]>([]);

  const gridStyle = useMemo(() => ({ height: '80vh', width: '100%' }), []);
  const gridRef = useRef<AgGridReact>({} as AgGridReact);

  const [selectedMappingConfig, setSelectedMappingConfig] = useState({} as MappingConfiguration);
  const [selectedMappingData, setSelectedMappingData] = useState<RowData[]>([]);

  const [templateState, setTemplateState] = useState<TemplateStatus>(InitialTemplateState);
  const [rowData, setRowData] = useState<any[]>([]);
  const [columnDefinitions, setColumnDefinitions] = useState<any[]>([]);

  const [showWarningModal, setShowWarningModal] = useState(false);
  const [showMappingUploadModal, setShowMappingUploadModal] = useState(false);

  useEffect(() => {
    if (tableAliasParam) {
      const selectedMappingConfig = getSelectedMapperConfig(tableAliasParam);
      if (selectedMappingConfig) {
        logger.info(`Fetched Mapping configuration of ${selectedMappingConfig?.table_alias}`);
        setSelectedMappingConfig(selectedMappingConfig);
        initialSetupConfig(selectedMappingConfig);
      } else {
        setTemplateState({ ...templateState, mappingDataLoading: LoadingStatus.Failed, dataChanged: false });
        displayFlashMessage('Unable to find selected mapping table', 'error');
      }
    }
  }, [tableAliasParam]);

  const initialSetupConfig = async (selectedMappingConfig: MappingConfiguration) => {
    if (tableAliasParam) {
      setTemplateState({ ...templateState, mappingDataLoading: LoadingStatus.Loading });

      getMappingsData(tableAliasParam)
        .then((getMappingsDataResponse) => {
          logger.info(`Fetched Mapping data of ${selectedMappingConfig?.table_alias}`);
          logger.info('Fetching Mappings Configuration Master - Response', { info: getMappingsDataResponse?.mappingData });
          setSelectedMappingData(getMappingsDataResponse?.mappingData);
          configureAgGridColumns(getMappingsDataResponse?.mappingData, selectedMappingConfig);
          configureAgGridRowData(getMappingsDataResponse?.mappingData, selectedMappingConfig);
          setTemplateState({ ...templateState, mappingDataLoading: LoadingStatus.Completed, dataChanged: false });
        })
        .catch((error: any) => {
          displayFlashMessage(`Unable to fetch Mappings for ${selectedMappingConfig?.table_alias}`, 'error');
          logger.error(`Unable to fetch Mapping data for ${selectedMappingConfig?.table_alias}`, error);
          setSelectedMappingData([]);
          configureAgGridColumns([], selectedMappingConfig);
          configureAgGridRowData([], selectedMappingConfig);
          setTemplateState({ ...templateState, mappingDataLoading: LoadingStatus.Completed, dataChanged: false });
        });
    }
  };

  const getSelectedMapperConfig = (tableAlias: string) => {
    const mappingConfig = appContext?.mappings?.find((mapping) => mapping.table_alias === tableAlias);
    return mappingConfig;
  };

  const isFirstColumn = (index: number) => {
    return index === 0;
  };

  const configureAgGridColumns = (mappingsData: RowData[], tableConfiguration: MappingConfiguration) => {
    try {
      let agGridColumnDef: any[] = [];
      const columnList = tableConfiguration.table_cols.split(',')?.map((column) => {
        return column.trim();
      });
      if (tableConfiguration.table_alias === MAPPING_CONFIG_STATUS_ALIAS) {
        agGridColumnDef = readOnlyColumnDefinition(columnList);
      } else {
        agGridColumnDef = columnList?.map((column, index) => {
          let columnDataType = tableConfiguration.table_dtype.split(',')[index];
          columnDataType = columnDataType.startsWith('dropdown') ? 'dropdown' : columnDataType;
          switch (columnDataType) {
            case 'dropdown':
              return {
                field: column,
                headerTooltip: 'Dropdown',
                editable: true,
                headerCheckboxSelection: isFirstColumn(index),
                checkboxSelection: isFirstColumn(index),
                cellEditor: 'agRichSelectCellEditor',
                cellEditorPopup: true,
                cellEditorParams: {
                  cellHeight: 50,
                  values: mappingsData[0][column].data
                }
              } as ColDef;
            case 'boolean':
              return {
                field: column,
                headerTooltip: 'Dropdown',
                editable: true,
                headerCheckboxSelection: isFirstColumn(index),
                checkboxSelection: isFirstColumn(index),
                cellEditor: 'agRichSelectCellEditor',
                cellEditorPopup: true,
                cellEditorParams: {
                  cellHeight: 50,
                  values: [true, false]
                }
              } as ColDef;
            case 'numeric' || 'float':
              return {
                field: column,
                headerTooltip: 'numeric/decimal',
                editable: true,
                headerCheckboxSelection: isFirstColumn(index),
                checkboxSelection: isFirstColumn(index),
                cellClassRules: nanCellClassRules,
                valueParser: (params: ValueParserParams) => numberParser(params.newValue.trim())
              } as ColDef;
            case 'datetime':
              return {
                field: column,
                headerTooltip: 'datetime',
                editable: true,
                headerCheckboxSelection: isFirstColumn(index),
                checkboxSelection: isFirstColumn(index),
                cellClassRules: nonDateCellClassRules,
                valueParser: (params: ValueParserParams) => dateParser(params.newValue.trim())
              } as ColDef;
            case 'date':
              return {
                field: column,
                headerTooltip: 'date',
                editable: true,
                headerCheckboxSelection: isFirstColumn(index),
                checkboxSelection: isFirstColumn(index),
                cellClassRules: nonDateCellClassRules,
                valueParser: (params: ValueParserParams) => dateParser(params.newValue.trim())
              } as ColDef;
            case 'CURRENT_USER':
              return {
                field: column,
                headerTooltip: 'CURRENT_USER',
                editable: false,
                headerCheckboxSelection: isFirstColumn(index),
                checkboxSelection: isFirstColumn(index)
              } as ColDef;
            case 'CURRENT_DATE_TIME':
              return {
                field: column,
                headerTooltip: 'CURRENT_DATE_TIME',
                editable: false,
                headerCheckboxSelection: isFirstColumn(index),
                checkboxSelection: isFirstColumn(index),
                cellClassRules: nonDateCellClassRules,
                valueParser: (params: ValueParserParams) => dateParser(params.newValue)
              } as ColDef;
            case 'CURRENT_DATE':
              return {
                field: column,
                headerTooltip: 'CURRENT_DATE',
                editable: false,
                headerCheckboxSelection: isFirstColumn(index),
                checkboxSelection: isFirstColumn(index),
                cellClassRules: nonDateCellClassRules,
                valueParser: (params: ValueParserParams) => dateParser(params.newValue)
              } as ColDef;
            default:
              return {
                field: column,
                headerTooltip: 'text',
                editable: true,
                headerCheckboxSelection: isFirstColumn(index),
                checkboxSelection: isFirstColumn(index),
                valueParser: (params: ValueParserParams) => params.newValue.trim()
              } as ColDef;
          }
        });
      }
      setColumnDefinitions(agGridColumnDef);
    } catch (error: any) {
      logger.error(`Unable to configure column data for ${selectedMappingConfig?.table_alias}`, { error: error });
    }
  };

  const readOnlyColumnDefinition = (columnList: string[]) => {
    return columnList?.map((column, index) => {
      if (column === 'updated_dt') {
        return {
          field: column,
          editable: false,
          headerCheckboxSelection: false,
          checkboxSelection: false,
          sort: 'desc'
        } as ColDef;
      } else {
        return {
          field: column,
          editable: false,
          headerCheckboxSelection: false,
          checkboxSelection: false
        } as ColDef;
      }
    });
  };

  const configureAgGridRowData = (mappingsData: RowData[], tableConfiguration: MappingConfiguration) => {
    try {
      let agGridRowData: any = [];
      mappingsData.forEach((row, index) => {
        let formattedRow: any = {};
        Object.keys(row).forEach((column, index) => {
          const columnDataType = tableConfiguration.table_dtype.split(',')[index];
          formattedRow[column] = columnDataType.startsWith('dropdown') ? row[column]?.field_value : row[column];
        });
        agGridRowData.push(formattedRow);
      });
      setRowData(agGridRowData);
      gridRef?.current?.api?.refreshCells();
    } catch (error: any) {
      logger.error(`Unable to configure row data for ${selectedMappingConfig?.table_alias}`, { error: error });
    }
  };

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
        { statusPanel: 'agTotalRowCountComponent', align: 'center' },
        { statusPanel: 'agFilteredRowCountComponent' },
        { statusPanel: 'agSelectedRowCountComponent' },
        { statusPanel: 'agAggregationComponent' }
      ]
    };
  }, []);

  const defaultAgGridColumnDefinition = useMemo(() => {
    return {
      resizable: true,
      minWidth: 100,
      flex: 1,
      sortable: true
    };
  }, []);

  const onGridReady = useCallback((params: any) => {}, []);

  const onCellValueChanged = useCallback(
    (event: CellValueChangedEvent) => {
      if (selectedMappingConfig && selectedMappingData) {
        setTemplateState({ ...templateState, mappingDataLoading: LoadingStatus.Completed, dataChanged: true });
        const { colDef, data, rowIndex, oldValue, newValue } = event;
        const allRows = rowData.slice();
        if (rowIndex != null) {
          let updatedRows: any[] = [];
          allRows.forEach((row, globalIndex) => {
            if (globalIndex === rowIndex) {
              let formattedRow: any = {};
              Object.keys(row).forEach((column, index) => {
                const columnDataType = selectedMappingConfig.table_dtype.split(',')[index];
                if (columnDataType === 'CURRENT_USER') {
                  formattedRow[column] = userDetails.Alias;
                } else if (columnDataType === 'CURRENT_DATE') {
                  formattedRow[column] = getPolarisDateFormat(getSimpleTimeFormat());
                } else if (columnDataType === 'CURRENT_DATE_TIME') {
                  formattedRow[column] = getSimpleTimeFormat();
                } else {
                  formattedRow[column] = row[column];
                }
              });
              updatedRows.push(formattedRow);
            } else {
              updatedRows.push(row);
            }
          });
          setRowData(updatedRows);
        }
      }
    },
    [rowData]
  );

  const onSelectionChanged = useCallback((selectionChangedEvent: SelectionChangedEvent) => {
    setTemplateState({ ...templateState, mappingDataLoading: LoadingStatus.Completed, dataChanged: true });
  }, []);

  const downloadAsFile = (fileType: any) => {
    logger.info('Mappings - File Downloaded as ' + fileType);
    if (fileType === 'excel') exportAsExcelFile(rowData, 'Mappings', 'Mappings');
    else exportAsCSVFile(rowData, 'Mappings', 'Mappings');
  };

  const deleteSelectedConfirmed = useCallback(() => {
    // Removes selection from the grid
    var selectedRows: any[] = gridRef.current.api.getSelectedRows();
    gridRef?.current?.api?.applyTransaction({ remove: selectedRows });
    setShowWarningModal(false);
    updateGrid();
  }, [rowData]);

  const updateGrid = () => {
    const updatedRowData: any[] = [];
    gridRef.current.api.forEachNode(function (node) {
      updatedRowData.push(node.data);
    });
    setRowData(updatedRowData);
  };

  const submitChanges = () => {
    const agGridAllNodes: any[] = [];
    gridRef.current.api.forEachNode(function (node) {
      agGridAllNodes.push(node.data);
    });

    setRowData(agGridAllNodes);
    logger.info(`Submitting the request for mapping  - ${selectedMappingConfig.table_nm} `, { info: agGridAllNodes });
    postMapData(agGridAllNodes, selectedMappingConfig.table_alias)
      .then((response) => {
        logger.info(`Submitting the request for mapping - Response - ${selectedMappingConfig.table_nm} `, { info: response });
        displayFlashMessage(
          "Request added to the job queue. You will receive email once it is completed. You can also track status in 'FDA Mapper Status' Mapping.",
          'success'
        );
        initialSetupConfig(selectedMappingConfig);
      })
      .catch((error: any) => {
        logger.error(`Submitting the request for mapping - Response - ${selectedMappingConfig.table_nm} `, error);
        displayFlashMessage('Request failed. Please try again or raise a ticket using link ', 'error');
      });
  };

  const addRow = useCallback(() => {
    if (selectedMappingConfig && selectedMappingData) {
      const newStore = rowData.slice();
      let newItem: any = {};
      selectedMappingConfig.table_cols.split(',').map((column: string, index: number) => {
        if (selectedMappingConfig.table_dtype.split(',')[index] === 'CURRENT_USER') {
          newItem[column] = userDetails.Alias;
        } else if (selectedMappingConfig.table_dtype.split(',')[index] === 'CURRENT_DATE') {
          newItem[column] = getPolarisDateFormat(getSimpleTimeFormat());
        } else if (selectedMappingConfig.table_dtype.split(',')[index] === 'CURRENT_DATE_TIME') {
          newItem[column] = getSimpleTimeFormat();
        } else {
          newItem[column] = '';
        }
      });
      newStore.splice(0, 0, newItem);
      setRowData(newStore);
    }
  }, [rowData]);

  const closeModal = (event: WarningEventType) => {
    if (event === 'proceed') {
      deleteSelectedConfirmed();
    } else {
      setShowWarningModal(false);
    }
  };

  const onConfirmUpload = useCallback(
    (uploadedMappingData: any[]) => {
      let agGridRowData: any = [];
      uploadedMappingData.forEach((row, index) => {
        let formattedRow: any = {};
        Object.keys(row).forEach((column, index) => {
          const columnDataType = selectedMappingConfig.table_dtype.split(',')[index];
          if (columnDataType === 'CURRENT_USER') {
            formattedRow[column] = userDetails.Alias;
          } else if (columnDataType === 'CURRENT_DATE') {
            formattedRow[column] = getPolarisDateFormat(getSimpleTimeFormat());
          } else if (columnDataType === 'CURRENT_DATE_TIME') {
            formattedRow[column] = getSimpleTimeFormat();
          } else {
            formattedRow[column] = typeof row[column] === 'string' ? row[column].trim() : row[column];
          }
        });
        agGridRowData.push(formattedRow);
      });

      const newStore = agGridRowData.concat(rowData.slice());
      setRowData(newStore);
      setTemplateState({ ...templateState, dataChanged: true });
      setShowMappingUploadModal(false);
    },
    [rowData]
  );

  const displayFlashMessage = (content: string, flashBarType: FlashbarProps.Type) => {
    setFlashbarItems([
      {
        type: flashBarType,
        content: content,
        dismissible: true,
        dismissLabel: 'Dismiss message',
        onDismiss: () => setFlashbarItems([])
      }
    ]);
  };

  return (
    <>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => {
          window.location.reload();
        }}
      >
        <AppLayout
          navigationHide={false}
          navigation={<MappingSideNavigation />}
          tools={<MappingsToolInfo />}
          notifications={<Flashbar items={flashbarItems} />}
          toolsOpen={toolsOpen}
          onToolsChange={({ detail }) => setToolsOpen(detail.open)}
          content={
            <>
              <MappingsUploadModal
                selectedMappingConfig={selectedMappingConfig}
                uploadFileShowModal={showMappingUploadModal}
                onCancelUpload={() => setShowMappingUploadModal(false)}
                onConfirmUpload={onConfirmUpload}
              ></MappingsUploadModal>
              <WarningModal
                showWarningModal={showWarningModal}
                closeWarningModal={(event: WarningEventType) => {
                  closeModal(event);
                }}
              ></WarningModal>
              <Container
                disableContentPaddings
                header={
                  <Header
                    variant="h1"
                    actions={
                      <>
                        {selectedMappingConfig?.table_nm === MAPPING_CONFIG_STATUS_TABLE_NAME && (
                          <>
                            <Button
                              disabled={templateState.mappingDataLoading !== LoadingStatus.Completed}
                              onClick={() => initialSetupConfig(selectedMappingConfig)}
                            >
                              Refresh
                            </Button>
                          </>
                        )}
                        {selectedMappingConfig?.table_nm !== MAPPING_CONFIG_STATUS_TABLE_NAME && (
                          <>
                            <SpaceBetween direction="horizontal" size="xs">
                              <ButtonDropdown
                                disabled={templateState.mappingDataLoading !== LoadingStatus.Completed}
                                onItemClick={({ detail }) => {
                                  downloadAsFile(detail.id);
                                }}
                                items={FILE_DOWNLOAD_OPTIONS}
                              >
                                Download As
                              </ButtonDropdown>
                              <Button
                                disabled={templateState.mappingDataLoading !== LoadingStatus.Completed}
                                onClick={() => setShowMappingUploadModal(true)}
                              >
                                Upload from csv
                              </Button>
                              <Button disabled={templateState.mappingDataLoading !== LoadingStatus.Completed} onClick={addRow}>
                                Add
                              </Button>
                              <Button
                                onClick={() => setShowWarningModal(true)}
                                disabled={(gridRef?.current?.api?.getSelectedRows() ? gridRef?.current?.api?.getSelectedRows() : []).length === 0}
                              >
                                Delete selected
                              </Button>
                              <Button
                                disabled={templateState.mappingDataLoading !== LoadingStatus.Completed}
                                onClick={() => initialSetupConfig(selectedMappingConfig)}
                              >
                                Reset
                              </Button>
                              <Button
                                disabled={templateState.mappingDataLoading !== LoadingStatus.Completed || !templateState.dataChanged}
                                variant="primary"
                                onClick={() => submitChanges()}
                              >
                                Submit
                              </Button>
                            </SpaceBetween>
                          </>
                        )}
                      </>
                    }
                    // description=
                    description={
                      <>
                        {selectedMappingConfig?.table_nm === MAPPING_CONFIG_STATUS_TABLE_NAME && <></>}
                        {selectedMappingConfig?.table_nm !== MAPPING_CONFIG_STATUS_TABLE_NAME && (
                          <>{'Updated data will be shown after job completed successfully.'}</>
                        )}
                      </>
                    }
                  >
                    <Box fontWeight="normal" variant="h2">
                      {selectedMappingConfig?.table_alias}
                    </Box>
                  </Header>
                }
              >
                <div className="ag-theme-alpine" style={gridStyle}>
                  {templateState.mappingDataLoading === LoadingStatus.Loading && <LoadingSpinner />}
                  {templateState.mappingDataLoading !== LoadingStatus.Loading && (
                    <AgGridReact
                      ref={gridRef}
                      onGridReady={onGridReady}
                      defaultColDef={defaultAgGridColumnDefinition}
                      stopEditingWhenCellsLoseFocus={true}
                      rowData={rowData}
                      columnDefs={columnDefinitions}
                      undoRedoCellEditing={true}
                      undoRedoCellEditingLimit={10}
                      statusBar={statusBar}
                      suppressDragLeaveHidesColumns={true}
                      onCellValueChanged={onCellValueChanged}
                      rowSelection={'multiple'}
                      suppressRowClickSelection={true}
                      tooltipShowDelay={100}
                      onSelectionChanged={onSelectionChanged}
                    ></AgGridReact>
                  )}
                </div>
              </Container>
            </>
          }
        />
      </ErrorBoundary>
    </>
  );
};
