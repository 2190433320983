import { StatusIndicatorProps } from '@amzn/awsui-components-react';

export const TRANSLATIONS_DIR_NAMESPACE = 'translations';

// This route is determined by translationOutputDir in configUtils
export const TRANSLATION_ROUTE = `/${TRANSLATIONS_DIR_NAMESPACE}`;

export const DEFAULT_LOCALE_EN_US = 'en-US';

export const APPLICATION_TITLE = 'FDA';
export const ACCESSORY_ASIN_TITLE = 'Accessory ASIN';
export const NBD_COUPON_REDEMPTION_TITLE = 'NBD Coupon Redemption Information Intake';
export const NBD_SKYNET_REPORT_LINK = 'https://skynet.db.amazon.com/#/workbooks/7947/views';
export const MEC_ADJUSTMENTS_TITLE = 'MEC Customer Adjustments';

export const AMAZON_FEDERATE_OIDC = 'AmazonFederate';
export const PHONE_TOOL_LINK = 'https://phonetool.amazon.com/users/';
// SIM links based on use case
export enum eSIMLinks {
  REQUEST_ACCESS = 'https://t.corp.amazon.com/create/templates/5cbac466-5195-4fdf-a11e-170c67d1ea5a',
  FEATURE_REQUEST = 'https://form.asana.com/?k=CeScTOvZSeFYjFMV7ivu_g&d=8442528107068',
  REPORT_AN_ISSUE = 'https://t.corp.amazon.com/create/templates/8fcfbcec-3340-488c-9b12-515c352801e0'
}

export const AD3_LDAP_GROUP = 'ad3-team';
export const AD3_FDA_LDAP_GROUP = 'ad3fdagrp'; // for ad3 and fda team
export const FDA_PORTAL_BUSINESS_TEAM = 'ad3fdabuss-grp'; // for all other business people

export const FILE_DOWNLOAD_OPTIONS = [
  { text: 'CSV', id: 'csv', disabled: false },
  { text: 'Excel', id: 'excel', disabled: false }
];

export const BaseBreadcrumbs = [
  {
    text: APPLICATION_TITLE,
    href: '/'
  }
];

export interface FileValidation extends Record<string, any> {
  HeadersMatching: ValidationEntity;
  RequiredFiledMissing: ValidationEntity;
}

export interface NBDValidation extends Record<string, any>, FileValidation {
  ValidDataFields: ValidationEntity;
}

export interface MecValidation extends Record<string, any>, FileValidation {
  ValidUnitFields: ValidationEntity;
}

export interface AccessoryFileValidation extends Record<string, any>, FileValidation {
  DuplicateRecords: ValidationEntity;
  NonSyncGlobalValues: ValidationEntity;
  InterdependentColumns: ValidationEntity;
  DropdownsNotMatched: ValidationEntity;
}

export interface ValidationEntity {
  colorOverride: StatusIndicatorProps.Color;
  validationStatus: StatusIndicatorProps.Type;
  validationMessage: string;
}

export const VALIDATION_NOT_INITIATED: ValidationEntity = {
  validationStatus: 'pending',
  colorOverride: 'grey',
  validationMessage: 'Not initiated'
};

export const VALIDATION_STARTED: ValidationEntity = {
  validationStatus: 'loading',
  colorOverride: 'grey',
  validationMessage: 'Validating'
};

export const SPLIT_PANEL_I18NSTRINGS = {
  preferencesTitle: 'Split panel preferences',
  preferencesPositionLabel: 'Split panel position',
  preferencesPositionDescription: 'Choose the default split panel position for the service.',
  preferencesPositionSide: 'Side',
  preferencesPositionBottom: 'Bottom',
  preferencesConfirm: 'Confirm',
  preferencesCancel: 'Cancel',
  closeButtonAriaLabel: 'Close panel',
  openButtonAriaLabel: 'Open panel',
  resizeHandleAriaLabel: 'Resize split panel'
};

export enum eErrorMessages {
  App_Initialization_Failed = "An unexpected error occurred, but don't worry, we're on it!",
  Generic_Error = 'An unexpected error occurred.',
  Api_Request_Failed = 'Unable to submit request. Please try again or reach out admin.',
  Api_Request_InProgress = 'Request in progress',
  Api_Request_Success = 'Request submitted successfully'
}
