export const getValidationErrorMessage = (touched: any, errors: any) => {
  return touched && errors ? errors : '';
};

export const getMultiSelectPlaceHolderValue = (entity: any, entityName: string): string => {
  let placeHolderValue = 'Select ' + entityName;
  if (entity?.length == 0) {
    return placeHolderValue;
  }

  if (entity?.length == 1) {
    placeHolderValue = entity[0].label;
  }

  if (entity?.length > 1) {
    placeHolderValue = entity[0].label + ' + ' + (entity?.length - 1) + (entity?.length - 1 === 1 ? ' Other' : ' Others');
  }
  return placeHolderValue;
};
