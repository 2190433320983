import React, { useState, useEffect, useContext, createContext } from 'react';
import { AppContext, LoadingStatus, MappingConfiguration, Projects } from 'src/models/AppModel';
import { configureAgGridLicense } from 'src/services/AWSServices';
import { getUserMappings } from 'src/services/FDAServices';
import { logger } from 'src/utils/FDALogger';
import { LoadingSpinner } from './GenericComponents/Spinner';
import { AccessoryASIN } from './Projects/AccessoryASIN/AccessoryASINHome';
import { CouponRedemption } from './Projects/CouponRedemption/CouponRedemption';
import { MecAdjustment } from './Projects/MecAdjustments/MecAdjustment';

const AppContextInitialData: AppContext = {
  mappings: [],
  setMappings: (mappings: MappingConfiguration[]) => {},
  projects: [],
  setProjects: (projects: Projects[]) => {},
  agGridLicenseConfigurationStatus: LoadingStatus.NotInitiated,
  contextLoadingError: null,
  contextLoadingStatus: LoadingStatus.NotInitiated
};
const AppContextDetails = createContext(AppContextInitialData);
export const useAppContext = () => {
  return useContext(AppContextDetails);
};

export const AppContextProvider = ({ children }: any) => {
  const [mappings, setMappings] = useState<MappingConfiguration[]>([]);
  const [projects, setProjects] = useState<Projects[]>([]);
  const [agGridLicenseConfigurationStatus, setAgGridLicenseConfigurationStatus] = useState<LoadingStatus>(LoadingStatus.NotInitiated);
  const [contextLoadingError, setContextLoadingError] = useState<string | null>(null);
  const [contextLoadingStatus, setContextLoadingStatus] = useState<LoadingStatus>(LoadingStatus.NotInitiated);

  useEffect(() => {
    setContextLoadingStatus(LoadingStatus.Loading);
    Promise.all([getMappings(), getProjects(), configureAgGridLicense()])
      .then(async (values) => {
        setMappings(values[0]);
        setProjects(values[1]);
        setAgGridLicenseConfigurationStatus(values[2] === 'setLicenseKey_Success' ? LoadingStatus.Completed : LoadingStatus.Failed);
        setContextLoadingStatus(LoadingStatus.Completed);
      })
      .catch((err) => {
        const errorMessage = 'Error while loading context. Some of the features in Application might not work as expected.';
        setContextLoadingStatus(LoadingStatus.Failed);
        setContextLoadingError(errorMessage);
      });
  }, []);

  return (
    <>
      {contextLoadingStatus === LoadingStatus.Completed && (
        <AppContextDetails.Provider
          value={{ mappings, setMappings, projects, setProjects, agGridLicenseConfigurationStatus, contextLoadingStatus, contextLoadingError }}
        >
          {children}
        </AppContextDetails.Provider>
      )}
      {contextLoadingStatus === LoadingStatus.Loading && <LoadingSpinner />}
    </>
  );
};

const getMappings = async () => {
  return getUserMappings()
    .then((mappingsMetadata: any) => {
      return mappingsMetadata.user_mapping;
    })
    .catch((error: any) => {
      logger.error('Error while fetching User Mappings ', error);
      return [];
    });
};

const getProjects = async () => {
  return [
    {
      Project: 'MEC Adjustments',
      ProjectRoute: '/mec_adjustments',
      ProjectComponent: <MecAdjustment />
    },
    {
      Project: 'NBD Coupon Redemption Information Intake',
      ProjectRoute: '/nbd_coupon_redemption',
      ProjectComponent: <CouponRedemption />
    },
    {
      Project: 'Accessory Asin',
      ProjectRoute: '/accessory_asin',
      ProjectComponent: <AccessoryASIN />
    }
  ] as Projects[];
};
