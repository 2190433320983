import React from 'react';
import { CouponsDataEntity } from 'src/models/CouponRedemptionModel';
import * as Yup from 'yup';
import { isEmpty } from '@aws-amplify/core';
import { logger } from 'src/utils/FDALogger';

const showErrorRowNumber = (index?: number) => {
  return index === undefined ? '' : ' At row ' + (index + 2);
};
const REQUIRED_FIELD = 'Required field';

export const validateCouponRedemptionRecord = (
  requestFrom: 'form' | 'upload',
  requestType: 'edit' | 'new',
  couponRecord: CouponsDataEntity,
  nbdCouponData: CouponsDataEntity[],
  index?: number
) => {
  let errors: any = {};

  // paws_id
  const paws_id_schema = Yup.string()
    .required(REQUIRED_FIELD)
    .min(11, 'PAWS ID should have minimum 11 digits')
    .max(13, 'PAWS ID cannot exceed 13 digits')
    .matches(/^[0-9]+$/, 'accepts only numbers');
  try {
    const validate = paws_id_schema.validateSync(couponRecord.paws_id);
  } catch (error: any) {
    errors = { ...errors, paws_id: error.errors[0] + showErrorRowNumber(index) };
  }

  // paws_id checking duplicates
  if (requestType === 'new') {
    try {
      const hasPAWS_ID = nbdCouponData.find((couponData) => couponData.paws_id === couponRecord.paws_id);
      if (hasPAWS_ID) {
        errors = { ...errors, paws_id: 'PAWS ID already exists' + showErrorRowNumber(index) };
      }
    } catch (error) {
      errors = { ...errors, paws_id: 'Issue with PAWS ID ' + showErrorRowNumber(index) };
    }
  }

  // company_code
  const company_code_schema = Yup.string().required(REQUIRED_FIELD).max(2, 'Allows max 2 characters').min(2, 'Allows min 2 characters');
  try {
    const validate = company_code_schema.validateSync(couponRecord.company_code);
  } catch (error: any) {
    errors = { ...errors, company_code: error.errors[0] + showErrorRowNumber(index) };
  }

  // coupons_issued
  const coupons_issued_schema = Yup.string()
    .required(REQUIRED_FIELD)
    .matches(/^[0-9]+$/, 'accepts only numbers');
  try {
    const validate = coupons_issued_schema.validateSync(couponRecord.coupons_issued);
  } catch (error: any) {
    errors = { ...errors, coupons_issued: error.errors[0] + showErrorRowNumber(index) };
  }

  // selling_price
  const selling_price_schema = Yup.string()
    .required(REQUIRED_FIELD)
    .matches(/^[0-9]+\.?\d*$/, 'accepts only decimals');
  try {
    const validate = selling_price_schema.validateSync(couponRecord.selling_price);
  } catch (error: any) {
    errors = { ...errors, selling_price: error.errors[0] + showErrorRowNumber(index) };
  }

  // bundle
  const bundle_schema = Yup.string()
    .required(REQUIRED_FIELD)
    .matches(/^(?:Y|N)$/, 'accepts only Y or N');
  try {
    const validate = bundle_schema.validateSync(couponRecord.bundle);
  } catch (error: any) {
    errors = { ...errors, bundle: error.errors[0] + showErrorRowNumber(index) };
  }

  // partner_company_name
  const partner_company_name_schema = Yup.string().required(REQUIRED_FIELD);
  try {
    const validate = partner_company_name_schema.validateSync(couponRecord.partner_company_name);
  } catch (error: any) {
    errors = { ...errors, partner_company_name: error.errors[0] + showErrorRowNumber(index) };
  }

  // program_description
  const program_description_schema = Yup.string().required(REQUIRED_FIELD);
  try {
    const validate = program_description_schema.validateSync(couponRecord.program_description);
  } catch (error: any) {
    errors = { ...errors, program_description: error.errors[0] + showErrorRowNumber(index) };
  }

  // poc_business
  const poc_business_schema = Yup.string().required(REQUIRED_FIELD);
  try {
    const validate = poc_business_schema.validateSync(couponRecord.poc_business);
  } catch (error: any) {
    errors = { ...errors, poc_business: error.errors[0] + showErrorRowNumber(index) };
  }

  // poc_accounting
  const poc_accounting_schema = Yup.string().required(REQUIRED_FIELD);
  try {
    const validate = poc_accounting_schema.validateSync(couponRecord.poc_accounting);
  } catch (error: any) {
    errors = { ...errors, poc_accounting: error.errors[0] + showErrorRowNumber(index) };
  }

  return errors;
};
