import { FlashbarProps } from '@amzn/awsui-components-react';
import { createContext, useContext } from 'react';
import { MappingDataEntity } from './Models/MappingModel';

export type MappingsConfigurationContextType = {
  mappingConfigurations: MappingDataEntity[];
  setMappingConfigurations: (mappingConfigurations: MappingDataEntity[]) => void;
  // selectedMappingConfigurations: MappingDataEntity;
  // seMappingConfigurations: (mappingConfiguration: MappingDataEntity) => void;
  notificationHandler: (status: boolean, message: string, flashbarType: FlashbarProps.Type) => void;
};

export const MappingsConfigurationContext = createContext<MappingsConfigurationContextType>({
  mappingConfigurations: [],
  setMappingConfigurations: (mappingConfigurations) => {},
  // selectedMappingConfigurations: {} as MappingDataEntity,
  // seMappingConfigurations: mappingConfiguration => { },
  notificationHandler: (status: boolean, notificationMessage: string, notificationType: FlashbarProps.Type) => {}
});

export const useMappingsConfigurationContext = () => useContext(MappingsConfigurationContext);
