import React from 'react';
import moment from 'moment';
import { MECAdjustments, MECAdjustmentsForm, MecMetadata } from 'src/models/MECAdjustments';
import { DropdownModel } from 'src/models/AccessoryASIN';
const showErrorRowNumber = (index?: number) => {
  return index === undefined ? '' : ' At row ' + (index + 2);
};

export const validateMECRecord = (
  requestFrom: 'form' | 'upload',
  mecRecord: MECAdjustmentsForm | MECAdjustments,
  mecMetadata: MecMetadata,
  index?: number
) => {
  let errors: any = {};

  if (moment(mecRecord.start_date).isValid() && moment(mecRecord.end_date).isValid()) {
    // start_date & end_date Validation
    const dateRangeError = moment(mecRecord.start_date).isBefore(moment(mecRecord.end_date))
      ? {}
      : { start_date: 'Start date should be earlier than End date' + showErrorRowNumber(index) };
    errors = { ...dateRangeError };

    const startDateOfPL = moment(moment(mecRecord.pl_month).startOf('month').format('YYYY-MM-DD'));
    const lastDateOfPL = moment(moment(mecRecord.pl_month).endOf('month').format('YYYY-MM-DD'));
    const isStartDateWithinPlMonth = moment(mecRecord.start_date).isBetween(startDateOfPL, lastDateOfPL, 'day', '[]');
    if (!isStartDateWithinPlMonth) {
      errors = { ...errors, start_date: 'Start date must be within PL Month' + showErrorRowNumber(index) };
    }

    const isEndDateWithinPlMonth = moment(mecRecord.end_date).isBetween(startDateOfPL, lastDateOfPL, 'day', '[]');
    if (!isEndDateWithinPlMonth) {
      errors = { ...errors, end_date: 'End date must be within PL Month' + showErrorRowNumber(index) };
    }
  } else {
    if (!moment(mecRecord.start_date).isValid()) {
      errors = { ...errors, start_date: 'Start date must be valid' + showErrorRowNumber(index) };
    }
    if (!moment(mecRecord.end_date).isValid()) {
      errors = { ...errors, end_date: 'End date must be valid' + showErrorRowNumber(index) };
    }
  }

  if (mecRecord.start_date === '') {
    errors = { ...errors, start_date: 'Required' + showErrorRowNumber(index) };
  }

  if (mecRecord.end_date === '') {
    errors = { ...errors, end_date: 'Required' + showErrorRowNumber(index) };
  }

  // Validation for these columns are interdependent
  // Values should be either
  // pos_units_adjustment && rgu_units_adjustment
  // OR
  // pos_pct_adjustment && rgu_pct_adjustment
  const pos_units_adjustment = mecRecord.pos_units_adjustment;
  const rgu_units_adjustment = mecRecord.rgu_units_adjustment;
  const pos_pct_adjustment = mecRecord.pos_pct_adjustment;
  const rgu_pct_adjustment = mecRecord.rgu_pct_adjustment;

  if (pos_units_adjustment !== null) {
    if (rgu_units_adjustment === null) {
      errors = { ...errors, rgu_units_adjustment: ' required' + showErrorRowNumber(index) };
    }

    // ASIN is required when pos_units_adjustment && rgu_units_adjustment have values
    if (mecRecord.asin === null) {
      errors = {
        ...errors,
        asin: ' required when units have values' + showErrorRowNumber(index)
      };
    }

    if (pos_pct_adjustment !== null) {
      errors = {
        ...errors,
        pos_pct_adjustment: ' should be empty when units are provided' + showErrorRowNumber(index)
      };
    }
    if (rgu_pct_adjustment !== null) {
      errors = {
        ...errors,
        rgu_pct_adjustment: ' should be empty when units are provided' + showErrorRowNumber(index)
      };
    }
  } else {
    if (pos_pct_adjustment === null) {
      errors = { ...errors, pos_pct_adjustment: ' required' + showErrorRowNumber(index) };
    } else if (pos_pct_adjustment > 100 || pos_pct_adjustment < -100) {
      errors = {
        ...errors,
        pos_pct_adjustment: ' must be in between -100 to 100 ' + showErrorRowNumber(index)
      };
    }

    if (rgu_pct_adjustment === null) {
      errors = { ...errors, rgu_pct_adjustment: ' required' + showErrorRowNumber(index) };
    } else if (rgu_pct_adjustment > 100 || rgu_pct_adjustment < -100) {
      errors = {
        ...errors,
        rgu_pct_adjustment: ' must be in between -100 to 100 ' + showErrorRowNumber(index)
      };
    }

    if (pos_units_adjustment !== null) {
      errors = {
        ...errors,
        pos_units_adjustment: " should be empty when pct's are provided" + showErrorRowNumber(index)
      };
    }
    if (rgu_units_adjustment !== null) {
      errors = {
        ...errors,
        rgu_units_adjustment: " should be empty when pct's are provided" + showErrorRowNumber(index)
      };
    }
  }

  // Validating Retailer
  const userEnteredRetailer = requestFrom === 'form' ? (mecRecord.retailer_name as DropdownModel).value : mecRecord.retailer_name;
  if (userEnteredRetailer === null) {
    errors = {
      ...errors,
      retailer_name: 'Required' + showErrorRowNumber(index)
    };
  } else {
    const isValidRetailer = mecMetadata.retailToCountry
      ?.map((data) => data.retailer)
      .find((data) => {
        if (requestFrom === 'form') {
          return data === (mecRecord.retailer_name as DropdownModel).value;
        } else {
          return data === mecRecord.retailer_name;
        }
      });
    if (!isValidRetailer) {
      errors = { ...errors, retailer_name: 'Retailer is not valid' + showErrorRowNumber(index) };
    }
  }

  // Validating Country
  const isValidCountry = mecMetadata.countryToRetail
    ?.map((data) => data.country)
    .find((data) => {
      if (requestFrom === 'form') {
        return data === (mecRecord.country as DropdownModel).value;
      } else {
        return data === mecRecord.country;
      }
    });
  if (!isValidCountry) {
    errors = { ...errors, country: 'Country is not valid' + showErrorRowNumber(index) };
  }

  // Validating Screen
  // Screen is optional
  const userScreen = requestFrom === 'form' ? (mecRecord.screen as DropdownModel).value : mecRecord.screen;
  if (userScreen != null && userScreen != '') {
    const isValidScreen = mecMetadata.screen
      ?.map((screen) => screen)
      .find((data) => {
        if (requestFrom === 'form') {
          return data === userScreen;
        } else {
          return data === userScreen;
        }
      });
    if (!isValidScreen) {
      errors = { ...errors, screen: 'Screen is not valid' + showErrorRowNumber(index) };
    }
  }

  // Validating Channel
  const isValidCannel = ['Online', 'Offline']
    ?.map((channel) => channel)
    .find((data) => {
      if (requestFrom === 'form') {
        return data === (mecRecord.channel as DropdownModel).value;
      } else {
        return data === mecRecord.channel;
      }
    });
  if (!isValidCannel) {
    errors = { ...errors, channel: 'Channel is not valid' + showErrorRowNumber(index) };
  }

  // Validating Retailer vs Country combination
  const retailerName = mecMetadata.retailToCountry.find((retailers) => {
    if (requestFrom === 'form') {
      return retailers.retailer === (mecRecord.retailer_name as DropdownModel).value;
    } else {
      return retailers.retailer === mecRecord.retailer_name;
    }
  });

  if (retailerName) {
    const isValidCountry = retailerName.country.find((country) => {
      if (requestFrom === 'form') {
        return country === (mecRecord.country as DropdownModel).value;
      } else {
        return country === mecRecord.country;
      }
    });

    if (!isValidCountry) {
      errors = { ...errors, country: 'Country & Retailer combination is not valid' + showErrorRowNumber(index) };
    }
  }

  // adjustment_type
  const adjustmentType = mecRecord.adjustment_type;
  if (!adjustmentType) {
    errors = { ...errors, adjustment_type: 'required' + showErrorRowNumber(index) };
  }

  return errors;
};
