import { Alert, Box, Button, Modal, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';

export type ConfirmActionType = 'reset' | 'delete' | 'clearPostMec' | 'mapMecToProduction';
interface MECConfirmModalProps {
  mecConfirmModal: boolean;
  onConfirmingTheCancel: () => void;
  onConfirmingTheDelete: (confirmActionType: ConfirmActionType) => void;
  confirmActionType: ConfirmActionType;
}
export const MECConfirmModal = ({ mecConfirmModal, onConfirmingTheCancel, onConfirmingTheDelete, confirmActionType }: MECConfirmModalProps) => {
  return (
    <Modal
      visible={mecConfirmModal}
      onDismiss={() => onConfirmingTheCancel()}
      header={renderSwitch(confirmActionType)?.header}
      closeAriaLabel="Close dialog"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onConfirmingTheCancel}>
              Cancel
            </Button>
            <Button variant="primary" onClick={() => onConfirmingTheDelete(confirmActionType)}>
              Confirm
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween size="m">{renderSwitch(confirmActionType)?.body}</SpaceBetween>
    </Modal>
  );
};

const renderSwitch = (param: ConfirmActionType) => {
  switch (param) {
    case 'delete':
      return {
        header: 'Delete MEC record',
        body: (
          <>
            <Box variant="span">Delete MEC record permanently? This action cannot be undone.</Box>
            <Alert>
              <Bold>Confirm</Bold> with this action will remove from the view and after <Bold>Submit</Bold>, it will permanently delete.
            </Alert>
          </>
        )
      };
    case 'mapMecToProduction':
      return {
        header: 'Map MEC to Production',
        body: (
          <>
            <Box variant="span">Will map all post data MEC Adjustments to Monthly Production</Box>
            <Alert>This action cannot be undone.</Alert>
          </>
        )
      };
    case 'clearPostMec':
      return {
        header: 'Clear POST MEC',
        body: (
          <>
            <Box variant="span">Will delete all post data MEC Adjustments</Box>
          </>
        )
      };
    case 'reset':
      return {
        header: 'Reset changes',
        body: (
          <>
            <Box variant="span">Will undo all changes made so far</Box>
          </>
        )
      };

    default:
      break;
  }
};

const Bold = ({ text }: any) => (
  <Box variant="span" fontWeight="bold">
    {text}
  </Box>
);
