import React from 'react';
import { HelpPanel } from '@amzn/awsui-components-react';

export const AccessoryASINInfo = () => {
  return (
    <HelpPanel header={<h2>More Info</h2>} footer={<></>}>
      {/* Gether info from customer  */}
      <p>
        {
          'This process to source accessory ASIN information from various data sources and to allow customers to easily update the ASIN mappings in a self-service manner.'
        }
      </p>
    </HelpPanel>
  );
};
