import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Button,
  ButtonDropdown,
  CollectionPreferences,
  FormField,
  Header,
  Pagination,
  RadioGroup,
  SpaceBetween,
  Table,
  TextFilter,
  TextFilterProps
} from '@amzn/awsui-components-react';
import React, { useEffect, useRef, useState } from 'react';
import { EmptyStateMessage } from 'src/components/GenericComponents/EmptyState';
import { FILE_DOWNLOAD_OPTIONS } from 'src/constants/FDAConstants';
import { ASINDataEntity } from 'src/models/AccessoryASIN';
import { logger } from 'src/utils/FDALogger';
import { exportAsCSVFile, exportAsExcelFile } from 'src/utils/FileServices';
import { AccessoryASINModal } from './AccessoryASINConfirmationModal';
import { AccessoryASINState } from './AccessoryASINHome';
import {
  ACCESSORY_ASIN_COLUMN_DEFINITIONS,
  ACCESSORY_ASIN_CONTENT_SELECTOR_OPTIONS,
  ACCESSORY_ASIN_DEFAULT_PREFERENCES,
  ACCESSORY_ASIN_PAGE_SELECTOR_OPTIONS,
  CUSTOM_PREFERENCE_OPTIONS,
  getMatchesCountText,
  paginationLabels
} from './AccessoryASINTableDefinition';

export interface AccessoryASINTableProps {
  asinState: AccessoryASINState;
  asinData: ASINDataEntity[];
  loadingASINs: boolean;
  refreshData: (eventType: string) => void;
  editData: (row: ASINDataEntity[]) => void;
  newData: () => void;
  deleteRow: (row: ASINDataEntity) => void;
  submitASINData: () => void;
}

export const AccessoryASINTable = (props: AccessoryASINTableProps) => {
  const [asinData, setASINData] = React.useState<ASINDataEntity[]>([]);
  const [loadingASINs, setLoadingASINs] = React.useState<boolean>(false);

  const [tableSelectedItem, setTableSelectedItem] = useState<ASINDataEntity[]>([]);
  const [preferences, setPreferences] = useState(ACCESSORY_ASIN_DEFAULT_PREFERENCES);
  const textFilterRef = useRef<TextFilterProps.Ref>(null);
  const isOnlyOneSelected = tableSelectedItem.length === 1;

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    textFilterRef.current?.focus();
  }, []);

  useEffect(() => {
    setASINData(props.asinData);
    setLoadingASINs(props.loadingASINs);
  }, [props]);

  const tableSelectionChanged = (detail: any) => {
    setTableSelectedItem(detail.selectedItems);
  };

  const onRowClickChanged = (detail: any) => {
    setTableSelectedItem([detail.item]);
  };

  const handleEdit = () => {
    props.editData(tableSelectedItem);
  };

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(asinData || [], {
    filtering: {
      empty: <EmptyStateMessage title={"Couldn't find ASIN data"} subtitle="" />,
      noMatch: (
        <EmptyStateMessage
          title="No matches"
          subtitle="We can’t find a match."
          action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
        />
      )
    },
    pagination: {
      pageSize: preferences.pageSize
    },
    sorting: {},
    selection: {}
  });

  const updatePageSize = (pageSize: string) => {
    logger.info('Accessory Asin - Table Size Updated' + pageSize);
    setPreferences({ ...preferences, pageSize: +pageSize });
  };

  const deleteSelectedRow = () => {
    setShowModal(true);
  };

  const onCancel = () => {
    setShowModal(false);
  };

  const onConfirm = () => {
    setShowModal(false);
    logger.info('Accessory Asin - Row Deleted' + { DeletedRow: tableSelectedItem[0] });
    props.deleteRow(tableSelectedItem[0]);
  };

  const submitDisabled = (): boolean => {
    return !props.asinState.asinDataFetched || !props.asinState.asinDataChanged || props.asinState.asinDataSubmitInProgress;
  };

  const downloadAsFile = (fileType: any) => {
    logger.info('Accessory Asin - File Downloaded as ' + fileType);
    let downloadData = asinData.map((asinRow: ASINDataEntity) => {
      return {
        asin: asinRow.asin,
        marketplace_id: asinRow.marketplace_id,
        compatible_screen: asinRow.compatible_screen,
        compatible_device: asinRow.compatible_device,
        item_name: asinRow.item_name,
        parent_asin: asinRow.parent_asin,
        brand: asinRow.brand,
        color: asinRow.color,
        gl: asinRow.gl,
        product_line: asinRow.product_line,
        product_desc: asinRow.product_desc,
        accessory_program: asinRow.accessory_program,
        merchant_type: asinRow.merchant_type,
        product_category: asinRow.product_category,
        is_generic: asinRow.is_generic,
        is_refurb: asinRow.is_refurb
      };
    });
    if (fileType === 'excel') exportAsExcelFile(downloadData, props.asinState.asinValue, 'Accessory ASIN Mappings');
    else exportAsCSVFile(downloadData, props.asinState.asinValue, 'Accessory ASIN Mappings');
  };

  const accessoryTableActions = () => {
    return (
      <SpaceBetween size="xs" direction="horizontal">
        <ButtonDropdown
          disabled={!props.asinState.asinDataFetched}
          onItemClick={({ detail }) => {
            downloadAsFile(detail.id);
          }}
          items={FILE_DOWNLOAD_OPTIONS}
        >
          Download As
        </ButtonDropdown>
        <Button onClick={() => props.newData()} disabled={!props.asinState.asinDataFetched}>
          Add
        </Button>
        <Button disabled={!isOnlyOneSelected || !props.asinState.asinDataFetched} onClick={() => handleEdit()}>
          Edit
        </Button>
        <Button disabled={tableSelectedItem.length === 0 || !props.asinState.asinDataFetched} onClick={() => deleteSelectedRow()}>
          Remove
        </Button>
        <Button disabled={loadingASINs} onClick={() => props.refreshData('RefreshASIN')}>
          Reset Changes
        </Button>
        <Button variant="primary" disabled={submitDisabled()} onClick={props.submitASINData}>
          Submit
        </Button>
      </SpaceBetween>
    );
  };

  return (
    <>
      <AccessoryASINModal showModal={showModal} onCancel={() => onCancel()} onConfirm={() => onConfirm()}></AccessoryASINModal>
      <Table
        className="fda-table"
        contentDensity="compact"
        variant="container"
        {...collectionProps}
        header={
          <Header
            counter={asinData && (tableSelectedItem!.length ? `(${tableSelectedItem!.length}/${asinData.length})` : `(${asinData.length})`)}
            actions={accessoryTableActions()}
          >
            {props.asinState.asinValue}
          </Header>
        }
        visibleColumns={preferences.visibleContent}
        trackBy={'unique_id'}
        resizableColumns
        wrapLines={preferences.wrapLines}
        loading={loadingASINs}
        loadingText="Loading ASIN's"
        columnDefinitions={ACCESSORY_ASIN_COLUMN_DEFINITIONS}
        items={items}
        selectionType="single"
        onSelectionChange={({ detail }) => tableSelectionChanged(detail)}
        onRowClick={({ detail }) => onRowClickChanged(detail)}
        selectedItems={tableSelectedItem}
        filter={
          <TextFilter
            {...filterProps}
            ref={textFilterRef}
            countText={getMatchesCountText(filteredItemsCount!)}
            filteringPlaceholder="Search"
            filteringAriaLabel="Search"
          />
        }
        pagination={
          <>
            <SpaceBetween size="m" direction="horizontal">
              <Pagination {...paginationProps} ariaLabels={paginationLabels} />
              <ButtonDropdown
                expandToViewport
                onItemClick={(item) => {
                  updatePageSize(item.detail.id);
                }}
                items={[
                  { text: '50', id: '50' },
                  { text: '100', id: '100' },
                  { text: '150', id: '150' },
                  { text: '200', id: '200' }
                ]}
              >
                {preferences.pageSize}
              </ButtonDropdown>
            </SpaceBetween>
          </>
        }
        preferences={
          <CollectionPreferences
            preferences={preferences}
            onConfirm={(event) => setPreferences(event.detail)}
            title="Preferences"
            confirmLabel="Confirm"
            cancelLabel="Cancel"
            visibleContentPreference={{
              title: 'Select visible columns',
              options: ACCESSORY_ASIN_CONTENT_SELECTOR_OPTIONS
            }}
            pageSizePreference={{
              title: 'Page size',
              options: ACCESSORY_ASIN_PAGE_SELECTOR_OPTIONS
            }}
            wrapLinesPreference={{
              label: 'Wrap lines',
              description: 'Check to see all the text and wrap the lines'
            }}
            customPreference={(state, setState) => (
              <FormField stretch={true} label="View as">
                <RadioGroup value={state} onChange={(event) => setState(event.detail.value)} items={CUSTOM_PREFERENCE_OPTIONS} />
              </FormField>
            )}
          />
        }
      />
    </>
  );
};
