import {
  AppLayout,
  AttributeEditor,
  Box,
  Button,
  ButtonDropdown,
  Checkbox,
  Container,
  Flashbar,
  FlashbarProps,
  Form,
  FormField,
  Header,
  Input,
  Multiselect,
  Select,
  SpaceBetween,
  StatusIndicator,
  TagEditor
} from '@amzn/awsui-components-react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ENV_CONSTANTS, useAuth } from 'src/utils/AuthProvider';
import { useAppContext } from '../AppContextProvider';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from '../GenericComponents/ErrorFallback';
import { useNavigate, useParams } from 'react-router-dom';
import { useMappingsConfigurationContext } from './MappingsConfigContext';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import {
  FormInitialValues,
  MappingConfigurationForm,
  FDA_TABLE_SOURCES,
  COLUMN_DATA_TYPE_DROPDOWNS,
  validationSchema,
  parseMappingConfigToFormValues,
  parseFormValuesToMappingConfig,
  MAPPING_CONFIG_MASTER_ALIAS,
  MAPPING_CONFIGURATIONS_BETA,
  MAPPING_CONFIGURATIONS_PROD
} from './MappingsConfigValidations';
import { MappingDataEntity } from './Models/MappingModel';
import { postMapData } from 'src/services/FDAServices';
import { logger } from 'src/utils/FDALogger';
import { getMultiSelectPlaceHolderValue } from 'src/utils/GenericUtils';

export const MappingsConfigurationDetails = () => {
  const [toolsOpen, setToolsOpen] = useState(false);
  const userDetails = useAuth();
  const appContext = useAppContext();
  const navigate = useNavigate();
  const stage = ENV_CONSTANTS.ENVIRONMENT_VARIABLES.Stage;

  const { mappingConfigTableAlias } = useParams();
  const { mappingConfigurations, setMappingConfigurations, notificationHandler } = useMappingsConfigurationContext();

  const formRef = useRef<FormikProps<MappingConfigurationForm>>(null);
  const [formValues, setFormValues] = useState(FormInitialValues);
  const [isExistingMapping, setIsExistingMapping] = useState<boolean>(false);

  useEffect(() => {
    const mappingConfigFound = mappingConfigurations?.find((mappingConfiguration) => mappingConfiguration.table_nm === mappingConfigTableAlias);
    if (mappingConfigFound) {
      const parsedFormValues = parseMappingConfigToFormValues(mappingConfigFound);
      setFormValues(parsedFormValues);
      setIsExistingMapping(true);
    } else {
      setIsExistingMapping(false);
      setFormValues(FormInitialValues);
    }
  }, [mappingConfigTableAlias]);

  const handleSubmit = async (formValues: MappingConfigurationForm, formikHelpers: FormikHelpers<any>) => {
    const parsedIntoMappingConfigObject = parseFormValuesToMappingConfig(formValues);
    let updatedMappingsEntity: MappingDataEntity[] = [];
    const mappingDataEntityFound = mappingConfigurations?.find(
      (mappingConfiguration) => mappingConfiguration.table_nm === parsedIntoMappingConfigObject.table_nm
    );
    if (mappingDataEntityFound) {
      updatedMappingsEntity = mappingConfigurations?.map((mappingConfiguration) => {
        if (mappingConfiguration.table_nm === parsedIntoMappingConfigObject.table_nm) {
          return parsedIntoMappingConfigObject;
        } else {
          return mappingConfiguration;
        }
      });
    } else {
      updatedMappingsEntity = mappingConfigurations.concat(parsedIntoMappingConfigObject);
    }
    formikHelpers.setSubmitting(true);
    notificationHandler(true, 'Submitting the request', 'info');
    updatedMappingsEntity =
      stage !== 'prod' ? MAPPING_CONFIGURATIONS_BETA.concat(updatedMappingsEntity) : MAPPING_CONFIGURATIONS_PROD.concat(updatedMappingsEntity);
    logger.info(`Submitting the request for mapping configuration - ${formValues.table_nm} `, { info: updatedMappingsEntity });
    postMapData(updatedMappingsEntity, MAPPING_CONFIG_MASTER_ALIAS)
      .then((response) => {
        formikHelpers.setSubmitting(false);
        notificationHandler(
          false,
          "Request added to the job queue. You will receive email once it is completed. You can also track status in 'FDA Mapper Status' Mapping.",
          'success'
        );
        logger.info(`Submitting the request for mapping configuration - Response - ${formValues.table_nm} `, { info: response });
      })
      .catch((error: any) => {
        formikHelpers.setSubmitting(false);
        logger.error(`Submitting the request for mapping configuration - Response - ${formValues.table_nm} `, error);
        notificationHandler(false, 'Request failed. Please try again or reach out to admin.', 'error');
      });
  };

  const resetPrimaryColumn = () => {
    formRef?.current?.setFieldValue('unique_key', []);
  };

  return (
    <>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => {
          window.location.reload();
        }}
      >
        <Formik<MappingConfigurationForm>
          innerRef={formRef}
          enableReinitialize={true}
          initialValues={formValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, touched, errors, setFieldValue, handleSubmit, isSubmitting, isValid }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Container
                  header={
                    <Header
                      variant="h3"
                      actions={
                        <>
                          <SpaceBetween size="s" direction="horizontal">
                            <Button variant="primary" disabled={isSubmitting} formAction={'submit'}>
                              Submit
                            </Button>
                          </SpaceBetween>
                        </>
                      }
                    >
                      {'Mappings Configuration'}
                    </Header>
                  }
                >
                  <Form>
                    <SpaceBetween size="l" direction="vertical">
                      <FormField
                        label="Table Source"
                        description="select the table source"
                        errorText={touched?.table_source?.label && errors?.table_source?.label}
                      >
                        <Select
                          selectedAriaLabel="Selected"
                          options={FDA_TABLE_SOURCES}
                          selectedOption={values.table_source}
                          onChange={({ detail }) => setFieldValue('table_source', detail.selectedOption)}
                        />
                      </FormField>

                      <FormField
                        label="Table Name"
                        description="Enter the Table Name. This table should be granted access to the user ‘ad3_adhoc’"
                        constraintText="Example: 'schema.table_name'"
                        errorText={touched.table_nm && errors.table_nm}
                      >
                        <Input onChange={({ detail }) => setFieldValue('table_nm', detail.value)} value={values.table_nm} />
                      </FormField>

                      <FormField label="Table Alias" description="Alias name for the mapping" errorText={touched.table_alias && errors.table_alias}>
                        <Input onChange={({ detail }) => setFieldValue('table_alias', detail.value)} value={values.table_alias} />
                      </FormField>

                      {/* <FormField> */}
                      <AttributeEditor
                        onAddButtonClick={() =>
                          setFieldValue('columnDefinitions', [
                            ...values.columnDefinitions,
                            { columnName: '', columnDataType: '', ColumnDropdownDetails: '' }
                          ])
                        }
                        onRemoveButtonClick={({ detail: { itemIndex } }) => {
                          const tmpItems = [...values.columnDefinitions];
                          tmpItems.splice(itemIndex, 1);
                          setFieldValue('columnDefinitions', tmpItems);
                        }}
                        removeButtonText="Remove"
                        empty={<StatusIndicator type="error">At least 1 column is required</StatusIndicator>}
                        items={values.columnDefinitions}
                        addButtonText="Add new column"
                        definition={[
                          {
                            label: 'Column Name',
                            errorText: (item, itemIndex) =>
                              errors?.columnDefinitions && errors?.columnDefinitions[itemIndex]
                                ? (errors?.columnDefinitions[itemIndex] as any)?.columnName
                                : null,
                            control: (item, itemIndex) => (
                              <FormField>
                                <Input
                                  placeholder="Enter column name"
                                  key={itemIndex}
                                  value={item.columnName}
                                  onChange={({ detail }) => {
                                    resetPrimaryColumn();
                                    setFieldValue(
                                      'columnDefinitions',
                                      values.columnDefinitions.map((item, i) =>
                                        i === itemIndex
                                          ? {
                                              ...item,
                                              columnName: detail.value
                                            }
                                          : item
                                      )
                                    );
                                  }}
                                />
                              </FormField>
                            )
                          },
                          {
                            label: 'Type',
                            errorText: (item, itemIndex) =>
                              errors?.columnDefinitions && errors?.columnDefinitions[itemIndex]
                                ? (errors?.columnDefinitions[itemIndex] as any)?.columnDataType?.label
                                : null,
                            control: (item, itemIndex) => (
                              <FormField>
                                <Select
                                  key={itemIndex}
                                  selectedAriaLabel="Selected"
                                  expandToViewport={true}
                                  options={COLUMN_DATA_TYPE_DROPDOWNS}
                                  selectedOption={item.columnDataType}
                                  onChange={({ detail }) =>
                                    setFieldValue(
                                      'columnDefinitions',
                                      values.columnDefinitions.map((item, i) =>
                                        i === itemIndex
                                          ? {
                                              ...item,
                                              columnDataType: detail.selectedOption
                                            }
                                          : item
                                      )
                                    )
                                  }
                                />
                              </FormField>
                            )
                          },
                          {
                            label: 'Custom Values',
                            errorText: (item, itemIndex) =>
                              errors?.columnDefinitions && errors?.columnDefinitions[itemIndex]
                                ? (errors?.columnDefinitions[itemIndex] as any).ColumnDropdownDetails
                                : null,
                            control: (item, itemIndex) => (
                              <>
                                {(item.columnDataType.value === 'text' ||
                                  item.columnDataType.value === 'numeric' ||
                                  item.columnDataType.value === 'boolean' ||
                                  item.columnDataType.value === 'datetime' ||
                                  item.columnDataType.value === 'date') && (
                                  <FormField constraintText="Not Applicable">
                                    <Input key={itemIndex} value={item.ColumnDropdownDetails} disabled={true} />
                                  </FormField>
                                )}

                                {(item.columnDataType.value === 'CURRENT_USER' ||
                                  item.columnDataType.value === 'CURRENT_DATE' ||
                                  item.columnDataType.value === 'CURRENT_DATE_TIME') && (
                                  <FormField constraintText="System will provide the values">
                                    <Input key={itemIndex} value={`{{${item.columnDataType.value}}}`} disabled={true} />
                                  </FormField>
                                )}

                                {item.columnDataType.label === 'DROPDOWN_FROM_CUSTOM_VALUES' && (
                                  <FormField constraintText={'Enter values seperated by ;'}>
                                    <Input
                                      key={itemIndex}
                                      placeholder="Enter values seperated by ;"
                                      value={item.ColumnDropdownDetails}
                                      onChange={({ detail }) =>
                                        setFieldValue(
                                          'columnDefinitions',
                                          values.columnDefinitions.map((i, idx) =>
                                            idx === itemIndex
                                              ? {
                                                  ...i,
                                                  ColumnDropdownDetails: detail.value
                                                }
                                              : i
                                          )
                                        )
                                      }
                                    />
                                  </FormField>
                                )}

                                {item.columnDataType.label === 'DROPDOWN_FROM_QUERY' && (
                                  <FormField
                                    constraintText={
                                      'Enter a select query with a single field. Example: source.select column_name from schema.table_name'
                                    }
                                  >
                                    <Input
                                      key={itemIndex}
                                      placeholder="Enter values"
                                      value={item.ColumnDropdownDetails}
                                      onChange={({ detail }) =>
                                        setFieldValue(
                                          'columnDefinitions',
                                          values.columnDefinitions.map((i, idx) =>
                                            idx === itemIndex
                                              ? {
                                                  ...i,
                                                  ColumnDropdownDetails: detail.value
                                                }
                                              : i
                                          )
                                        )
                                      }
                                    />
                                  </FormField>
                                )}

                                {item.columnDataType.label === 'DROPDOWN_FROM_SOURCE' && (
                                  <FormField
                                    constraintText={"Enter source in format 'schema.table.columnName' Example : source.schema.table_name-column_name"}
                                  >
                                    <Input
                                      key={itemIndex}
                                      placeholder="Enter values"
                                      value={item.ColumnDropdownDetails}
                                      onChange={({ detail }) =>
                                        setFieldValue(
                                          'columnDefinitions',
                                          values.columnDefinitions.map((i, idx) =>
                                            idx === itemIndex
                                              ? {
                                                  ...i,
                                                  ColumnDropdownDetails: detail.value
                                                }
                                              : i
                                          )
                                        )
                                      }
                                    />
                                  </FormField>
                                )}
                              </>
                            )
                          }
                        ]}
                      />

                      <FormField
                        label={
                          <>
                            Unique Keys <i> - optional</i>
                          </>
                        }
                        description="Dropdown updates when Column Names are updated"
                      >
                        <Multiselect
                          placeholder={getMultiSelectPlaceHolderValue(values.unique_key, 'Unique Keys')}
                          selectedAriaLabel="Selected"
                          selectedOptions={values.unique_key}
                          onChange={({ detail }) => setFieldValue('unique_key', detail.selectedOptions)}
                          deselectAriaLabel={(e) => `Remove ${e.label}`}
                          options={values.columnDefinitions.map((item) => {
                            return {
                              label: item.columnName,
                              value: item.columnName
                            };
                          })}
                        />
                      </FormField>
                    </SpaceBetween>
                  </Form>
                </Container>
              </form>
            );
          }}
        </Formik>
      </ErrorBoundary>
    </>
  );
};
