import {
  AppLayout,
  Box,
  Button,
  Container,
  Flashbar,
  FlashbarProps,
  Grid,
  Input,
  SpaceBetween,
  SplitPanel,
  StatusIndicator,
  Tabs,
  Toggle
} from '@amzn/awsui-components-react';
import { RestAPI } from '@aws-amplify/api-rest';
import { isEmpty } from '@aws-amplify/core';
import React, { useContext, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useAppContext } from 'src/components/AppContextProvider';
import { ErrorFallback } from 'src/components/GenericComponents/ErrorFallback';
import { FDA_BACKEND_SERVICE, PROJECT_DATA } from 'src/constants/APIConstants';
import { ACCESSORY_ASIN_TITLE, SPLIT_PANEL_I18NSTRINGS } from 'src/constants/FDAConstants';
import { ASINData, ASINDataEntity, ASINGlobalValues, ProjectMetadata, ProjectsEntity, SubmitRequestForASIN } from 'src/models/AccessoryASIN';
import { LoadingStatus } from 'src/models/AppModel';
import { fetchProjectMetadata, postProjectData, toggleRedshiftSync } from 'src/services/FDAServices';
import updateAppTitle from 'src/services/PortalTitleService';
import { AuthContextDetails } from 'src/utils/AuthProvider';
import { getCurrentUserLocalTimeReadableFormat } from 'src/utils/DateTimeUtils';
import { logger } from 'src/utils/FDALogger';
import { MultiASINUpload } from './AccessoryASINMultiUpload';
import { AccessoryASINTable } from './AccessoryASINTable';
import { getPanelContent, useSplitPanelProps } from './AccessoryASINTableSplitPanel';
import { AccessoryASINInfo } from './AccessoryASINToolContent';

export interface AccessoryASINState {
  asinValue: string;
  asinDataFetched: boolean;
  asinDataChanged: boolean;
  asinDataSubmitInProgress: boolean;
}

export const AccessoryASIN = () => {
  // Updates browser tab title
  updateAppTitle(ACCESSORY_ASIN_TITLE);
  const userDetails = useContext(AuthContextDetails);
  const appContext = useAppContext();
  const [projectMetadata, setProjectMetadata] = React.useState<ProjectsEntity>({} as ProjectsEntity);
  const [redshiftSync, setRedshiftSync] = React.useState<boolean>(false);
  const [redshiftSyncInProgress, setRedshiftSyncInProgress] = React.useState<boolean>(false);

  const [asinInputValue, setUserEnteredASIN] = React.useState('');
  const [globalValues, setGlobalValues] = useState<ASINGlobalValues>({} as ASINGlobalValues);
  const [selectedItems, setSelectedItems] = useState<ASINDataEntity[]>([]);
  const [asinData, setASINData] = React.useState<ASINDataEntity[]>([]);

  const [loadingASINs, setLoadingASINs] = React.useState<boolean>(false);
  const [deleteASINIds, setDeleteASINIds] = React.useState<string[]>([]);

  const [toolsOpen, setToolsOpen] = useState(false);
  const [eventType, setEventType] = useState<'edit' | 'new'>('new');
  const [flashbarItems, setFlashbarItems] = useState<FlashbarProps.MessageDefinition[]>([]);

  const [accessoryASINState, setAccessoryASINState] = useState<AccessoryASINState>({
    asinValue: '',
    asinDataFetched: false,
    asinDataChanged: false,
    asinDataSubmitInProgress: false
  });

  const {
    splitPanelPrefs,
    splitPanelSize,
    splitPanelOpen,
    onSplitPanelPreferencesChange,
    onSplitPanelResize,
    onSplitPanelToggle,
    splitPanelOpenByEdit
  } = useSplitPanelProps();

  const updateTheTable = (updatedASINRow: ASINDataEntity, globalValues: ASINGlobalValues) => {
    if (eventType === 'edit') {
      const updatedASINData = asinData.map((asinRow) => {
        if (asinRow.unique_id === updatedASINRow.unique_id) {
          return { ...updatedASINRow };
        } else {
          asinRow.accessory_program = globalValues.accessory_program;
          asinRow.is_generic = globalValues.is_generic;
          asinRow.is_refurb = globalValues.is_refurb;
          asinRow.merchant_type = globalValues.merchant_type;
          asinRow.product_category = globalValues.product_category;
          asinRow.is_locked = 'Y';
          return asinRow;
        }
      });
      logger.info('Accessory Asin - Row Edited ', { EditedRow: updatedASINRow });
      setAccessoryASINState({ ...accessoryASINState, asinDataChanged: true });
      setASINData(updatedASINData);
      setGlobalValues(globalValues);
      splitPanelOpenByEdit(false);
    } else {
      asinData.splice(0, 0, updatedASINRow);
      const updatedASINData = asinData.map((asinRow) => {
        asinRow.accessory_program = globalValues.accessory_program;
        asinRow.is_generic = globalValues.is_generic;
        asinRow.is_refurb = globalValues.is_refurb;
        asinRow.merchant_type = globalValues.merchant_type;
        asinRow.product_category = globalValues.product_category;
        asinRow.is_locked = 'Y';
        return asinRow;
      });
      logger.info('Accessory Asin - Row Added ', { AddedRow: updatedASINRow });
      setAccessoryASINState({ ...accessoryASINState, asinDataChanged: true });
      setASINData(updatedASINData);
      setGlobalValues(globalValues);
      splitPanelOpenByEdit(false);
    }
  };

  const { header: panelHeader, body: panelBody } = getPanelContent(
    asinInputValue,
    selectedItems,
    selectedItems.length > 1 ? 'multiple' : 'single',
    projectMetadata,
    eventType,
    globalValues,
    updateTheTable
  );

  useEffect(() => {
    if (appContext.contextLoadingStatus === LoadingStatus.Completed) {
      fetchProjectMetadataInformation();
    }
  }, [appContext.contextLoadingStatus]);

  const updateProjectMetadata = (projectsEntity: ProjectsEntity[] | []) => {
    const accessoryProjectMetadata = projectsEntity.find((data) => data.table_alias === 'Accessory Asin');
    if (accessoryProjectMetadata) {
      setProjectMetadata(accessoryProjectMetadata);
      setRedshiftSync(accessoryProjectMetadata.redshift_sync_status);
    } else {
      displayFlashMessage('Accessory ASIN metadata not found', 'error');
    }
  };

  const fetchProjectMetadataInformation = () => {
    fetchProjectMetadata().then(({ projects: ProjectsEntity }: ProjectMetadata) => {
      updateProjectMetadata(ProjectsEntity);
    });
  };

  const displayFlashMessage = (content: string, flashBarType: FlashbarProps.Type) => {
    setFlashbarItems([
      {
        type: flashBarType,
        content: content,
        dismissible: true,
        dismissLabel: 'Dismiss message',
        onDismiss: () => setFlashbarItems([])
      }
    ]);
  };

  const deleteRowEvent = (row: ASINDataEntity) => {
    let deleteASINS = deleteASINIds;
    deleteASINS.push(row.unique_id);
    const uniqueDeleteASINS = deleteASINS.filter((x, i, a) => {
      return a.indexOf(x) === i;
    });
    setDeleteASINIds(uniqueDeleteASINS);
    const updatedAsinData = asinData.filter((asinRow) => asinRow.unique_id !== row.unique_id);
    setAccessoryASINState({ ...accessoryASINState, asinDataChanged: true });
    setASINData(updatedAsinData);
  };

  // After editing the row, updating the global field values.
  const editDataEvent = (row: ASINDataEntity[]) => {
    setSelectedItems(row);
    setEventType('edit');
    setGlobalValues({
      accessory_program: row[0]?.accessory_program,
      asin: row[0]?.asin,
      is_generic: row[0]?.is_generic,
      is_refurb: row[0]?.is_refurb,
      merchant_type: row[0]?.merchant_type,
      product_category: row[0]?.product_category,
      is_locked: 'Y'
    });
    splitPanelOpenByEdit(true);
  };

  // After creating the row, updating the global field values.
  const newDataEvent = () => {
    setEventType('new');
    setGlobalValues({
      accessory_program: asinData[0]?.accessory_program,
      asin: asinData[0]?.asin,
      is_generic: asinData[0]?.is_generic,
      is_refurb: asinData[0]?.is_refurb,
      merchant_type: asinData[0]?.merchant_type,
      product_category: asinData[0]?.product_category,
      is_locked: 'Y'
    });
    splitPanelOpenByEdit(true);
  };

  const getASINData = async (userEnteredASIN: string) => {
    setLoadingASINs(true);
    logger.info('Accessory Asin - Data Fetching for ' + userEnteredASIN);
    // Naming convention of request body fields as per backend API
    const requestBody = {
      table_alias: projectMetadata?.table_alias,
      search_key_field: {
        asin: userEnteredASIN
      },
      search_fields: {}
    };
    await RestAPI.post(FDA_BACKEND_SERVICE, PROJECT_DATA, {
      body: requestBody
    })
      .then((response: ASINData) => {
        setAccessoryASINState({
          ...accessoryASINState,
          asinValue: userEnteredASIN,
          asinDataChanged: false,
          asinDataFetched: true
        });
        setASINData(response.data);
        setLoadingASINs(false);
      })
      .catch((err) => {
        setLoadingASINs(false);
        displayFlashMessage('Unable to fetch ASIN data.', 'error');
      });
  };

  const asinSearchChanged = async () => {
    !isEmpty(asinInputValue) ? await getASINData(asinInputValue) : displayFlashMessage('Please enter ASIN', 'info');
  };

  const syncChanged = (redshiftSyncStatus: boolean) => {
    setRedshiftSync(redshiftSyncStatus);
    setRedshiftSyncInProgress(true);
    toggleRedshiftSync(redshiftSyncStatus, 'Accessory Asin')
      .then((response) => {
        setRedshiftSyncInProgress(false);
        const successMessage = String('Request to turn ')
          .concat(redshiftSyncStatus ? 'On' : 'Off ')
          .concat(' Redshift Sync is success');
        displayFlashMessage(successMessage, 'success');
        logger.info('Accessory Asin - Redshift Sync turned ' + redshiftSyncStatus ? 'On' : 'Off ');
      })
      .catch((err) => {
        setRedshiftSyncInProgress(false);
        setRedshiftSync(!redshiftSyncStatus);
        displayFlashMessage('Unable to update Redshift Sync On/Off.', 'error');
      });
  };

  const submitASINData = () => {
    setAccessoryASINState({ ...accessoryASINState, asinDataSubmitInProgress: true });
    displayFlashMessage('Submitting the request.', 'info');
    let postBody: SubmitRequestForASIN = {
      asin_globals: [globalValues],
      delete_data: deleteASINIds,
      table_alias: projectMetadata.table_alias,
      post_data: asinData
    };
    logger.info('Accessory Asin - Submit changes ', { PostRequest: postBody });
    postProjectData(postBody)
      .then(() => {
        displayFlashMessage('Request submitted successfully.', 'success');
        getASINData(accessoryASINState.asinValue);
        fetchProjectMetadataInformation();
      })
      .catch(() => {
        displayFlashMessage('Unable to submit request.', 'error');
      });
  };

  return (
    <>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => {
          window.location.reload();
        }}
      >
        <AppLayout
          notifications={<Flashbar items={flashbarItems} />}
          navigationHide={true}
          tools={<AccessoryASINInfo />}
          toolsOpen={toolsOpen}
          onToolsChange={({ detail }) => setToolsOpen(detail.open)}
          splitPanelOpen={splitPanelOpen}
          splitPanelPreferences={splitPanelPrefs}
          splitPanelSize={300}
          onSplitPanelPreferencesChange={onSplitPanelPreferencesChange}
          onSplitPanelResize={onSplitPanelResize}
          onSplitPanelToggle={onSplitPanelToggle}
          splitPanel={
            <SplitPanel className="fda-split-panel" hidePreferencesButton header={panelHeader} i18nStrings={SPLIT_PANEL_I18NSTRINGS}>
              {panelBody}
            </SplitPanel>
          }
          maxContentWidth={Number.MAX_VALUE}
          contentType="default"
          content={
            <>
              <Tabs
                tabs={[
                  {
                    label: (
                      <Box fontWeight="normal" variant="h3">
                        {'Accessory ASIN'}
                      </Box>
                    ),
                    id: 'accessory_asin',
                    content: (
                      <>
                        <SpaceBetween size="m" direction="vertical">
                          <Container>
                            <Grid gridDefinition={[{ colspan: 2 }, { colspan: 2 }, { colspan: 2 }, { colspan: 3 }, { colspan: 3 }]}>
                              <SpaceBetween size="m" direction="horizontal">
                                <Input
                                  onChange={({ detail }) => {
                                    setUserEnteredASIN(detail.value);
                                  }}
                                  onKeyUp={(e) => {
                                    e.detail.key === 'Enter' ? asinSearchChanged() : '';
                                  }}
                                  value={asinInputValue}
                                  inputMode="text"
                                  placeholder="Enter ASIN Value"
                                  type="text"
                                />
                                <Button iconName="search" onClick={() => asinSearchChanged()}></Button>
                              </SpaceBetween>

                              <Toggle
                                onChange={({ detail }) => syncChanged(detail.checked)}
                                checked={redshiftSync}
                                disabled={redshiftSyncInProgress || !userDetails.isAdmin}
                              >
                                Redshift Sync
                              </Toggle>

                              <div>
                                <Box variant="awsui-key-label">Redshift Sync Status</Box>
                                <StatusIndicator
                                  type={projectMetadata?.redshift_sync === 'Y' ? 'success' : 'pending'}
                                  colorOverride={projectMetadata?.redshift_sync === 'Y' ? 'green' : 'blue'}
                                >
                                  {projectMetadata?.redshift_sync === 'Y' ? 'In sync' : 'Out of sync' + ' with Redshift'}
                                </StatusIndicator>
                              </div>
                              <div>
                                <Box variant="awsui-key-label">Last Updated</Box>
                                <div>{getCurrentUserLocalTimeReadableFormat(projectMetadata?.updated_time)}</div>
                              </div>
                              <div>
                                <Box variant="awsui-key-label">Updated by</Box>
                                <div>{projectMetadata?.updated_user}</div>
                              </div>
                            </Grid>
                          </Container>
                          <AccessoryASINTable
                            asinState={accessoryASINState}
                            asinData={asinData}
                            loadingASINs={loadingASINs}
                            refreshData={() => asinSearchChanged()}
                            editData={(row) => editDataEvent(row)}
                            newData={() => newDataEvent()}
                            deleteRow={(row) => deleteRowEvent(row)}
                            submitASINData={() => submitASINData()}
                          />
                        </SpaceBetween>
                      </>
                    )
                  },
                  {
                    label: (
                      <Box fontWeight="normal" variant="h3">
                        {'Multi ASIN Upload'}
                      </Box>
                    ),
                    id: 'multi_asin_upload',
                    content: <MultiASINUpload projectMetadata={projectMetadata} />
                  }
                ]}
              />
            </>
          }
        />
      </ErrorBoundary>
    </>
  );
};
