import React, { ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { LocalizationContextBuilder } from '@amzn/arb-tools';
import { MbmProvider } from '@amzn/react-arb-tools';
import { DEFAULT_LOCALE_EN_US, TRANSLATION_ROUTE, eErrorMessages } from 'src/constants/FDAConstants';
import arbManifest from '../i18n/translations/arbManifest';
import { AuthProvider } from 'src/utils/AuthProvider';
import { AppContextProvider } from './AppContextProvider';
import { AppRouter } from './Navigation/AppRouter';
import { AppInitializationFailed } from './GenericComponents/ErrorFallback';

const localizationContext = new LocalizationContextBuilder().withLocale(DEFAULT_LOCALE_EN_US).withDefaultLocale(DEFAULT_LOCALE_EN_US).build();

const mbmOptions = {
  arbManifest,
  defaultLocalizationContext: localizationContext,
  urlPrefix: TRANSLATION_ROUTE
};

interface AppProps {
  children?: ReactNode; // Use ReactNode for children that could be multiple elements, text, numbers, or null
}

export const App: React.FC<AppProps> = ({ children }) => {
  const handleErrorReset = () => {
    window.location.reload();
  };

  return (
    <ErrorBoundary
      FallbackComponent={({ error, resetErrorBoundary }) =>
        AppInitializationFailed(error, resetErrorBoundary, eErrorMessages.App_Initialization_Failed)
      }
      onReset={handleErrorReset}
    >
      <MbmProvider {...mbmOptions}>
        <AuthProvider>
          <AppContextProvider>
            <AppRouter />
          </AppContextProvider>
        </AuthProvider>
      </MbmProvider>
    </ErrorBoundary>
  );
};
