import React, { useEffect, useState } from 'react';
import { Box, Button, Form, FormField, Input, Modal, SpaceBetween } from '@amzn/awsui-components-react';

export type WarningEventType = 'cancel' | 'proceed';
interface WarningModalProps {
  showWarningModal: boolean;
  closeWarningModal: (warningEvent: WarningEventType) => void;
}
export const WarningModal = ({ showWarningModal, closeWarningModal }: WarningModalProps) => {
  const onCancel = () => {
    closeWarningModal('cancel');
  };

  const onConfirm = () => {
    closeWarningModal('proceed');
  };

  return (
    <Modal
      visible={showWarningModal}
      onDismiss={onCancel}
      closeAriaLabel="Close modal"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onCancel}>
              Cancel
            </Button>
            <Button variant="primary" onClick={onConfirm}>
              Proceed
            </Button>
          </SpaceBetween>
        </Box>
      }
      header="Are you sure ?"
    >
      On proceeding, selected rows will be removed
    </Modal>
  );
};
