import React from 'react';
import { HelpPanel } from '@amzn/awsui-components-react';

export const MecToolBar = () => {
  return (
    <HelpPanel header={<h2>More Info</h2>} footer={<></>}>
      <h3>Description</h3>
      <p>
        MEC (Month-End Close) Manual Adjustments tool is a self-service tool is to help Accounting team to load monthly adjustments into the FDA
        redshift cluster to automate the month end close process. This tool will give Accounting team flexibility to control what information need to
        adjust each month. This process is created to allow Accounting team to load their adjustments into this portal by EOD on Day 1 of MEC.
      </p>
    </HelpPanel>
  );
};
