import { Alert, Box, Button, Modal, SpaceBetween, StatusIndicator } from '@amzn/awsui-components-react';
import { KatFileUploadStatus } from '@amzn/katal-components/es/file-upload';
import { KatFileUpload } from '@amzn/katal-react';
import { isEmpty } from '@aws-amplify/core';
import React, { useContext, useEffect, useState } from 'react';
import { LoadingSpinner } from 'src/components/GenericComponents/Spinner';
import { MecValidation, ValidationEntity, VALIDATION_NOT_INITIATED, VALIDATION_STARTED } from 'src/constants/FDAConstants';
import { MECAdjustments, MecMetadata } from 'src/models/MECAdjustments';
import { AuthContextDetails } from 'src/utils/AuthProvider';
import { getDataFromCsvFile, getHeaderFromCsvFile } from 'src/utils/FileServices';
import * as MecValidations from './MECValidations';
import { v4 as uuidv4 } from 'uuid';
import { getPolarisDateFormat } from 'src/utils/DateTimeUtils';
import { logger } from 'src/utils/FDALogger';

interface MecModalProps {
  mecPlMonth: string;
  showModal: boolean;
  onMecFileUploadCancel: () => void;
  onMecFileUploadConfirm: (mecData: MECAdjustments[]) => void;
  mecMetadata: MecMetadata;
}
interface FileState {
  fileUploadState: FileUploadState;
}

interface FileUploadState {
  status: KatFileUploadStatus;
  error?: string;
}

export const MecFileUploadModal = ({ mecPlMonth, showModal, onMecFileUploadCancel, onMecFileUploadConfirm, mecMetadata }: MecModalProps) => {
  let UPLOAD_FILE_HEADERS_FORMAT = [
    'start_date',
    'end_date',
    'retailer_name',
    'country',
    'screen',
    'asin',
    'pos_units_adjustment',
    'pos_pct_adjustment',
    'rgu_units_adjustment',
    'rgu_pct_adjustment',
    'adjustment_type',
    'channel'
  ];

  const INITIAL_VALIDATION_STATUS: MecValidation = {
    HeadersMatching: { ...VALIDATION_NOT_INITIATED, validationMessage: 'Header validation' },
    RequiredFiledMissing: { ...VALIDATION_NOT_INITIATED, validationMessage: 'Required field validation' },
    ValidUnitFields: { ...VALIDATION_NOT_INITIATED, validationMessage: "Unit field's validation" }
  };

  const userDetails = useContext(AuthContextDetails);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [fileValidation, setFileValidation] = useState<MecValidation>(INITIAL_VALIDATION_STATUS);
  const [file, setFile] = useState<File | null>(null);
  const [uploadFileMecData, setUploadFileMecData] = useState<MECAdjustments[]>([]);
  const [fileState, setFileState] = useState<FileState>({
    fileUploadState: { status: KatFileUploadStatus.READY, error: '' }
  });

  // Parsing the csv data
  // Empty string to null
  // numbers to number format
  const csvParsing = (value: any, column: any) => {
    if (isNaN(value)) {
      return value === '' ? null : value;
    } else {
      return value === '' ? null : +value;
    }
  };
  const mecCsvParseConfig = { header: true, skipEmptyLines: true, transform: csvParsing };

  useEffect(() => {
    setUploadingFile(false);
    setFileValidation(INITIAL_VALIDATION_STATUS);
    setFile(null);
    setUploadFileMecData([]);
    setFileState({ fileUploadState: { status: KatFileUploadStatus.READY, error: '' } });
  }, [showModal]);

  const handleOnFileUpload = async (event: KatFileUpload.FilesAttachedEvent) => {
    logger.info(`MEC upload - handleOnFileUpload`);
    setFile(event.detail.files[0]);
    const fileInfo = await Promise.all([getHeaderFromCsvFile(event.detail.files[0]), getDataFromCsvFile(event.detail.files[0], mecCsvParseConfig)]);
    const fileHeader: string[] = fileInfo[0];
    const fileData: MECAdjustments[] = fileInfo[1];
    ValidateMecData(fileHeader, fileData);
  };

  const handleOnFileReplacement = async (event: KatFileUpload.FilesReplacedEvent) => {
    logger.info(`MEC upload - handleOnFileReplacement`);
    setFile(event.detail.newFiles[0]);
    const fileInfo = await Promise.all([
      getHeaderFromCsvFile(event.detail.newFiles[0]),
      getDataFromCsvFile(event.detail.newFiles[0], mecCsvParseConfig)
    ]);
    const fileHeader: string[] = fileInfo[0];
    const fileData: MECAdjustments[] = fileInfo[1];
    ValidateMecData(fileHeader, fileData);
  };

  const handleOnFileRemove = (event: KatFileUpload.FilesRemovedEvent) => {
    setFile(null);
    setUploadFileMecData([]);
  };

  const ValidateMecData = (fileHeader: string[], mecData: MECAdjustments[]) => {
    logger.info(`MEC upload - ValidateMecData`);
    setFileValidation(INITIAL_VALIDATION_STATUS);

    validateHeaders(fileHeader).then(async (headerValidation) => {
      if (headerValidation.validationStatus === 'success') {
        setFileValidation({
          ...fileValidation,
          HeadersMatching: headerValidation,
          RequiredFiledMissing: { ...VALIDATION_STARTED, validationMessage: 'Validating required fields' }
        });

        // Adding pl_month, mec_id (newly generated UUID), approved_flag, updated_user and updated_time to the uploaded file
        mecData.forEach((excelRow, index) => {
          excelRow.pl_month = mecPlMonth;
          excelRow.mec_id = uuidv4();
          excelRow.approved_flag = 'N';
          excelRow.start_date = excelRow.start_date ? getPolarisDateFormat(excelRow.start_date) : excelRow.start_date;
          excelRow.end_date = excelRow.end_date ? getPolarisDateFormat(excelRow.end_date) : excelRow.end_date;
        });

        setUploadFileMecData(mecData);

        // Validating the uploaded file data
        const validations = await Promise.all([validateRequiredFields(mecData), validateDataFieldValues(mecData)]);

        // Updating the UI about the validation status
        setFileValidation({
          ...fileValidation,
          HeadersMatching: headerValidation,
          RequiredFiledMissing: validations[0],
          ValidUnitFields: validations[1]
        });
      }
    });
  };

  const validateHeaders = (fileHeader: string[]): Promise<ValidationEntity> => {
    return new Promise((resolve, reject) => {
      if (JSON.stringify(UPLOAD_FILE_HEADERS_FORMAT) !== JSON.stringify(fileHeader)) {
        logger.info(`MEC upload - validateHeaders Headers didn't match`);
        setFileState({ fileUploadState: { status: KatFileUploadStatus.ERROR, error: "Headers didn't match" } });
        return resolve({
          colorOverride: 'red',
          validationMessage: "Headers didn't match",
          validationStatus: 'error'
        });
      } else logger.info(`MEC upload - validateHeaders success`);
      return resolve({
        colorOverride: 'green',
        validationMessage: 'Valid File',
        validationStatus: 'success'
      });
    });
  };

  const validateRequiredFields = (couponsDataEntity: MECAdjustments[]): Promise<ValidationEntity> => {
    return new Promise((resolve, reject) => {
      couponsDataEntity.forEach((excelRow, index) => {
        Object.keys(excelRow).forEach((column) => {
          if (isRequiredField(column)) {
            if (!excelRow[column]) {
              return resolve({
                colorOverride: 'red',
                validationMessage: column + ' is required at row ' + excelRowNumber(index),
                validationStatus: 'error'
              });
            }
          }
        });
      });
      return resolve({
        colorOverride: 'green',
        validationMessage: 'Required Fields are valid',
        validationStatus: 'success'
      });
    });
  };

  const validateDataFieldValues = (mecDataEntity: MECAdjustments[]): Promise<ValidationEntity> => {
    let initialValidationError: ValidationEntity = {
      colorOverride: 'red',
      validationMessage: '',
      validationStatus: 'error'
    };

    return new Promise((resolve, reject) => {
      mecDataEntity.forEach((excelRow, index) => {
        let errors: any = MecValidations.validateMECRecord('upload', excelRow, mecMetadata, index);
        if (!isEmpty(errors)) {
          return resolve({
            ...initialValidationError,
            validationMessage: errors
          });
        }
      });
      return resolve({
        colorOverride: 'green',
        validationMessage: "Unit field's are valid",
        validationStatus: 'success'
      });
    });
  };

  const isRequiredField = (columnName: keyof MECAdjustments) => {
    // Except screen all are required fields
    // mec_id will be empty for new records.
    // asin has conditional "required field" validation
    // Special validations for isUnitColumns
    return (
      columnName !== 'screen' &&
      columnName !== 'mec_id' &&
      columnName !== 'asin' &&
      columnName !== 'pos_units_adjustment' &&
      columnName !== 'pos_pct_adjustment' &&
      columnName !== 'rgu_units_adjustment' &&
      columnName !== 'rgu_pct_adjustment'
    );
  };

  const excelRowNumber = (index: number) => index + 2;

  const isValidFile = () => {
    return (
      fileValidation.HeadersMatching.validationStatus === 'success' &&
      fileValidation.RequiredFiledMissing.validationStatus === 'success' &&
      fileValidation.ValidUnitFields.validationStatus === 'success'
    );
  };

  const saveToTable = () => {
    logger.info(`MEC upload - saveToTable`);
    setUploadingFile(true);
    onMecFileUploadConfirm(uploadFileMecData);
  };

  return (
    <Modal
      visible={showModal}
      onDismiss={onMecFileUploadCancel}
      header={'MEC Adjustment File Upload'}
      closeAriaLabel="Close dialog"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onMecFileUploadCancel}>
              Cancel
            </Button>
            <Button variant="primary" disabled={!isValidFile() || uploadingFile} onClick={() => saveToTable()}>
              Save to table
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween size="m" direction="vertical">
        {!uploadingFile && (
          <KatFileUpload
            accept=".csv, text/csv"
            file-view="list"
            variant="small"
            required
            disabled={uploadingFile}
            multiple={false}
            state={fileState}
            onFilesAttached={(e) => handleOnFileUpload(e)}
            onFilesReplaced={(e) => handleOnFileReplacement(e)}
            onFilesRemoved={(e) => handleOnFileRemove(e)}
          >
            <div slot="hint">{'Accepted file format: .csv'}</div>
            <div slot="constraints-message">{'Accepted file format: .csv'}</div>
            <div slot="error">{fileState.fileUploadState.error}</div>
          </KatFileUpload>
        )}
        {uploadingFile && <LoadingSpinner />}
        <div>
          <SpaceBetween size="m" direction="vertical">
            {Object.keys(fileValidation).map((key, index) => {
              return (
                <StatusIndicator key={index} type={fileValidation[key].validationStatus} colorOverride={fileValidation[key].colorOverride}>
                  {JSON.stringify(fileValidation[key].validationMessage)}
                </StatusIndicator>
              );
            })}
          </SpaceBetween>
        </div>
      </SpaceBetween>
    </Modal>
  );
};
