import { Alert, Box, Button, Modal, SpaceBetween, StatusIndicator } from '@amzn/awsui-components-react';
import { KatFileUploadStatus } from '@amzn/katal-components/es/file-upload';
import { KatFileUpload } from '@amzn/katal-react';
import React, { useContext, useEffect, useState } from 'react';
import { LoadingSpinner } from 'src/components/GenericComponents/Spinner';
import { FileValidation, ValidationEntity, VALIDATION_NOT_INITIATED, VALIDATION_STARTED } from 'src/constants/FDAConstants';
import { MappingConfiguration } from 'src/models/AppModel';
import { getDataFromCsvFile, getHeaderFromCsvFile } from 'src/utils/FileServices';

interface MappingsModalProps {
  uploadFileShowModal: boolean;
  onCancelUpload: () => void;
  onConfirmUpload: (uploadedMappingData: any[]) => void;
  selectedMappingConfig: MappingConfiguration | undefined;
}

interface FileState {
  fileUploadState: FileUploadState;
}

interface FileUploadState {
  status: KatFileUploadStatus;
  error?: string;
}

export const MappingsUploadModal = ({
  uploadFileShowModal,
  onCancelUpload,
  onConfirmUpload: onConfirm,
  selectedMappingConfig
}: MappingsModalProps) => {
  const FILE_HEADERS = selectedMappingConfig?.table_cols?.split(',');

  const INITIAL_VALIDATION_STATUS: FileValidation = {
    HeadersMatching: { ...VALIDATION_NOT_INITIATED, validationMessage: 'Header validation' },
    RequiredFiledMissing: { ...VALIDATION_NOT_INITIATED, validationMessage: 'Required field validation' }
  };

  const [uploadingFile, setUploadingFile] = useState(false);
  const [fileValidation, setFileValidation] = useState<FileValidation>(INITIAL_VALIDATION_STATUS);
  const [uploadedMappingData, setUploadedMappingData] = useState<any[]>([]);
  const [fileState, setFileState] = useState<FileState>({
    fileUploadState: { status: KatFileUploadStatus.READY, error: '' }
  });
  const couponCsvParseConfig = { header: true, skipEmptyLines: true };

  useEffect(() => {
    setUploadingFile(false);
    setFileValidation(INITIAL_VALIDATION_STATUS);
    setUploadedMappingData([]);
    setFileState({ fileUploadState: { status: KatFileUploadStatus.READY, error: '' } });
  }, [uploadFileShowModal]);

  const handleOnFileUpload = async (event: KatFileUpload.FilesAttachedEvent) => {
    const fileInfo = await Promise.all([
      getHeaderFromCsvFile(event.detail.files[0]),
      getDataFromCsvFile(event.detail.files[0], couponCsvParseConfig)
    ]);
    const fileHeader: string[] = fileInfo[0];
    const fileData: any[] = fileInfo[1];
    ValidateNBDCouponData(fileHeader, fileData);
  };

  const handleOnFileReplacement = async (event: KatFileUpload.FilesReplacedEvent) => {
    const fileInfo = await Promise.all([
      getHeaderFromCsvFile(event.detail.newFiles[0]),
      getDataFromCsvFile(event.detail.newFiles[0], couponCsvParseConfig)
    ]);
    const fileHeader: string[] = fileInfo[0];
    const fileData: any[] = fileInfo[1];
    ValidateNBDCouponData(fileHeader, fileData);
  };

  const handleOnFileRemove = (event: KatFileUpload.FilesRemovedEvent) => {
    setUploadedMappingData([]);
  };

  const ValidateNBDCouponData = (fileHeader: string[], nbdData: any[]) => {
    setFileValidation(INITIAL_VALIDATION_STATUS);

    validateHeaders(fileHeader).then(async (headerValidation) => {
      if (headerValidation.validationStatus === 'success') {
        setFileValidation({
          ...fileValidation,
          HeadersMatching: headerValidation,
          RequiredFiledMissing: { ...VALIDATION_STARTED, validationMessage: 'Validating required fields' }
        });
        setUploadedMappingData(nbdData);

        // Validating the uploaded file data
        const validations = await Promise.all([validateRequiredFields(nbdData)]);

        // Updating the UI about the validation status
        setFileValidation({
          ...fileValidation,
          HeadersMatching: headerValidation,
          RequiredFiledMissing: validations[0]
        });
      }
    });
  };

  const validateRequiredFields = (uploadedMappingData: any[]): Promise<ValidationEntity> => {
    return new Promise((resolve, reject) => {
      uploadedMappingData.forEach((excelRow, index) => {
        Object.keys(excelRow).forEach((column) => {
          if (selectedMappingConfig?.unique_key?.split(',').includes(column) && !excelRow[column]) {
            return resolve({
              colorOverride: 'red',
              validationMessage: column + ' is required at row ' + excelRowNumber(index),
              validationStatus: 'error'
            });
          }
        });
      });
      return resolve({
        colorOverride: 'green',
        validationMessage: 'Required Fields are valid',
        validationStatus: 'success'
      });
    });
  };

  const excelRowNumber = (index: number) => index + 2;

  const validateHeaders = (fileHeader: string[]): Promise<ValidationEntity> => {
    return new Promise((resolve, reject) => {
      if (JSON.stringify(FILE_HEADERS) !== JSON.stringify(fileHeader)) {
        setFileState({ fileUploadState: { status: KatFileUploadStatus.ERROR, error: "Headers didn't match" } });
        return resolve({
          colorOverride: 'red',
          validationMessage: "Headers didn't match",
          validationStatus: 'error'
        });
      } else
        return resolve({
          colorOverride: 'green',
          validationMessage: 'Valid File',
          validationStatus: 'success'
        });
    });
  };

  const isValidFile = () => {
    return fileValidation.HeadersMatching.validationStatus === 'success' && fileValidation.RequiredFiledMissing.validationStatus === 'success';
  };

  const saveToTable = () => {
    setUploadingFile(true);
    onConfirm(uploadedMappingData);
  };

  return (
    <Modal
      visible={uploadFileShowModal}
      onDismiss={onCancelUpload}
      header={'Upload a .csv Mapping data'}
      closeAriaLabel="Close dialog"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onCancelUpload}>
              Cancel
            </Button>
            <Button variant="primary" disabled={!isValidFile() || uploadingFile} onClick={() => saveToTable()}>
              Save to table
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween size="m" direction="vertical">
        {!uploadingFile && (
          <KatFileUpload
            accept=".csv, text/csv"
            file-view="list"
            variant="small"
            required
            disabled={uploadingFile}
            multiple={false}
            state={fileState}
            onFilesAttached={(e: any) => handleOnFileUpload(e)}
            onFilesReplaced={(e: any) => handleOnFileReplacement(e)}
            onFilesRemoved={(e: any) => handleOnFileRemove(e)}
          >
            <div slot="hint">{'Accepted file format: .csv'}</div>
            <div slot="constraints-message">{'Accepted file format: .csv'}</div>
            <div slot="error">{fileState.fileUploadState.error}</div>
          </KatFileUpload>
        )}
        {uploadingFile && <LoadingSpinner />}
        <div>
          <SpaceBetween size="m" direction="vertical">
            {Object.keys(fileValidation).map((key, index) => {
              return (
                <StatusIndicator key={index} type={fileValidation[key].validationStatus} colorOverride={fileValidation[key].colorOverride}>
                  {fileValidation[key].validationMessage}
                </StatusIndicator>
              );
            })}
          </SpaceBetween>
        </div>
      </SpaceBetween>
    </Modal>
  );
};
