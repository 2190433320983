import React, { useState } from 'react';
import { eSIMLinks } from 'src/constants/FDAConstants';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'src/utils/AuthProvider';
import { AppLayout, Box, Button, Container, Flashbar, FlashbarProps, Grid, Link, SpaceBetween } from '@amzn/awsui-components-react';

export const LandingPage = () => {
  const userAuthDetails = useAuth();
  const navigate = useNavigate();
  const [flashbarItems, setFlashbarItems] = useState<FlashbarProps.MessageDefinition[]>([]);

  const onFollowLink = (link: string) => {
    navigate(link);
  };

  return (
    <div>
      <AppLayout
        disableContentPaddings={true}
        notifications={<Flashbar items={flashbarItems} />}
        navigationHide
        toolsHide
        content={
          <Box margin={{ bottom: 'l' }}>
            <div className="custom-home__header">
              <Box padding={{ vertical: 'xxl', horizontal: 's' }}>
                <Grid
                  gridDefinition={[
                    { offset: { l: 2, xxs: 1 }, colspan: { l: 8, xxs: 10 } },
                    { colspan: { xl: 6, l: 5, s: 6, xxs: 10 }, offset: { l: 2, xxs: 1 } },
                    { colspan: { xl: 2, l: 3, s: 4, xxs: 10 }, offset: { s: 0, xxs: 1 } }
                  ]}
                >
                  <Box fontWeight="light" padding={{ top: 'xs' }}>
                    <span className="custom-home__category">{'Devices & Services Finance'}</span>
                  </Box>

                  <div className="custom-home__header-title">
                    <Box variant="h1" fontWeight="heavy" padding="n" fontSize="display-l" color="inherit">
                      FDA
                    </Box>
                    <Box variant="p" fontWeight="light">
                      <span className="custom-home__header-sub-title">
                        {
                          'FDA Portal is created to automate some of the critical projects which are manually managed. The purpose of automation is to offload manual, time-consuming, repetitive, and/or administrative work. This application is more interactive and dynamic for the users to manage their data and also provides fastest way to store and retrieve the data. Mapper feature helps FDA team to store and manage the data in one place.'
                        }
                      </span>
                    </Box>
                  </div>

                  <div className="custom-home__header-cta">
                    {!userAuthDetails.isAdmin && !userAuthDetails.isBusinessUser && (
                      <Container>
                        <SpaceBetween size="xl">
                          <Box variant="h2" padding="n">
                            Welcome to FDA
                          </Box>
                          <Button href={eSIMLinks.REQUEST_ACCESS} target="_blank" iconAlign="right" iconName="external" variant="primary">
                            Request Access
                          </Button>
                        </SpaceBetween>
                      </Container>
                    )}
                  </div>
                </Grid>
              </Box>
            </div>

            <Box padding={{ top: 'xxxl', horizontal: 's' }}>
              <Grid gridDefinition={[{ colspan: { xl: 6, l: 5, s: 6, xxs: 10 }, offset: { l: 2, xxs: 1 } }]}>
                <SpaceBetween size="xxl">
                  <div>
                    <Box variant="h1" tagOverride="h2" padding={{ bottom: 's', top: 'n' }}>
                      Projects
                    </Box>
                  </div>

                  <div>
                    <Box variant="h2" tagOverride="h2" padding={{ bottom: 's', top: 'n' }}>
                      <Link onFollow={() => onFollowLink('/accessory_asin')} fontSize="heading-l" variant="primary">
                        Accessory ASIN
                      </Link>
                    </Box>
                    <Container>
                      <Box variant="p">
                        {
                          'This process to source accessory ASIN information from various data sources and to allow customers to easily update the ASIN mappings in a self-service manner.'
                        }
                      </Box>
                    </Container>
                  </div>

                  <div>
                    <Box variant="h2" tagOverride="h2" padding={{ bottom: 's', top: 'n' }}>
                      <Link onFollow={() => onFollowLink('/mec_adjustments')} fontSize="heading-l" variant="primary">
                        MEC Adjustments
                      </Link>
                    </Box>
                    <Container>
                      <Box variant="p">
                        {
                          'This process is created to allow Accounting team to load their adjustments into this portal by EOD on Day 1 of MEC (Month-End Close).'
                        }
                      </Box>
                    </Container>
                  </div>

                  <div>
                    <Box variant="h2" tagOverride="h2" padding={{ bottom: 's', top: 'n' }}>
                      <Link onFollow={() => onFollowLink('/nbd_coupon_redemption')} fontSize="heading-l" variant="primary">
                        NBD Coupon Redemption Information Intake
                      </Link>
                    </Box>
                    <Container>
                      <Box variant="p">
                        {`This process feeds Promotion Approval Workflow Service (PAWS) data to the BIGFOOT_NBD_Coupon Redemption Skynet Dashboard. The dashboard is scheduled to refresh at midnight PST.`}
                      </Box>
                    </Container>
                  </div>

                  <div>
                    <Box variant="h1" tagOverride="h2" padding={{ bottom: 's', top: 'n' }}>
                      Mappings
                    </Box>
                  </div>

                  <div>
                    <Container>
                      <Box variant="p">
                        {
                          'Allows FDA team to create or update the lookup/reference table dynamically in a batch process mode. Users are provided with an option to add custom dropdown fields with values or dropdowns by referencing another table values.'
                        }
                      </Box>
                    </Container>
                  </div>
                </SpaceBetween>
              </Grid>
            </Box>
          </Box>
        }
      />
    </div>
  );
};
