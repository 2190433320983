import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Button,
  ButtonDropdown,
  CollectionPreferences,
  FlashbarProps,
  FormField,
  Header,
  Pagination,
  RadioGroup,
  SpaceBetween,
  Table,
  TextFilter,
  TextFilterProps
} from '@amzn/awsui-components-react';
import React, { useEffect, useRef, useState } from 'react';
import { EmptyStateMessage } from 'src/components/GenericComponents/EmptyState';
import { FILE_DOWNLOAD_OPTIONS } from 'src/constants/FDAConstants';
import { MECAdjustments, MecMetadata, PostApproveMEC } from 'src/models/MECAdjustments';
import { approveMECMetadata } from 'src/services/FDAServices';
import { isDifferenceMoreThanAMonth } from 'src/utils/DateTimeUtils';
import { logger } from 'src/utils/FDALogger';
import { exportAsCSVFile, exportAsExcelFile } from 'src/utils/FileServices';
import { MECState } from './MecAdjustment';
import { ConfirmActionType, MECConfirmModal } from './MecConfirmModal';
import { useMEC } from './MecContext';
import { MecFileUploadModal } from './MecFileUploadModal';
import {
  ACCESSORY_ASIN_COLUMN_DEFINITIONS,
  CUSTOM_PREFERENCE_OPTIONS,
  getMatchesCountText,
  MEC_DEFAULT_PREFERENCES,
  MEC_PAGE_SELECTOR_OPTIONS,
  MEC_SELECTOR_OPTIONS,
  paginationLabels
} from './MECTableDefinitions';

interface MecTableProps {
  mecState: MECState;
  updateMecState: (mecState: MECState) => void;
  editData: (row: MECAdjustments) => void;
  newData: () => void;
  deleteRow: (row: MECAdjustments) => void;
  submitMECData: () => void;
  displayFlashMessage: (content: string, flashBarType: FlashbarProps.Type) => void;
  mecMetadata: MecMetadata;
  resetMec: () => void;
  splitPanelOpen: boolean;
}

export const MecTable = ({
  mecState,
  updateMecState,
  submitMECData,
  editData,
  newData,
  deleteRow,
  displayFlashMessage,
  mecMetadata,
  resetMec,
  splitPanelOpen
}: MecTableProps) => {
  const { mecData, setMecData } = useMEC();
  const textFilterRef = useRef<TextFilterProps.Ref>(null);
  const [preferences, setPreferences] = useState(MEC_DEFAULT_PREFERENCES);
  const [tableSelectedItem, setTableSelectedItem] = useState<MECAdjustments[]>([]);
  const isOnlyOneSelected = tableSelectedItem.length === 1;

  const [mecUploadFileModal, setMecUploadFileModal] = useState(false);
  const [mecConfirmModal, setMecConfirmModal] = useState(false);

  const [approvalSelectedItems, setApprovalSelectedItems] = useState<MECAdjustments[]>([]);
  const isDifferenceMoreThanMonth = isDifferenceMoreThanAMonth(mecState.mecValue);
  useEffect(() => {
    setTableSelectedItem([]);
  }, [mecData]);

  const downloadAsFile = (fileType: any) => {
    logger.info(`MEC - File Download as ${fileType}`);
    let downloadData = mecData.map((mecRow: MECAdjustments) => {
      return {
        start_date: mecRow.start_date,
        end_date: mecRow.end_date,
        retailer_name: mecRow.retailer_name,
        country: mecRow.country,
        screen: mecRow.screen,
        asin: mecRow.asin,
        pos_units_adjustment: mecRow.pos_units_adjustment,
        pos_pct_adjustment: mecRow.pos_pct_adjustment,
        rgu_units_adjustment: mecRow.rgu_units_adjustment,
        rgu_pct_adjustment: mecRow.rgu_pct_adjustment,
        adjustment_type: mecRow.adjustment_type,
        channel: mecRow.channel
      };
    });
    if (fileType === 'excel') exportAsExcelFile(downloadData, 'MEC', 'Mec');
    else exportAsCSVFile(downloadData, 'MEC', 'MEC');
  };

  const tableSelectionChanged = (detail: any) => {
    if (mecState.mecApprovalMode) {
      setApprovalSelectedItems(detail.selectedItems);
    } else {
      setTableSelectedItem(detail.selectedItems);
    }
  };

  const onRowClickChanged = (detail: any) => {
    setTableSelectedItem([detail.item]);
  };

  // To show Upload File Modal
  const uploadMECFile = () => {
    setMecUploadFileModal(true);
  };

  // Cancelling the File upload
  const onMecFileUploadCancel = () => {
    setMecUploadFileModal(false);
  };

  // On File Upload confirmation
  const onMecFileUploadConfirm = (uploadedData: MECAdjustments[]) => {
    const updatedData = Array.from(uploadedData.concat(mecData));
    setMecData(updatedData);
    updateMecState({ ...mecState, mecDataChanged: true });
    setMecUploadFileModal(false);
  };

  // Show confirmation for Delete Modal
  const handleRemove = () => {
    setMecConfirmModal(true);
  };

  // On cancelling delete
  const onCancel = () => {
    setMecConfirmModal(false);
  };

  // On confirming delete
  const onConfirm = (actionType: ConfirmActionType) => {
    if (actionType === 'delete') {
      deleteRow(tableSelectedItem[0]);
      setMecConfirmModal(false);
    }
  };

  // Approval vs Normal Mode
  useEffect(() => {
    // Approval Mode
    if (mecState.mecApprovalMode) {
      const approvedRecords = mecData.filter((mecRow) => mecRow.approved_flag === 'Y');
      setApprovalSelectedItems(approvedRecords);
      // updateMecState({ ...mecState, mecDataChanged: true, mecApprovalMode: mecState.mecApprovalMode });
    } else {
      setApprovalSelectedItems([]);
      // updateMecState({ ...mecState, mecDataChanged: true, mecApprovalMode: mecState.mecApprovalMode });
    }
  }, [mecState.mecApprovalMode]);

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(mecData || [], {
    filtering: {
      empty: <EmptyStateMessage title={'No data'} subtitle="" />,
      noMatch: (
        <EmptyStateMessage
          title="No matches"
          subtitle="We can’t find a match."
          action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
        />
      )
    },
    pagination: {
      pageSize: preferences.pageSize
    },
    sorting: {},
    selection: {}
  });

  const updatePageSize = (pageSize: string) => {
    setPreferences({ ...preferences, pageSize: +pageSize });
  };

  const approveSelectedRecords = () => {
    updateMecState({ ...mecState, mecDataSubmitInProgress: true, mecDataLoading: true });
    displayFlashMessage('Submitting the request.', 'info');

    const mec_id_approved: string[] = [];
    // const approvedRows = mecData.filter((mecRow) => mecRow.approved_flag === "Y");
    approvalSelectedItems.forEach((approvedRows) => {
      if (approvedRows.mec_id) {
        mec_id_approved.push(approvedRows.mec_id);
      }
    });
    let postBody: PostApproveMEC = {
      mec_id: mec_id_approved,
      pl_month: mecState.mecValue
    };
    approveMECMetadata(postBody)
      .then((response) => {
        displayFlashMessage('Request submitted successfully.', 'success');
        const updatedMEC = mecData?.map((mecRow) => {
          // After Approval success, update the actual mecData
          let approvedMECRows: MECAdjustments | undefined = approvalSelectedItems.find((approvedRows) => approvedRows.mec_id === mecRow.mec_id);
          if (approvedMECRows) {
            return { ...approvedMECRows, approved_flag: 'Y' };
          } else {
            return { ...mecRow, approved_flag: 'N' };
          }
        });
        setMecData(updatedMEC);
        setApprovalSelectedItems([]);
        // resetMec();
        updateMecState({ ...mecState, mecDataSubmitInProgress: false, mecApprovalMode: false, mecDataLoading: false });
      })
      .catch((err) => {
        displayFlashMessage('Unable to submit request.', 'error');
        updateMecState({ ...mecState, mecDataSubmitInProgress: false, mecDataLoading: false });
      });
  };

  return (
    <>
      <MecFileUploadModal
        mecPlMonth={mecState.mecValue}
        onMecFileUploadCancel={onMecFileUploadCancel}
        onMecFileUploadConfirm={onMecFileUploadConfirm}
        showModal={mecUploadFileModal}
        mecMetadata={mecMetadata}
      />
      <MECConfirmModal
        mecConfirmModal={mecConfirmModal}
        onConfirmingTheCancel={onCancel}
        onConfirmingTheDelete={(action) => onConfirm(action)}
        confirmActionType={'delete'}
      ></MECConfirmModal>
      <Table
        className="fda-table"
        {...collectionProps}
        header={
          <Header
            counter={
              mecData &&
              ((mecState.mecApprovalMode ? approvalSelectedItems : tableSelectedItem)!.length
                ? `(${(mecState.mecApprovalMode ? approvalSelectedItems : tableSelectedItem)!.length}/${mecData.length})`
                : `(${mecData.length})`)
            }
            actions={
              <>
                {!mecState.mecApprovalMode && (
                  <SpaceBetween size="xs" direction="horizontal">
                    <Button onClick={resetMec} disabled={mecState.mecDataSubmitInProgress || !mecState.mecDataFetched}>
                      Reset
                    </Button>
                    <ButtonDropdown
                      disabled={!mecState.mecDataFetched}
                      onItemClick={({ detail }) => {
                        downloadAsFile(detail.id);
                      }}
                      items={FILE_DOWNLOAD_OPTIONS}
                    >
                      Download As
                    </ButtonDropdown>
                    <Button
                      onClick={uploadMECFile}
                      disabled={isDifferenceMoreThanMonth || mecState.mecDataSubmitInProgress || !mecState.mecDataFetched}
                    >
                      Upload from a file
                    </Button>
                    <Button
                      onClick={() => newData()}
                      disabled={isDifferenceMoreThanMonth || !mecState.mecDataFetched || mecState.mecDataSubmitInProgress}
                    >
                      Add
                    </Button>
                    <Button
                      onClick={() => editData(tableSelectedItem[0])}
                      disabled={
                        isDifferenceMoreThanMonth ||
                        !isOnlyOneSelected ||
                        !mecState.mecDataFetched ||
                        mecState.mecDataSubmitInProgress ||
                        tableSelectedItem[0].approved_flag === 'Y'
                      }
                    >
                      Edit
                    </Button>
                    <Button
                      disabled={
                        isDifferenceMoreThanMonth ||
                        tableSelectedItem?.length === 0 ||
                        !mecState.mecDataFetched ||
                        mecState.mecDataSubmitInProgress ||
                        tableSelectedItem[0].approved_flag === 'Y'
                      }
                      onClick={() => handleRemove()}
                    >
                      Remove
                    </Button>
                    <Button
                      variant="primary"
                      onClick={submitMECData}
                      disabled={isDifferenceMoreThanMonth || !mecState.mecDataChanged || mecState.mecDataSubmitInProgress || splitPanelOpen}
                    >
                      Submit
                    </Button>
                  </SpaceBetween>
                )}
                {mecState.mecApprovalMode && (
                  <SpaceBetween size="xs" direction="horizontal">
                    <Button
                      variant="primary"
                      onClick={approveSelectedRecords}
                      disabled={mecState.mecDataSubmitInProgress || !mecState.mecDataFetched}
                    >
                      Submit
                    </Button>
                  </SpaceBetween>
                )}
              </>
            }
          >
            {mecState.mecValue}
          </Header>
        }
        visibleColumns={preferences.visibleContent}
        // trackBy={"mec_id"}
        // resizableColumns={true}
        // wrapLines={preferences.wrapLines}
        loading={mecState.mecDataLoading}
        loadingText="Loading MEC's"
        columnDefinitions={ACCESSORY_ASIN_COLUMN_DEFINITIONS}
        items={items}
        selectionType={mecState.mecApprovalMode ? 'multi' : 'single'}
        onSelectionChange={({ detail }) => tableSelectionChanged(detail)}
        onRowClick={mecState.mecApprovalMode ? undefined : ({ detail }) => onRowClickChanged(detail)}
        selectedItems={mecState.mecApprovalMode ? approvalSelectedItems : tableSelectedItem}
        filter={
          <TextFilter
            {...filterProps}
            ref={textFilterRef}
            countText={getMatchesCountText(filteredItemsCount!)}
            filteringPlaceholder="Search"
            filteringAriaLabel="Search"
          />
        }
        pagination={
          <>
            <SpaceBetween size="m" direction="horizontal">
              <Pagination {...paginationProps} ariaLabels={paginationLabels} />
              <ButtonDropdown
                onItemClick={(item) => {
                  updatePageSize(item.detail.id);
                }}
                items={[
                  { text: '50', id: '50' },
                  { text: '100', id: '100' },
                  { text: '150', id: '150' },
                  { text: '200', id: '200' }
                ]}
              >
                {preferences.pageSize}
              </ButtonDropdown>
            </SpaceBetween>
          </>
        }
        preferences={
          <CollectionPreferences
            preferences={preferences}
            onConfirm={(event) => setPreferences(event.detail)}
            title="Preferences"
            confirmLabel="Confirm"
            cancelLabel="Cancel"
            visibleContentPreference={{
              title: 'Select visible columns',
              options: MEC_SELECTOR_OPTIONS
            }}
            pageSizePreference={{
              title: 'Page size',
              options: MEC_PAGE_SELECTOR_OPTIONS
            }}
            wrapLinesPreference={{
              label: 'Wrap lines',
              description: 'Check to see all the text and wrap the lines'
            }}
            customPreference={(state, setState) => (
              <FormField stretch={true} label="View as">
                <RadioGroup value={state} onChange={(event) => setState(event.detail.value)} items={CUSTOM_PREFERENCE_OPTIONS} />
              </FormField>
            )}
          />
        }
      />
    </>
  );
};
