import * as XLSX from 'xlsx';
import * as PapaParse from 'papaparse';
import { saveAs } from 'file-saver';
import { getCurrentUserLocalTimeFormat } from './DateTimeUtils';

const CSV_EXTENSION = '.csv';
const EXCEL_EXTENSION = '.xlsx';

export const exportAsExcelFile = (json: any[], excelFileName: string, worksheetName: string) => {
  const downloadingFileName = excelFileName + '_' + getCurrentUserLocalTimeFormat() + EXCEL_EXTENSION;
  const workbook: XLSX.WorkBook = XLSX.utils.book_new();
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
  XLSX.utils.book_append_sheet(workbook, worksheet, worksheetName);
  XLSX.writeFileXLSX(workbook, downloadingFileName);
};

export const exportAsCSVFile = (json: any[], excelFileName: string, worksheetName: string) => {
  const downloadingFileName = excelFileName + '_' + getCurrentUserLocalTimeFormat() + CSV_EXTENSION;
  const workbook: XLSX.WorkBook = XLSX.utils.book_new();
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
  XLSX.utils.book_append_sheet(workbook, worksheet, worksheetName);
  XLSX.writeFile(workbook, downloadingFileName);
};

export const exportAsPapaparseCSVFile = (json: any[], excelFileName: string) => {
  const downloadingFileName = excelFileName + '_' + getCurrentUserLocalTimeFormat() + CSV_EXTENSION;
  const csvData = PapaParse.unparse(json);
  saveAs(csvData, downloadingFileName);
};

export const getHeaderFromCsvFile = (file: File): Promise<any[]> => {
  return new Promise<any[]>((resolve) => {
    const reader: FileReader = new FileReader();
    reader.readAsText(file);
    reader.onload = (_event: Event) => {
      const csvData = PapaParse.parse(
        reader.result as string,
        Object.assign({} as PapaParse.ParseConfig, {
          error: () => {},
          encoding: 'UTF-8'
        })
      );
      resolve(csvData.data[0]);
    };
  });
};

export const getDataFromCsvFile = (file: File, parsingConfig: any): Promise<any[]> => {
  return new Promise<any[]>((resolve) => {
    const reader: FileReader = new FileReader();
    reader.readAsText(file);
    reader.onload = (_event: Event) => {
      const csvData = PapaParse.parse(
        reader.result as string,
        Object.assign(parsingConfig as PapaParse.ParseConfig, {
          error: () => {},
          encoding: 'UTF-8'
        })
      );
      resolve(csvData.data);
    };
  });
};
