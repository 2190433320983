import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  AppLayoutProps,
  Autosuggest,
  Box,
  Button,
  Checkbox,
  DatePicker,
  Form,
  FormField,
  Input,
  NonCancelableCustomEvent,
  Select,
  SpaceBetween,
  StatusIndicator
} from '@amzn/awsui-components-react';
import { useNumberPref, useObjectPref } from 'src/utils/UserPrefs';
import { DropdownModel, MECAdjustments, MECAdjustmentsForm, MecMetadata } from 'src/models/MECAdjustments';
import { AuthContextDetails } from 'src/utils/AuthProvider';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { isEmpty } from '@aws-amplify/core';
import * as MecValidations from './MECValidations';
import { MECState } from './MecAdjustment';
import { getCurrentUserLocalTime, getPolarisDateFormat } from 'src/utils/DateTimeUtils';
import { getValidationErrorMessage } from 'src/utils/GenericUtils';
import { v4 as uuidv4 } from 'uuid';

const AdjustmentTypeAutoSuggestions = [
  { value: 'COMEXPORT ELIMINATION ENTRY' },
  { value: 'AC COMMERCIAL ELIMINATION ENTRY' },
  { value: 'BBY OFFLINE' },
  { value: 'BUYBACK' }
];

export function useSplitPanelProps() {
  const [splitPanelPrefs, setSplitPanelPrefs] = useObjectPref<AppLayoutProps.SplitPanelPreferences>(location.pathname, 'splitPanelPrefs', {
    position: 'side'
  });
  const [splitPanelSize, setSplitPanelSize] = useNumberPref(location.pathname, 'splitPanelSize');
  const [splitPanelOpen, setSplitPanelOpen] = useState<boolean>(false);

  const onSplitPanelPreferencesChange = (event: NonCancelableCustomEvent<AppLayoutProps.SplitPanelPreferences>) => {
    setSplitPanelPrefs(event.detail);
  };

  const onSplitPanelResize = (event: NonCancelableCustomEvent<AppLayoutProps.SplitPanelResizeDetail>) => {
    setSplitPanelSize(event.detail.size);
  };

  const onSplitPanelToggle = (event: NonCancelableCustomEvent<AppLayoutProps.ChangeDetail>) => {
    setSplitPanelOpen(event.detail.open);
  };

  const splitPanelOpenByEdit = (splitPanelOpen: boolean) => {
    setSplitPanelOpen(splitPanelOpen);
  };

  useEffect(() => {
    let updatedSplitPanelPrefs: AppLayoutProps.SplitPanelPreferences = { position: 'side' };
    setSplitPanelPrefs(updatedSplitPanelPrefs);
  }, []);

  return {
    splitPanelPrefs,
    onSplitPanelPreferencesChange,
    splitPanelSize,
    onSplitPanelResize,
    splitPanelOpen,
    splitPanelOpenByEdit,
    onSplitPanelToggle
  };
}

const InitialValues: MECAdjustmentsForm = {
  mec_id: null,
  pl_month: '',
  start_date: '',
  end_date: '',
  prefix: false,
  retailer_name: { label: '', value: '' },
  suffix: false,
  country: { label: '', value: '' },
  screen: { label: '', value: '' },
  asin: '',
  pos_units_adjustment: null,
  pos_pct_adjustment: null,
  rgu_units_adjustment: null,
  rgu_pct_adjustment: null,
  adjustment_type: '',
  channel: { label: '', value: '' },
  approved_flag: ''
};

export const mecDataEditPanel = (
  type: 'single' | 'multiple',
  mecMetadata: MecMetadata,
  eventType: 'edit' | 'new',
  splitPanelRow: MECAdjustments | undefined,
  updateTheTable: (formValues: MECAdjustments) => void,
  mecState: MECState
) => {
  const replaceString = /\%/gi;
  const userContext = useContext(AuthContextDetails);
  const mecFormRef = useRef<FormikProps<MECAdjustmentsForm>>(null);
  const [mecFormInitialValues, setMecFormInitialValues] = useState<MECAdjustmentsForm>(InitialValues);

  useEffect(() => {
    mecFormRef.current?.resetForm();
    if (eventType === 'edit') {
      let medEditRow = splitPanelRow ? splitPanelRow : ({} as MECAdjustments);
      setMecFormInitialValues({
        mec_id: medEditRow.mec_id,
        pl_month: medEditRow.pl_month,
        start_date: getPolarisDateFormat(medEditRow.start_date),
        end_date: getPolarisDateFormat(medEditRow.end_date),
        prefix: medEditRow.retailer_name.startsWith('%'),
        retailer_name: stringToPolarisDropdown(medEditRow.retailer_name.replace(replaceString, '')),
        suffix: medEditRow.retailer_name.endsWith('%'),
        country: stringToPolarisDropdown(medEditRow.country),
        screen: stringToPolarisDropdown(medEditRow.screen),
        asin: medEditRow.asin,
        pos_units_adjustment: medEditRow.pos_units_adjustment,
        pos_pct_adjustment: medEditRow.pos_pct_adjustment,
        rgu_units_adjustment: medEditRow.rgu_units_adjustment,
        rgu_pct_adjustment: medEditRow.rgu_pct_adjustment,
        adjustment_type: medEditRow.adjustment_type,
        channel: stringToPolarisDropdown(medEditRow.channel),
        approved_flag: medEditRow.approved_flag
      });
    } else {
      setMecFormInitialValues({ ...InitialValues, pl_month: mecState.mecValue, mec_id: uuidv4() });
    }
  }, [eventType, splitPanelRow, mecState]);

  const stringToPolarisDropdown = (objectValue: string | undefined) => {
    if (objectValue)
      return {
        value: objectValue,
        label: objectValue
      };
    else
      return {
        value: '',
        label: ''
      };
  };

  const retailersDropdown = () => {
    if (!isEmpty(mecMetadata))
      return mecMetadata?.retailToCountry
        .sort((a, b) => (a.retailer < b.retailer ? -1 : 1))
        .map((retailers) => {
          return {
            label: retailers.retailer,
            value: retailers.retailer
          };
        });
    else return [];
  };

  const countriesDropdown = (formValues: MECAdjustmentsForm) => {
    if (!isEmpty(mecMetadata)) {
      if (formValues.retailer_name.value === null) {
        return mecMetadata?.countryToRetail
          .sort((a, b) => (a.country < b.country ? -1 : 1))
          .map((countries) => {
            return {
              label: countries.country,
              value: countries.country
            };
          });
      } else {
        return mecMetadata?.retailToCountry
          .find((retailers) => retailers.retailer === formValues.retailer_name.value)
          ?.country.sort((a, b) => (a < b ? -1 : 1))
          .map((country) => {
            return {
              label: country,
              value: country
            };
          });
      }
    } else return [];
  };

  const screenDropdown = () => {
    if (!isEmpty(mecMetadata))
      return mecMetadata?.screen
        .sort((a, b) => (a < b ? -1 : 1))
        .map((screens) => {
          return {
            label: screens,
            value: screens
          };
        });
    else return [];
  };

  const channelDropdown = () => {
    return [
      {
        label: 'Online',
        value: 'Online'
      },
      {
        label: 'Offline',
        value: 'Offline'
      }
    ];
  };

  const handleSubmit = (formValues: MECAdjustmentsForm, formikHelpers: FormikHelpers<MECAdjustmentsForm>) => {
    const formattedMECRow = formatFormValuesToMECRow(formValues);
    splitPanelRow = undefined;
    updateTheTable(formattedMECRow);
    setTimeout(() => {
      formikHelpers.setSubmitting(false);
    }, 2000);
  };

  const formatFormValuesToMECRow = (formValues: MECAdjustmentsForm): MECAdjustments => {
    let retailer = polarisDropdownToString(formValues.retailer_name);
    retailer = formValues.prefix ? '%' + retailer : retailer;
    retailer = formValues.suffix ? retailer + '%' : retailer;
    return {
      mec_id: formValues.mec_id,
      pl_month: formValues.pl_month,
      start_date: getPolarisDateFormat(formValues.start_date),
      end_date: getPolarisDateFormat(formValues.end_date),
      retailer_name: retailer,
      country: polarisDropdownToString(formValues.country),
      screen: polarisDropdownToString(formValues.screen),
      asin: formValues.asin,
      pos_units_adjustment: formValues.pos_units_adjustment,
      pos_pct_adjustment: formValues.pos_pct_adjustment,
      rgu_units_adjustment: formValues.rgu_units_adjustment,
      rgu_pct_adjustment: formValues.rgu_pct_adjustment,
      adjustment_type: formValues.adjustment_type,
      channel: polarisDropdownToString(formValues.channel),
      approved_flag: 'N',
      updated_time: getCurrentUserLocalTime(),
      updated_user: userContext.Alias
    };
  };

  const polarisDropdownToString = (dropdownData: DropdownModel): string => {
    return dropdownData.label;
  };

  const formikCustomValidate = (formValues: MECAdjustmentsForm | undefined) => {
    if (!formValues) return;
    return MecValidations.validateMECRecord('form', formValues, mecMetadata);
  };

  return {
    header: eventType.toUpperCase(),
    body: (
      <>
        {!mecState.mecDataFetched && (
          <>
            <StatusIndicator type="info">Please load PL Month</StatusIndicator>
          </>
        )}
        {mecState.mecDataFetched && (
          <SpaceBetween size="m">
            <Formik<MECAdjustmentsForm>
              innerRef={mecFormRef}
              enableReinitialize
              initialValues={mecFormInitialValues}
              validate={formikCustomValidate}
              onSubmit={handleSubmit}
            >
              {({ values, touched, errors, setFieldValue, handleSubmit, isSubmitting }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <Form>
                      <SpaceBetween size="m" direction="vertical">
                        <SpaceBetween size="m" direction="vertical">
                          <FormField label="Start Date" errorText={touched?.start_date && errors?.start_date}>
                            <DatePicker
                              onChange={({ detail }) => setFieldValue('start_date', detail.value)}
                              value={values.start_date}
                              openCalendarAriaLabel={(selectedDate) => 'Choose Date' + (selectedDate ? `, selected date is ${selectedDate}` : '')}
                              nextMonthAriaLabel="Next month"
                              placeholder="YYYY/MM/DD"
                              previousMonthAriaLabel="Previous month"
                              todayAriaLabel="Today"
                            />
                          </FormField>

                          <FormField label="End Date" errorText={touched?.end_date && errors?.end_date}>
                            <DatePicker
                              onChange={({ detail }) => setFieldValue('end_date', detail.value)}
                              value={values.end_date}
                              openCalendarAriaLabel={(selectedDate) => 'Choose Date' + (selectedDate ? `, selected date is ${selectedDate}` : '')}
                              nextMonthAriaLabel="Next month"
                              placeholder="YYYY/MM/DD"
                              previousMonthAriaLabel="Previous month"
                              todayAriaLabel="Today"
                            />
                          </FormField>

                          <SpaceBetween size="xs" direction="vertical">
                            <FormField label="Retailer Name" errorText={getValidationErrorMessage(touched?.retailer_name, errors?.retailer_name)}>
                              <Select
                                selectedOption={values.retailer_name}
                                onChange={({ detail }) => setFieldValue('retailer_name', detail.selectedOption)}
                                options={retailersDropdown()}
                                selectedAriaLabel="Selected"
                              />
                            </FormField>
                            <SpaceBetween size="xs" direction="horizontal">
                              <Checkbox onChange={({ detail }) => setFieldValue('prefix', detail.checked)} checked={values.prefix}>
                                Prefix %
                              </Checkbox>
                              <Checkbox onChange={({ detail }) => setFieldValue('suffix', detail.checked)} checked={values.suffix}>
                                Suffix %
                              </Checkbox>
                            </SpaceBetween>
                          </SpaceBetween>

                          <FormField label="Country" errorText={getValidationErrorMessage(touched?.country, errors?.country)}>
                            <Select
                              selectedOption={values.country}
                              onChange={({ detail }) => setFieldValue('country', detail.selectedOption)}
                              options={countriesDropdown(values)}
                              selectedAriaLabel="Selected"
                            />
                          </FormField>

                          <FormField label="Screen" errorText={getValidationErrorMessage(touched?.screen, errors?.screen)}>
                            <Select
                              selectedOption={values.screen}
                              onChange={({ detail }) => setFieldValue('screen', detail.selectedOption)}
                              options={screenDropdown()}
                              selectedAriaLabel="Selected"
                            />
                          </FormField>

                          <FormField label="Asin" errorText={touched?.asin && errors?.asin}>
                            <Input
                              onChange={({ detail }) => setFieldValue('asin', detail.value === '' ? null : detail.value)}
                              value={values.asin !== null ? values.asin : ''}
                            />
                          </FormField>

                          <FormField label="POS Units Adj" errorText={touched?.pos_units_adjustment && errors?.pos_units_adjustment}>
                            <Input
                              inputMode={'decimal'}
                              type={'number'}
                              onChange={({ detail }) => {
                                setFieldValue('pos_units_adjustment', detail.value === '' ? null : +detail.value);
                              }}
                              value={values.pos_units_adjustment?.toString() || ''}
                            />
                          </FormField>

                          <FormField label="POS PCT Adj" errorText={touched?.pos_pct_adjustment && errors?.pos_pct_adjustment}>
                            <Input
                              inputMode={'decimal'}
                              type={'number'}
                              onChange={({ detail }) => setFieldValue('pos_pct_adjustment', detail.value === '' ? null : +detail.value)}
                              value={values.pos_pct_adjustment?.toString() || ''}
                            />
                          </FormField>

                          <FormField label="RGU Units Adj" errorText={touched?.rgu_units_adjustment && errors?.rgu_units_adjustment}>
                            <Input
                              inputMode={'decimal'}
                              type={'number'}
                              onChange={({ detail }) => setFieldValue('rgu_units_adjustment', detail.value === '' ? null : +detail.value)}
                              value={values.rgu_units_adjustment?.toString() || ''}
                            />
                          </FormField>

                          <FormField label="RGU PCT Adj" errorText={touched?.rgu_pct_adjustment && errors?.rgu_pct_adjustment}>
                            <Input
                              inputMode={'decimal'}
                              type={'number'}
                              onChange={({ detail }) => setFieldValue('rgu_pct_adjustment', detail.value === '' ? null : +detail.value)}
                              value={values.rgu_pct_adjustment?.toString() || ''}
                            />
                          </FormField>

                          <FormField label="Adjustment Type" errorText={touched?.adjustment_type && errors?.adjustment_type}>
                            <Autosuggest
                              onChange={({ detail }) => setFieldValue('adjustment_type', detail.value)}
                              value={values.adjustment_type}
                              options={AdjustmentTypeAutoSuggestions}
                              enteredTextLabel={(value) => `Use: "${value}"`}
                              ariaLabel="Autosuggest"
                              placeholder="Enter value"
                              empty="No matches found"
                              expandToViewport
                              virtualScroll
                              disableBrowserAutocorrect={true}
                            />
                          </FormField>

                          <FormField label="Channel" errorText={getValidationErrorMessage(touched?.channel, errors?.channel)}>
                            <Select
                              selectedOption={values.channel}
                              onChange={({ detail }) => setFieldValue('channel', detail.selectedOption)}
                              options={channelDropdown()}
                              selectedAriaLabel="Selected"
                            />
                          </FormField>
                        </SpaceBetween>

                        <SpaceBetween size="m">
                          <Button variant="primary" onClick={() => handleSubmit} disabled={isSubmitting}>
                            {eventType === 'edit' ? 'Save changes' : 'Create new record'}
                          </Button>
                        </SpaceBetween>
                      </SpaceBetween>
                    </Form>
                  </form>
                );
              }}
            </Formik>
          </SpaceBetween>
        )}
      </>
    )
  };
};
