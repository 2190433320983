import { GetParameterCommand, GetParameterCommandOutput, SSMClient } from '@aws-sdk/client-ssm';
import { S3Client, GetObjectCommand, S3ClientConfig, GetObjectCommandInput, GetObjectCommandOutput } from '@aws-sdk/client-s3';
import { getCredentialsFromAWSService } from './AuthServices';
import { LicenseManager } from 'ag-grid-enterprise';
import { ENV_CONSTANTS } from 'src/utils/AuthProvider';
import { logger } from 'src/utils/FDALogger';

const AgGridLicenseKeyParameterStoreKey = '/fda-fe/ag-grid-license';
export const configureAgGridLicense = async (): Promise<string> => {
  const licenseKey = await retrieveParameterFromSSM(AgGridLicenseKeyParameterStoreKey);
  if (licenseKey) {
    LicenseManager.setLicenseKey(licenseKey);
    logger.info('Ag-Grid License configured successfully!', { logType: 'configStatus' });
    return new Promise((resolve) => resolve('setLicenseKey_Success'));
  } else {
    logger.error('Ag-Grid License setup failed!', { logType: 'configStatus' });
    return new Promise((reject) => reject('setLicenseKey_Failed'));
  }
};

/**
 * Retrieves a SecureString value from AWS Systems Manager Parameter Store.
 * @param parameterName The name of the parameter in Parameter Store.
 * @returns The string value of the parameter.
 */
export const retrieveParameterFromSSM = async (parameterName: string): Promise<string> => {
  const REGION = ENV_CONSTANTS.ENVIRONMENT_VARIABLES.Region;
  const ssmClientConfig = { region: REGION, credentials: await getCredentialsFromAWSService() };
  const ssmClient = new SSMClient(ssmClientConfig);
  const command = new GetParameterCommand({ Name: parameterName, WithDecryption: true });
  const response: GetParameterCommandOutput = await ssmClient.send(command);
  if (!response.Parameter?.Value)
    logger.error(`Unable to load Ag-Grid License key from AWS Systems Manager Parameter Store with key ${parameterName}`);
  return response.Parameter?.Value || '';
};

export async function s3Client(s3Bucket: string, s3BucketRegion: string, s3Key: string): Promise<GetObjectCommandOutput> {
  const s3ClientConfig: S3ClientConfig = {
    region: s3BucketRegion,
    credentials: await getCredentialsFromAWSService()
  };
  const client = new S3Client(s3ClientConfig);
  const commandInput: GetObjectCommandInput = {
    Bucket: s3Bucket,
    Key: s3Key
  };
  return await client.send(new GetObjectCommand(commandInput));
}
