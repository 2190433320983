import { Alert, AppLayout, Button, Container, Header, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const PageNotFound = () => {
  const [visible, setVisible] = React.useState(true);
  const navigate = useNavigate();

  const goToHomePage = () => {
    navigate({ pathname: '/' });
  };

  return (
    <>
      <AppLayout
        navigationHide={true}
        content={
          <Container disableContentPaddings>
            <Alert
              onDismiss={() => setVisible(false)}
              visible={visible}
              dismissAriaLabel="Close alert"
              header={
                <Header
                  actions={
                    <SpaceBetween size="m" direction="horizontal">
                      <Button onClick={goToHomePage}>Go to Home Page</Button>
                    </SpaceBetween>
                  }
                ></Header>
              }
            >
              Page Not Found
            </Alert>
          </Container>
        }
      ></AppLayout>
    </>
  );
};
