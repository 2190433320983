import { Alert, Button } from '@amzn/awsui-components-react';
import React from 'react';

export const ErrorFallback = (error: any, resetErrorBoundary: any) => {
  return (
    <Alert type="error" dismissible={false} visible={true} header="Error" action={<Button onClick={resetErrorBoundary}>Try again</Button>}>
      {error.message}
    </Alert>
  );
};

export const AppInitializationFailed = (error: any, resetErrorBoundary: any, customMessage: string) => {
  return (
    <Alert
      type="error"
      dismissible={false}
      visible={true}
      header="Error"
      action={<Button onClick={() => window.location.reload()}>Try again</Button>}
    >
      {error.message}
    </Alert>
  );
};
