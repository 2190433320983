import moment from 'moment-timezone';

// Returns in the format as (User Based)
// 2022-07-29T07:28:11.091Z
export const getCurrentUserLocalTime = () => {
  const localTime = moment.tz(moment.tz.guess());
  return localTime.toISOString();
};

export const getSimpleTimeFormat = () => {
  const localTime = moment.tz(moment.tz.guess());
  return localTime.format('yyyy-MM-DD HH:mm:ss');
};

// Returns in the format as (User Based)
// YYYY-MM-DD-HH-mm-ss
export const getCurrentUserLocalTimeFormat = () => {
  const localTime = moment.tz(moment.tz.guess());
  return localTime.format('YYYY-MM-DD-HH-mm-ss');
};

// Returns in the format as (User Based)
// July 29th 2022, 12:28:11 AM PDT
export const getCurrentUserLocalTimeReadableFormat = (inputValue?: string) => {
  const inputValueInMoment = moment(inputValue?.toString());
  const localTime = inputValue ? inputValueInMoment.tz(moment.tz.guess()) : moment.tz(moment.tz.guess());
  return localTime.format('MMMM Do YYYY, hh:mm:ss A z');
};

export const convertDateTimeToReadableFormat = (inputValue?: string) => {
  const inputValueInMoment = moment(inputValue?.toString());
  return inputValueInMoment.format('MMMM Do YYYY, hh:mm:ss A z');
};

// Returns in the format as
// 2022-07-29T07:28:11.091Z
export const getCurrentPSTLocal = () => {
  const localTime = moment.tz('America/Los_Angeles');
  return localTime.toISOString();
};

// Returns in the format as
// July 29th 2022, 12:28:11 AM PDT
export const getCurrentPSTLocalTimeReadableFormat = () => {
  const localTime = moment.tz('America/Los_Angeles');
  return localTime.format('MMMM Do YYYY, hh:mm:ss A z');
};

// PL Month Format
// YYYY-MM
export const getPlMonthFormat = (selectedMonth: string) => {
  return moment(selectedMonth).format('YYYY-MM');
};

// Polaris Date Format
// YYYY-MM-DD
export const getPolarisDateFormat = (value: string) => {
  return moment(value).format('YYYY-MM-DD');
};

// Local Format - Fri Jul 29 2022 00:30:11 GMT-0700
// ISO Format   - 2022-07-29T07:28:11.091Z

export const getPreviousMonthFirstDate = () => {
  return moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
};

export const isDifferenceMoreThanAMonth = (input: string) => {
  return moment(input).diff(moment(), 'months') < -1;
};

export const isValidDate = (input: any) => {
  return moment(input).isValid();
};
