import React, { useEffect, useState } from 'react';
import { Box, Spinner } from '@amzn/awsui-components-react';

export const LoadingSpinner = () => {
  return (
    <Box margin="xxl" padding="xxl" textAlign="center">
      <Spinner size="large" />
    </Box>
  );
};
