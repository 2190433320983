import { CollectionPreferencesProps, RadioGroupProps, TableProps } from '@amzn/awsui-components-react';
import { ASINDataEntity } from 'src/models/AccessoryASIN';
import { getCurrentUserLocalTimeReadableFormat } from 'src/utils/DateTimeUtils';

export const ACCESSORY_ASIN_COLUMN_DEFINITIONS: TableProps.ColumnDefinition<ASINDataEntity>[] = [
  {
    id: 'asin',
    header: 'asin',
    cell: (item) => item.asin || '',
    sortingField: 'asin',
    width: 140
  },
  {
    id: 'marketplace_id',
    header: 'marketplace id',
    cell: (item) => item.marketplace_id || '',
    sortingField: 'marketplace_id'
  },
  {
    id: 'compatible_screen',
    header: 'compatible screen',
    cell: (item) => item.compatible_screen || '',
    sortingField: 'compatible_screen'
  },
  {
    id: 'compatible_device',
    header: 'compatible device',
    cell: (item) => item.compatible_device || '',
    sortingField: 'compatible_device'
  },
  {
    id: 'item_name',
    header: 'item name',
    cell: (item) => item.item_name || '',
    sortingField: 'item_name',
    width: 140
  },
  {
    id: 'parent_asin',
    header: 'parent asin',
    cell: (item) => item.parent_asin,
    sortingField: 'parent_asin'
  },
  {
    id: 'brand',
    header: 'brand',
    cell: (item) => item.brand || '',
    sortingField: 'brand'
  },
  {
    id: 'color',
    header: 'color',
    cell: (item) => item.color || '',
    sortingField: 'color'
  },
  {
    id: 'gl',
    header: 'gl',
    cell: (item) => item.gl || '',
    sortingField: 'gl'
  },
  {
    id: 'product_line',
    header: 'product line',
    cell: (item) => item.product_line || '',
    sortingField: 'product_line'
  },
  {
    id: 'product_desc',
    header: 'product desc',
    cell: (item) => item.product_desc || '',
    sortingField: 'product_desc'
  },
  {
    id: 'accessory_program',
    header: 'accessory program',
    cell: (item) => item.accessory_program || '',
    sortingField: 'accessory_program'
  },
  {
    id: 'merchant_type',
    header: 'merchant type',
    cell: (item) => item.merchant_type || '',
    sortingField: 'merchant_type'
  },
  {
    id: 'product_category',
    header: 'product category',
    cell: (item) => item.product_category || '',
    sortingField: 'product_category'
  },
  {
    id: 'is_generic',
    header: 'is generic',
    cell: (item) => item.is_generic || '',
    sortingField: 'is_generic'
  },
  {
    id: 'is_refurb',
    header: 'is refurb',
    cell: (item) => item.is_refurb || '',
    sortingField: 'is_refurb'
  },
  {
    id: 'updated_time',
    header: 'Last Updated',
    cell: (item) => getCurrentUserLocalTimeReadableFormat(item.updated_time) || '',
    sortingField: 'updated_time'
  },
  {
    id: 'updated_user',
    header: 'Updated by',
    cell: (item) => item.updated_user || '',
    sortingField: 'updated_user'
  }
];

export const paginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page'
};

export const ACCESSORY_ASIN_CONTENT_SELECTOR_OPTIONS: CollectionPreferencesProps.VisibleContentOptionsGroup[] = [
  {
    label: 'Accessory ASIN Properties',
    options: [
      {
        id: 'asin',
        label: 'asin',
        editable: false
      },
      {
        id: 'marketplace_id',
        label: 'marketplace_id',
        editable: false
      },
      {
        id: 'compatible_screen',
        label: 'compatible_screen',
        editable: false
      },
      {
        id: 'compatible_device',
        label: 'compatible_device',
        editable: false
      },
      {
        id: 'item_name',
        label: 'item_name',
        editable: true
      },
      {
        id: 'updated_time',
        label: 'Last Updated',
        editable: true
      },
      {
        id: 'updated_user',
        label: 'Updated by',
        editable: true
      }
    ]
  }
];

export const ACCESSORY_ASIN_PAGE_SELECTOR_OPTIONS: CollectionPreferencesProps.PageSizeOption[] = [
  { value: 50, label: '50' },
  { value: 100, label: '100' },
  { value: 150, label: '150' },
  { value: 200, label: '200' }
];

export function getMatchesCountText(count: number) {
  return count === 1 ? `1 match` : `${count} matches`;
}

export const CUSTOM_PREFERENCE_OPTIONS: RadioGroupProps.RadioButtonDefinition[] = [{ value: 'table', label: 'Table' }];

export const ACCESSORY_ASIN_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences = {
  pageSize: 50,
  wrapLines: false,
  visibleContent: [
    'asin',
    'marketplace_id',
    'compatible_screen',
    'compatible_device',
    'item_name',
    'parent_asin',
    'brand',
    'color',
    'gl',
    'product_line',
    'product_desc',
    'accessory_program',
    'merchant_type',
    'product_category',
    'is_generic',
    'is_refurb'
  ],
  custom: CUSTOM_PREFERENCE_OPTIONS[0].value
};
