import React, { ReactNode, useEffect, useState } from 'react';
import { Box } from '@amzn/awsui-components-react';

interface EmptyStateProps {
  title: string;
  subtitle: string;
  action?: ReactNode;
}
export function EmptyStateMessage({ title, subtitle, action }: EmptyStateProps) {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
      <Box variant="p" padding={{ bottom: 's' }} color="inherit">
        {subtitle}
      </Box>
      {action}
    </Box>
  );
}
