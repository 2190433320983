import { Alert, Box, Button, Modal, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';

interface NBDConfirmModalProps {
  couponConfirmModal: boolean;
  onConfirmingTheCancel: () => void;
  onConfirmingTheDelete: () => void;
}
export const NBDConfirmModal = ({ couponConfirmModal, onConfirmingTheCancel, onConfirmingTheDelete }: NBDConfirmModalProps) => {
  return (
    <Modal
      visible={couponConfirmModal}
      onDismiss={onConfirmingTheCancel}
      header={'Delete record'}
      closeAriaLabel="Close dialog"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onConfirmingTheCancel}>
              Cancel
            </Button>
            <Button variant="primary" onClick={onConfirmingTheDelete}>
              Confirm
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween size="m">
        <Box variant="span">Delete coupon record permanently? This action cannot be undone.</Box>

        <Alert>
          <Box variant="span" fontWeight="bold">
            Confirm
          </Box>{' '}
          with this action will remove from the view and after{' '}
          <Box variant="span" fontWeight="bold">
            Submit
          </Box>{' '}
          request it will permanently delete from the record.
        </Alert>
      </SpaceBetween>
    </Modal>
  );
};
