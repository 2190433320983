import { BreadcrumbGroup } from '@amzn/awsui-components-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface AppBreadcrumbProps {
  items: any[];
}
export const AppBreadcrumb = ({ items }: AppBreadcrumbProps) => {
  const navigate = useNavigate();
  const onFollowEvent = (event: any) => {
    if (!event.detail.external) {
      event.preventDefault();
      navigate(event.detail.href);
    }
  };
  return (
    <BreadcrumbGroup
      items={items}
      onFollow={(event) => {
        onFollowEvent(event);
      }}
    />
  );
};
