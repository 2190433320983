import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SideNavigation, SideNavigationProps } from '@amzn/awsui-components-react';
import { useAppContext } from '../AppContextProvider';
import { AD3_LDAP_GROUP, AD3_FDA_LDAP_GROUP, FDA_PORTAL_BUSINESS_TEAM } from 'src/constants/FDAConstants';
import { LoadingStatus } from 'src/models/AppModel';
import { useAuth } from 'src/utils/AuthProvider';

export interface Navigation {
  section: string;
  type: string;
  text: string;
  href: string;
  accessibleToOnlyAdmins: boolean;
}

export const NavigationItems: Navigation[] = [
  { section: 'Admin', type: 'link', text: 'Mapping Configuration', href: '/mappings' + '/mappings-configuration', accessibleToOnlyAdmins: true }
];

export const MappingSideNavigation = () => {
  const appContext = useAppContext();
  const userDetails = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [sideNavigation, setSideNavigation] = useState<SideNavigationProps.Item[]>([]);
  const [activeRef, setActiveRef] = useState<string>('');

  useEffect(() => {
    if (appContext.contextLoadingStatus === LoadingStatus.Completed) {
      const finalList = getMappingsList();
      mapSideNavigationBasedOnUser(finalList);
    }
  }, [appContext, userDetails]);

  const getMappingsList = () => {
    const mappingsList = appContext.mappings
      ?.sort((a, b) => (a.table_alias < b.table_alias ? -1 : 1))
      .map((mapping) => {
        return {
          section: 'Mappings',
          type: 'link',
          text: mapping.table_alias,
          href: '/mappings/' + mapping.table_alias,
          accessibleToOnlyAdmins: false
        };
      }) as Navigation[];
    const finalList = [...NavigationItems, ...mappingsList];
    return finalList;
  };

  const mapSideNavigationBasedOnUser = (finalList: Navigation[]) => {
    const isAdmin = userDetails.isAdmin;
    const accessBasedNavigationItems = isAdmin ? finalList : finalList.filter((navigation) => navigation.accessibleToOnlyAdmins === false);
    const uniqueSections = accessBasedNavigationItems
      .filter((thing, i, arr) => arr.findIndex((t) => t.section === thing.section) === i)
      .map((navigation) => navigation.section);

    let navigationItems: SideNavigationProps.Item[] = uniqueSections.map((sectionName) => {
      return {
        type: 'section',
        text: sectionName,
        items: accessBasedNavigationItems
          .filter((navigationItem) => navigationItem.section === sectionName)
          .map((_navigation) => {
            return {
              type: _navigation.type,
              text: _navigation.text,
              href: _navigation.href
            } as SideNavigationProps.Item;
          })
      };
    });
    setSideNavigation(navigationItems);
  };

  useEffect(() => {
    setActiveRef(pathname.replace(/\%20/gi, ' '));
  }, [pathname]);

  return (
    <SideNavigation
      items={sideNavigation}
      activeHref={activeRef}
      onFollow={(event) => {
        if (!event.detail.external) {
          event.preventDefault();
          navigate(event.detail.href);
        }
      }}
    />
  );
};
