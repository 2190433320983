import { createContext, useContext } from 'react';
import { MECAdjustments, MecMetadata } from 'src/models/MECAdjustments';

export type MecContextType = {
  mecData: MECAdjustments[];
  setMecData: (mecData: MECAdjustments[]) => void;
  mecMetadata: MecMetadata;
};

export const MecContext = createContext<MecContextType>({ mecData: [], setMecData: (mec) => {}, mecMetadata: {} as MecMetadata });
export const useMEC = () => useContext(MecContext);
