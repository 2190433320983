import { Alert, Box, Button, Modal, SpaceBetween, StatusIndicator } from '@amzn/awsui-components-react';
import { KatFileUploadStatus } from '@amzn/katal-components/es/file-upload';
import { KatFileUpload } from '@amzn/katal-react';
import * as CouponRedemptionValidations from '../CouponRedemption/CouponRedemptionValidations';
import React, { useContext, useEffect, useState } from 'react';
import { LoadingSpinner } from 'src/components/GenericComponents/Spinner';
import { FileValidation, NBDValidation, ValidationEntity, VALIDATION_NOT_INITIATED, VALIDATION_STARTED } from 'src/constants/FDAConstants';
import { CouponsDataEntity } from 'src/models/CouponRedemptionModel';
import { AuthContextDetails } from 'src/utils/AuthProvider';
import { getCurrentUserLocalTime } from 'src/utils/DateTimeUtils';
import { getDataFromCsvFile, getHeaderFromCsvFile } from 'src/utils/FileServices';
import { isEmpty } from '@aws-amplify/core';

interface NBDCouponModalProps {
  nbdOriginalData: CouponsDataEntity[];
  showModal: boolean;
  onCancel: () => void;
  onConfirm: (nbdCouponData: CouponsDataEntity[]) => void;
}
interface FileState {
  fileUploadState: FileUploadState;
}

interface FileUploadState {
  status: KatFileUploadStatus;
  error?: string;
}

export const NBDCouponUploadModal = ({ nbdOriginalData, showModal, onCancel, onConfirm }: NBDCouponModalProps) => {
  let UPLOAD_FILE_HEADERS_FORMAT = [
    'paws_id',
    'company_code',
    'coupons_issued',
    'selling_price',
    'bundle',
    'partner_company_name',
    'program_description',
    'poc_business',
    'poc_accounting'
  ];

  const INITIAL_VALIDATION_STATUS: NBDValidation = {
    HeadersMatching: { ...VALIDATION_NOT_INITIATED, validationMessage: 'Header validation' },
    DuplicateRecords: { ...VALIDATION_NOT_INITIATED, validationMessage: 'Duplicate records validation' },
    RequiredFiledMissing: { ...VALIDATION_NOT_INITIATED, validationMessage: 'Required field validation' },
    ValidDataFields: { ...VALIDATION_NOT_INITIATED, validationMessage: 'Data validation' }
  };

  const userDetails = useContext(AuthContextDetails);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [fileValidation, setFileValidation] = useState<FileValidation>(INITIAL_VALIDATION_STATUS);
  const [file, setFile] = useState<File | null>(null);
  const [uploadingNBDFileData, setUploadingNBDFileData] = useState<CouponsDataEntity[]>([]);
  const [fileState, setFileState] = useState<FileState>({
    fileUploadState: { status: KatFileUploadStatus.READY, error: '' }
  });
  const couponCsvParseConfig = { header: true, skipEmptyLines: true };

  useEffect(() => {
    setUploadingFile(false);
    setFileValidation(INITIAL_VALIDATION_STATUS);
    setFile(null);
    setUploadingNBDFileData([]);
    setFileState({ fileUploadState: { status: KatFileUploadStatus.READY, error: '' } });
  }, [showModal]);

  const handleOnFileUpload = async (event: KatFileUpload.FilesAttachedEvent) => {
    setFile(event.detail.files[0]);
    const fileInfo = await Promise.all([
      getHeaderFromCsvFile(event.detail.files[0]),
      getDataFromCsvFile(event.detail.files[0], couponCsvParseConfig)
    ]);
    const fileHeader: string[] = fileInfo[0];
    const fileData: CouponsDataEntity[] = fileInfo[1];
    ValidateNBDCouponData(fileHeader, fileData);
  };

  const handleOnFileReplacement = async (event: KatFileUpload.FilesReplacedEvent) => {
    setFile(event.detail.newFiles[0]);
    const fileInfo = await Promise.all([
      getHeaderFromCsvFile(event.detail.newFiles[0]),
      getDataFromCsvFile(event.detail.newFiles[0], couponCsvParseConfig)
    ]);
    const fileHeader: string[] = fileInfo[0];
    const fileData: CouponsDataEntity[] = fileInfo[1];
    ValidateNBDCouponData(fileHeader, fileData);
  };

  const handleOnFileRemove = (event: KatFileUpload.FilesRemovedEvent) => {
    setFile(null);
    setUploadingNBDFileData([]);
  };

  const ValidateNBDCouponData = (fileHeader: string[], fileData: CouponsDataEntity[]) => {
    setFileValidation(INITIAL_VALIDATION_STATUS);

    validateHeaders(fileHeader).then(async (headerValidation) => {
      if (headerValidation.validationStatus === 'success') {
        setFileValidation({
          ...fileValidation,
          HeadersMatching: headerValidation,
          DuplicateRecords: { ...VALIDATION_STARTED, validationMessage: 'Validating duplicate records' },
          RequiredFiledMissing: { ...VALIDATION_STARTED, validationMessage: 'Validating required fields' },
          ValidDataFields: { ...VALIDATION_STARTED, validationMessage: 'Validating data' }
        });

        // Adding unique_id, is_locked, updated_user and updated_time to the uploaded file
        fileData.forEach((excelRow, index) => {
          excelRow.unique_id = excelRow.paws_id;
          excelRow.approval_flag = 'N';
          excelRow.updated_user = userDetails.Alias;
          excelRow.updated_time = getCurrentUserLocalTime();
        });

        setUploadingNBDFileData(fileData);

        // Validating the uploaded file data
        const validations = await Promise.all([
          validateDuplicateRecords(fileData),
          validateRequiredFields(fileData),
          validateData(fileData, nbdOriginalData)
        ]);

        // Updating the UI about the validation status
        setFileValidation({
          ...fileValidation,
          HeadersMatching: headerValidation,
          RequiredFiledMissing: validations[0],
          DuplicateRecords: validations[1],
          ValidDataFields: validations[2]
        });
      }
    });
  };

  const validateData = (fileData: CouponsDataEntity[], nbdOriginalData: CouponsDataEntity[]): Promise<ValidationEntity> => {
    let initialValidationError: ValidationEntity = {
      colorOverride: 'red',
      validationMessage: '',
      validationStatus: 'error'
    };

    return new Promise((resolve, reject) => {
      fileData.forEach((excelRow, index) => {
        let errors: any = CouponRedemptionValidations.validateCouponRedemptionRecord('upload', 'new', excelRow, nbdOriginalData, index);
        if (!isEmpty(errors)) {
          return resolve({
            ...initialValidationError,
            validationMessage: errors
          });
        }
      });
      return resolve({
        colorOverride: 'green',
        validationMessage: 'Valid data',
        validationStatus: 'success'
      });
    });
  };

  const validateRequiredFields = (fileData: CouponsDataEntity[]): Promise<ValidationEntity> => {
    return new Promise((resolve, reject) => {
      fileData.forEach((excelRow, index) => {
        Object.keys(excelRow).forEach((column) => {
          if (!excelRow[column]) {
            return resolve({
              colorOverride: 'red',
              validationMessage: column + ' is required at row ' + excelRowNumber(index),
              validationStatus: 'error'
            });
          }
        });
      });
      return resolve({
        colorOverride: 'green',
        validationMessage: 'Required Fields are valid',
        validationStatus: 'success'
      });
    });
  };

  const validateDuplicateRecords = (fileData: CouponsDataEntity[]): Promise<ValidationEntity> => {
    return new Promise((resolve, reject) => {
      fileData.forEach((excelRow, index) => {
        Object.keys(excelRow).forEach((column) => {
          if (fileData.filter((thing, i, arr) => arr.findIndex((t) => t.unique_id === thing.unique_id) === i).length != fileData.length) {
            return resolve({
              colorOverride: 'red',
              validationMessage: 'Duplicate records (PAWS_ID) found',
              validationStatus: 'error'
            });
          }
        });
      });
      return resolve({
        colorOverride: 'green',
        validationMessage: 'No duplicates found',
        validationStatus: 'success'
      });
    });
  };

  const excelRowNumber = (index: number) => index + 2;

  const validateHeaders = (fileHeader: string[]): Promise<ValidationEntity> => {
    return new Promise((resolve, reject) => {
      if (JSON.stringify(UPLOAD_FILE_HEADERS_FORMAT) !== JSON.stringify(fileHeader)) {
        setFileState({ fileUploadState: { status: KatFileUploadStatus.ERROR, error: "Headers didn't match" } });
        return resolve({
          colorOverride: 'red',
          validationMessage: "Headers didn't match",
          validationStatus: 'error'
        });
      } else
        return resolve({
          colorOverride: 'green',
          validationMessage: 'Valid File',
          validationStatus: 'success'
        });
    });
  };

  const isValidFile = () => {
    return (
      fileValidation.DuplicateRecords.validationStatus === 'success' &&
      fileValidation.HeadersMatching.validationStatus === 'success' &&
      fileValidation.RequiredFiledMissing.validationStatus === 'success' &&
      fileValidation.ValidDataFields.validationStatus === 'success'
    );
  };

  const saveToTable = () => {
    setUploadingFile(true);
    onConfirm(uploadingNBDFileData);
  };

  return (
    <Modal
      visible={showModal}
      onDismiss={onCancel}
      header={'Upload a .csv NBD Coupon data'}
      closeAriaLabel="Close dialog"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onCancel}>
              Cancel
            </Button>
            <Button variant="primary" disabled={!isValidFile() || uploadingFile} onClick={() => saveToTable()}>
              Save to table
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween size="m" direction="vertical">
        {!uploadingFile && (
          <KatFileUpload
            accept=".csv, text/csv"
            file-view="list"
            variant="small"
            required
            disabled={uploadingFile}
            multiple={false}
            state={fileState}
            onFilesAttached={(e) => handleOnFileUpload(e)}
            onFilesReplaced={(e) => handleOnFileReplacement(e)}
            onFilesRemoved={(e) => handleOnFileRemove(e)}
          >
            <div slot="hint">{'Accepted file format: .csv'}</div>
            <div slot="constraints-message">{'Accepted file format: .csv'}</div>
            <div slot="error">{fileState.fileUploadState.error}</div>
          </KatFileUpload>
        )}
        {uploadingFile && <LoadingSpinner />}
        <div>
          <SpaceBetween size="m" direction="vertical">
            {Object.keys(fileValidation).map((key, index) => {
              return (
                <StatusIndicator key={index} type={fileValidation[key].validationStatus} colorOverride={fileValidation[key].colorOverride}>
                  {JSON.stringify(fileValidation[key].validationMessage)}
                </StatusIndicator>
              );
            })}
          </SpaceBetween>
        </div>
      </SpaceBetween>
    </Modal>
  );
};
