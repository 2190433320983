export const FDA_BACKEND_SERVICE = 'FDAService';
export const PROJECTS_METADATA_API = '/midway?operation_name=getFDAmetadatarecs';
export const PROJECT_DATA = '/midway?operation_name=getFDAprojrecs';
export const MEC_DATA = '/midway?operation_name=getMecAdjRecs';
export const POST_MEC_DATA = '/midway?operation_name=postMecAdjRecs';
export const POST_APPROVED_MEC_DATA = '/midway?operation_name=postMecAdjApproveRecs';
export const CLEAR_APPROVED_MEC_DATA = '/midway?operation_name=postMecAdjClearAllRecs';
export const MAP_MEC_APPROVED_MEC_DATA = '/midway?operation_name=postMecAdjustprocrecs';

export const MEC_RETAIL_SCREEN_COUNTRY = '/midway?operation_name=getRetailerScreenCountry';
export const ACCESSORY_ASIN_REDSHIFT_SYNC = '/midway?operation_name=postFDAprojredshifttrigger';
export const PROJECT_DATA_SUBMIT = '/midway?operation_name=postFDAprojrecs';

export const USER_MAPPINGS = '/midway?operation_name=getUsermapings';
export const MAPPINGS_DATA = '/midway?operation_name=getMapdata';
export const SUBMIT_MAPPINGS_DATA = '/midway?operation_name=postMapdata';
