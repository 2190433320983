import React from 'react';
import { Box, Button, HelpPanel, Icon, Link, Popover, StatusIndicator } from '@amzn/awsui-components-react';
import { NBD_SKYNET_REPORT_LINK } from 'src/constants/FDAConstants';

export const CouponMoreInfoToolBar = () => {
  return (
    <HelpPanel
      header={<h2>More Info</h2>}
      footer={
        <div>
          <h3>
            Useful Links <Icon name="external" />
          </h3>
          <ul>
            <li>
              <Link external externalIconAriaLabel="Opens in a new tab" href={NBD_SKYNET_REPORT_LINK}>
                Skynet Dashboard
              </Link>
            </li>
          </ul>
        </div>
      }
    >
      <h3>Description</h3>
      <p>
        This portal is used to take NBD PAWS information from the business, so FDA team can merge to report it on the BIGFOOT_NBD_Coupon Redemption
        Skynet Dashboard.
      </p>
    </HelpPanel>
  );
};

const copyToClipboard = (textToBeCopied: string) => {
  return (
    <span className="custom-wrapping">
      <Box margin={{ right: 'xxs' }} display="inline-block">
        <Popover
          size="small"
          position="top"
          triggerType="custom"
          dismissButton={false}
          content={<StatusIndicator type="success">copied</StatusIndicator>}
        >
          <Button
            variant="inline-icon"
            iconName="copy"
            onClick={() => {
              navigator.clipboard.writeText(textToBeCopied);
            }}
          />
        </Popover>
      </Box>
      <code>
        <b>{textToBeCopied}</b>
      </code>
    </span>
  );
};
