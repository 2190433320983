import { CellClassParams, ValueFormatterParams } from 'ag-grid-community';
import { isValidDate } from 'src/utils/DateTimeUtils';

const isNull = (value: any) => {
  return value === null;
};

export const nanCellClassRules = {
  'nan-cell': (params: CellClassParams) => isNaN(params.value)
};

export const nonDateCellClassRules = {
  'non-date-cell': (params: CellClassParams) => !isValidDate(params.value)
};

export const numberParser = (value: any) => {
  return Number(value);
};

export const dateParser = (value: any) => {
  return value;
};
