import React from 'react';
import { Badge, CollectionPreferencesProps, RadioGroupProps, StatusIndicator, TableProps } from '@amzn/awsui-components-react';
import { CouponsDataEntity } from 'src/models/CouponRedemptionModel';
import { getCurrentUserLocalTimeReadableFormat } from 'src/utils/DateTimeUtils';

export const NBD_COUPON_COLUMN_DEFINITIONS: TableProps.ColumnDefinition<CouponsDataEntity>[] = [
  {
    id: 'approval_flag',
    header: 'Approval Status',
    cell: (item) =>
      (
        <StatusIndicator colorOverride={item.approval_flag === 'Y' ? 'green' : 'blue'} type={item.approval_flag === 'Y' ? 'success' : 'pending'}>
          {item.approval_flag === 'Y' ? 'Approved' : 'Pending'}
        </StatusIndicator>
      ) || '',
    sortingField: 'approval_flag'
  },
  {
    id: 'paws_id',
    header: 'PAWS ID',
    cell: (item) => item.paws_id || '',
    sortingField: 'paws_id'
  },
  {
    id: 'company_code',
    header: 'Company Code',
    cell: (item) => item.company_code || '',
    sortingField: 'company_code'
  },
  {
    id: 'coupons_issued',
    header: 'Coupons Issued',
    cell: (item) => item.coupons_issued || '',
    sortingField: 'coupons_issued'
  },
  {
    id: 'selling_price',
    header: 'Selling Price',
    cell: (item) => item.selling_price || '',
    sortingField: 'selling_price'
  },
  {
    id: 'bundle',
    header: 'Bundle',
    cell: (item) => item.bundle || '',
    sortingField: 'bundle'
  },
  {
    id: 'partner_company_name',
    header: 'Partner Company Name',
    cell: (item) => item.partner_company_name,
    sortingField: 'partner_company_name'
  },
  {
    id: 'program_description',
    header: 'Program Description',
    cell: (item) => item.program_description || '',
    sortingField: 'program_description'
  },
  {
    id: 'poc_business',
    header: 'Business Poc Alias',
    cell: (item) => item.poc_business || '',
    sortingField: 'poc_business'
  },
  {
    id: 'poc_accounting',
    header: 'Accounting Poc Alias',
    cell: (item) => item.poc_accounting || '',
    sortingField: 'poc_accounting'
  },
  {
    id: 'updated_time',
    header: 'Last Updated at',
    cell: (item) => getCurrentUserLocalTimeReadableFormat(item.updated_time) || '',
    sortingField: 'updated_time'
  },
  {
    id: 'updated_user',
    header: 'Submitter Alias',
    cell: (item) => item.updated_user || '',
    sortingField: 'updated_user'
  }
];

export const paginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page'
};

export const NBD_COUPON_CONTENT_SELECTOR_OPTIONS: CollectionPreferencesProps.VisibleContentOptionsGroup[] = [
  {
    label: 'NBD Coupon Redemption Properties',
    options: []
  }
];

export function getMatchesCountText(count: number) {
  return count === 1 ? `1 match` : `${count} matches`;
}

export const CUSTOM_PREFERENCE_OPTIONS: RadioGroupProps.RadioButtonDefinition[] = [{ value: 'table', label: 'Table' }];

export const DEFAULT_VISIBLE_CONTENT = [
  'paws_id',
  'company_code',
  'coupons_issued',
  'selling_price',
  'bundle',
  'partner_company_name',
  'program_description',
  'poc_business',
  'poc_accounting',
  'updated_time',
  'updated_user'
];
export const NBD_COUPON_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences = {
  pageSize: 150,
  visibleContent: DEFAULT_VISIBLE_CONTENT,
  wrapLines: false,
  custom: CUSTOM_PREFERENCE_OPTIONS[0].value
};
