import React, { useEffect, useRef, useState, useContext } from 'react';
import { AppLayoutProps, Box, Button, Form, FormField, Input, NonCancelableCustomEvent, Select, SpaceBetween } from '@amzn/awsui-components-react';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { useNumberPref, useObjectPref } from '../../../utils/UserPrefs';
import * as CouponRedemptionValidations from '../CouponRedemption/CouponRedemptionValidations';
import { AuthContextDetails } from 'src/utils/AuthProvider';
import { ProjectsEntity } from 'src/models/AccessoryASIN';
import { CouponsDataEntity } from 'src/models/CouponRedemptionModel';
import { getCurrentUserLocalTime } from 'src/utils/DateTimeUtils';

export function useNBDSplitPanelProps() {
  const [splitPanelPrefs, setSplitPanelPrefs] = useObjectPref<AppLayoutProps.SplitPanelPreferences>(location.pathname, 'splitPanelPrefs', {
    position: 'side'
  });
  const [splitPanelSize, setSplitPanelSize] = useNumberPref(location.pathname, 'splitPanelSize');
  const [splitPanelOpen, setSplitPanelOpen] = useState<boolean>(false);

  const onSplitPanelResize = (event: NonCancelableCustomEvent<AppLayoutProps.SplitPanelResizeDetail>) => {
    setSplitPanelSize(event.detail.size);
  };

  const onSplitPanelPreferencesChange = (event: NonCancelableCustomEvent<AppLayoutProps.SplitPanelPreferences>) => {
    setSplitPanelPrefs(event.detail);
  };

  const onSplitPanelToggle = (event: NonCancelableCustomEvent<AppLayoutProps.ChangeDetail>) => {
    setSplitPanelOpen(event.detail.open);
  };

  const splitPanelOpenByEdit = (splitPanelOpen: boolean) => {
    setSplitPanelOpen(splitPanelOpen);
  };

  useEffect(() => {
    let updatedSplitPanelPrefs: AppLayoutProps.SplitPanelPreferences = { position: 'side' };
    setSplitPanelPrefs(updatedSplitPanelPrefs);
  }, []);

  return {
    splitPanelPrefs,
    onSplitPanelResize,
    onSplitPanelPreferencesChange,
    splitPanelOpen,
    splitPanelOpenByEdit,
    onSplitPanelToggle
  };
}

export const couponRedemptionPanel = (
  selectedItems: CouponsDataEntity,
  nbdCouponData: CouponsDataEntity[],
  type: 'single' | 'multiple',
  projectMetadata: ProjectsEntity,
  eventType: 'edit' | 'new',
  updateTheTable: (formValues: CouponsDataEntity) => void
) => {
  if (type === 'single') {
    return NBDSplitPanelContent(selectedItems, nbdCouponData, projectMetadata, eventType, updateTheTable);
  } else {
    return EMPTY_PANEL_CONTENT;
  }
};

export const getPanelContentMultiple = (selectedItems: CouponsDataEntity[]) => {
  return {
    header: '',
    body: (
      <SpaceBetween size="s" direction="vertical">
        <Box variant="h4">Select only 1 row</Box>;
      </SpaceBetween>
    )
  };
};

export const EMPTY_PANEL_CONTENT = {
  header: 'Not selected!',
  body: 'Select a row to edit.'
};

const InitialValues: CouponsDataEntity = {
  unique_id: '',
  paws_id: '',
  company_code: '',
  coupons_issued: '',
  selling_price: '',
  bundle: '',
  partner_company_name: '',
  program_description: '',
  poc_business: '',
  poc_accounting: '',
  approval_flag: '',
  updated_time: '',
  updated_user: ''
};

export const NBDSplitPanelContent = (
  selectedItems: CouponsDataEntity,
  nbdCouponData: CouponsDataEntity[],
  projectMetadata: ProjectsEntity,
  eventType: 'edit' | 'new',
  updateTheTable: (formValues: CouponsDataEntity) => void
) => {
  const userContext = useContext(AuthContextDetails);
  const couponFormRef = useRef<FormikProps<CouponsDataEntity>>(null);
  const [nbdFormInitialValues, setNBDFormInitialValues] = useState<CouponsDataEntity>(InitialValues);

  const handleSubmit = (formValues: CouponsDataEntity, formikHelpers: FormikHelpers<CouponsDataEntity>) => {
    updateTheTable(formValues);
    formikHelpers.setSubmitting(false);
  };

  const formikCustomValidate = (formValues: CouponsDataEntity | undefined) => {
    if (!formValues) return;
    return CouponRedemptionValidations.validateCouponRedemptionRecord('form', eventType, formValues, nbdCouponData);
  };

  useEffect(() => {
    couponFormRef.current?.resetForm();
    if (eventType === 'edit') {
      setNBDFormInitialValues({
        unique_id: selectedItems.unique_id,
        paws_id: selectedItems.paws_id,
        company_code: selectedItems.company_code,
        coupons_issued: selectedItems.coupons_issued,
        selling_price: selectedItems.selling_price,
        bundle: selectedItems.bundle,
        partner_company_name: selectedItems.partner_company_name,
        program_description: selectedItems.program_description,
        poc_business: selectedItems.poc_business,
        poc_accounting: selectedItems.poc_accounting,
        approval_flag: 'N',
        updated_time: getCurrentUserLocalTime(),
        updated_user: userContext.Alias
      });
    } else {
      let newObj = {
        ...InitialValues,
        approval_flag: 'N',
        updated_time: getCurrentUserLocalTime(),
        updated_user: userContext.Alias
      };
      setNBDFormInitialValues(newObj);
    }
  }, [eventType, selectedItems]);

  const updateUniqueKey = (formValues: CouponsDataEntity) => {
    couponFormRef.current?.setFieldValue('unique_id', formValues.paws_id, true);
  };

  return {
    header: eventType.toUpperCase(),
    body: (
      <>
        <SpaceBetween size="m">
          <Formik<CouponsDataEntity>
            innerRef={couponFormRef}
            enableReinitialize
            initialValues={nbdFormInitialValues}
            validate={formikCustomValidate}
            onSubmit={handleSubmit}
          >
            {({ values, touched, errors, setFieldValue, handleSubmit, isSubmitting }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Form>
                    <SpaceBetween size="xl" direction="vertical">
                      <SpaceBetween size="m" direction="vertical">
                        <FormField label="PAWS ID" errorText={errors.paws_id}>
                          <Input
                            disabled={eventType === 'edit'}
                            value={values.paws_id}
                            onChange={(event) => setFieldValue('paws_id', event.detail.value)}
                            onBlur={() => updateUniqueKey(values)}
                          />
                        </FormField>

                        <FormField label="Company Code" errorText={errors.company_code}>
                          <Input value={values.company_code} onChange={(event) => setFieldValue('company_code', event.detail.value)} />
                        </FormField>

                        <FormField label="Coupons Issued" errorText={errors.coupons_issued}>
                          <Input value={values.coupons_issued} onChange={(event) => setFieldValue('coupons_issued', event.detail.value)} />
                        </FormField>

                        <FormField label="Selling Price" errorText={errors.selling_price}>
                          <Input value={values.selling_price} onChange={(event) => setFieldValue('selling_price', event.detail.value)} />
                        </FormField>

                        <FormField label="Bundle" errorText={errors.bundle}>
                          <Input value={values.bundle} onChange={(event) => setFieldValue('bundle', event.detail.value)} />
                        </FormField>

                        <FormField label="Partner Company Name" errorText={errors.partner_company_name}>
                          <Input
                            value={values.partner_company_name}
                            onChange={(event) => setFieldValue('partner_company_name', event.detail.value)}
                          />
                        </FormField>

                        <FormField label="Program Description" errorText={errors.program_description}>
                          <Input value={values.program_description} onChange={(event) => setFieldValue('program_description', event.detail.value)} />
                        </FormField>

                        <FormField label="Poc Business" errorText={errors.poc_business}>
                          <Input value={values.poc_business} onChange={(event) => setFieldValue('poc_business', event.detail.value)} />
                        </FormField>

                        <FormField label="Poc Accounting" errorText={errors.poc_accounting}>
                          <Input value={values.poc_accounting} onChange={(event) => setFieldValue('poc_accounting', event.detail.value)} />
                        </FormField>
                      </SpaceBetween>

                      <SpaceBetween size="l">
                        <Button variant="primary" onClick={() => handleSubmit} disabled={isSubmitting}>
                          {eventType === 'edit' ? 'Save changes' : 'Create new record'}
                        </Button>
                      </SpaceBetween>
                    </SpaceBetween>
                  </Form>
                </form>
              );
            }}
          </Formik>
        </SpaceBetween>
      </>
    )
  };
};
