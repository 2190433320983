import {
  AppLayout,
  Box,
  Button,
  Container,
  DatePicker,
  Flashbar,
  FlashbarProps,
  FormField,
  Grid,
  Header,
  SpaceBetween,
  SplitPanel,
  StatusIndicator,
  Toggle
} from '@amzn/awsui-components-react';

import React, { createContext, useContext, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useAppContext } from 'src/components/AppContextProvider';
import { ErrorFallback } from 'src/components/GenericComponents/ErrorFallback';
import { MEC_ADJUSTMENTS_TITLE, SPLIT_PANEL_I18NSTRINGS } from 'src/constants/FDAConstants';
import { ProjectMetadata, ProjectsEntity } from 'src/models/AccessoryASIN';
import { LoadingStatus } from 'src/models/AppModel';
import { MECAdjustments, MecMetadata, MecPost } from 'src/models/MECAdjustments';
import {
  clearMECMetadata,
  fetchProjectMetadata,
  getMECMetadata,
  getMECMonthData,
  mapMECAdjustmentData,
  postMECMetadata
} from 'src/services/FDAServices';
import updateAppTitle from 'src/services/PortalTitleService';
import { AuthContextDetails } from 'src/utils/AuthProvider';
import { getCurrentUserLocalTimeReadableFormat, getPlMonthFormat, isDifferenceMoreThanAMonth } from 'src/utils/DateTimeUtils';
import { logger } from 'src/utils/FDALogger';
import { mecDataEditPanel, useSplitPanelProps } from './MecAdjustmentsSplitPanel';
import { ConfirmActionType, MECConfirmModal } from './MecConfirmModal';
import { MecContext } from './MecContext';
import { MecTable } from './MecTable';
import { MecToolBar } from './MecToolBar';

export interface MECState {
  mecValue: string;
  mecDataLoading: boolean;
  mecDataFetched: boolean;
  mecDataChanged: boolean;
  mecDataSubmitInProgress: boolean;
  mecApprovalMode: boolean;
}

export const MecAdjustment = () => {
  // Updates browser tab title
  updateAppTitle(MEC_ADJUSTMENTS_TITLE);
  const userDetails = useContext(AuthContextDetails);
  const appContext = useAppContext();
  const [projectMetadata, setProjectMetadata] = useState<ProjectsEntity>({} as ProjectsEntity);

  const [mecPlMonth, setMecPlMonth] = useState('');

  const [mecData, setMecData] = useState<MECAdjustments[]>([]);
  const [mecMetadata, setMecMetaData] = useState<MecMetadata>({} as MecMetadata);
  const [splitPanelRow, setSplitPanelRow] = useState<MECAdjustments>();

  const [loadingMECs, setLoadingMECs] = useState<boolean>(false);
  const [deleteMECIds, setDeleteMECIds] = useState<string[]>([]);
  // const [mecApprovalMode, setMecApprovalMode] = useState(false);

  const [toolsOpen, setToolsOpen] = useState(false);
  const [eventType, setEventType] = useState<'edit' | 'new'>('new');

  const [flashbarItems, setFlashbarItems] = useState<FlashbarProps.MessageDefinition[]>([]);

  const [mecConfirmModal, setMecConfirmModal] = useState(false);
  const [actionType, setActionType] = useState<ConfirmActionType>('delete');

  const [mecState, setMecState] = useState<MECState>({
    mecValue: '',
    mecDataLoading: false,
    mecDataFetched: false,
    mecDataChanged: false,
    mecDataSubmitInProgress: false,
    mecApprovalMode: false
  });

  const {
    splitPanelPrefs,
    splitPanelSize,
    splitPanelOpen,
    onSplitPanelPreferencesChange,
    onSplitPanelResize,
    onSplitPanelToggle,
    splitPanelOpenByEdit
  } = useSplitPanelProps();

  const updateTheTable = (updateRow: MECAdjustments) => {
    if (eventType === 'edit') {
      const updatedMecData = mecData.map((mecRow) => {
        if (mecRow.mec_id === updateRow.mec_id) {
          return { ...updateRow };
        } else {
          return mecRow;
        }
      });
      setMecState({ ...mecState, mecDataChanged: true });
      setMecData(updatedMecData);
      splitPanelOpenByEdit(false);
    } else {
      mecData.splice(0, 0, updateRow);
      setMecState({ ...mecState, mecDataChanged: true });
      splitPanelOpenByEdit(false);
    }
  };

  const { header: panelHeader, body: panelBody } = mecDataEditPanel('single', mecMetadata, eventType, splitPanelRow, updateTheTable, mecState);

  // Updates on Projects Metadata Information changed
  useEffect(() => {
    if (appContext.contextLoadingStatus === LoadingStatus.Completed) {
      fetchProjectMetadataInformation();
    }
  }, [appContext.contextLoadingStatus]);

  // Fetches the Project metadata
  const fetchProjectMetadataInformation = () => {
    fetchProjectMetadata().then(({ projects: ProjectsEntity }: ProjectMetadata) => {
      updateProjectMetadata(ProjectsEntity);
    });
  };

  // Updates the MEC metadata information accordingly
  const updateProjectMetadata = (projectsEntity: ProjectsEntity[] | []) => {
    const accessoryProjectMetadata = projectsEntity.find((data) => data.table_alias === 'mec customer');
    if (accessoryProjectMetadata) {
      setProjectMetadata(accessoryProjectMetadata);
    } else {
      displayFlashMessage('MEC metadata not found', 'error');
    }
  };

  // Fetches MEC metadata
  useEffect(() => {
    const fetchingMetadata = async () => {
      getMECMetadata()
        .then((mecMetadataResponse: MecMetadata) => {
          setMecMetaData(mecMetadataResponse);
        })
        .catch((err) => {
          logger.error(err);
        });
    };
    fetchingMetadata();
  }, []);

  const editData = (editRow: MECAdjustments) => {
    setEventType('edit');
    setSplitPanelRow(editRow);
    splitPanelOpenByEdit(true);
  };

  const newData = () => {
    setEventType('new');
    setSplitPanelRow(undefined);
    splitPanelOpenByEdit(true);
  };

  const deleteRow = (deleteRow: MECAdjustments) => {
    const updatedMecData = mecData.filter((mecRow) => mecRow.mec_id !== deleteRow.mec_id);
    setMecState({ ...mecState, mecDataChanged: true });
    setMecData(updatedMecData);
  };

  const resetMec = () => {
    splitPanelOpenByEdit(false);
    getMECData(mecState.mecValue);
  };

  // Fetches the MEC data based on month selection
  const getMECData = async (selectedPL: string) => {
    setMecState({ ...mecState, mecDataLoading: true });
    getMECMonthData(getPlMonthFormat(selectedPL))
      .then((response: MECAdjustments[]) => {
        logger.info(`Fetched MEC data for the Pl Month of ${getPlMonthFormat(selectedPL)}`);
        setMecData(response);
        setMecState({
          ...mecState,
          mecValue: getPlMonthFormat(selectedPL),
          mecDataLoading: false,
          mecDataChanged: false,
          mecDataFetched: true
        });
      })
      .catch(() => {
        setMecState({ ...mecState, mecDataFetched: false, mecDataLoading: false });
        logger.error(`Unable to fetch MEC data for the Pl Month of ${getPlMonthFormat(selectedPL)}`);
        displayFlashMessage(`Unable to fetch MEC data for the Pl Month of ${getPlMonthFormat(selectedPL)}`, 'error');
      });
  };

  const updateMecState = (state: MECState) => {
    setMecState(state);
  };

  const displayFlashMessage = (content: string, flashBarType: FlashbarProps.Type) => {
    setFlashbarItems([
      {
        type: flashBarType,
        content: content,
        dismissible: true,
        dismissLabel: 'Dismiss message',
        onDismiss: () => setFlashbarItems([])
      }
    ]);
  };

  const dateSelectionChanged = (detail: any) => {
    setMecPlMonth(detail.value);
    getMECData(detail.value);
  };

  const submitMECData = () => {
    setMecState({ ...mecState, mecDataSubmitInProgress: true });
    displayFlashMessage('Submitting the request.', 'info');
    let postBody: MecPost = {
      pl_month: getPlMonthFormat(mecPlMonth),
      mec_records: mecData
    };
    logger.info(`Submitted MEC data for the Pl Month of ${getPlMonthFormat(mecPlMonth)}`);
    postMECMetadata(postBody)
      .then(() => {
        logger.info(`Request submitted successfully for the Pl Month of ${getPlMonthFormat(mecPlMonth)}`);
        displayFlashMessage('Request submitted successfully.', 'success');
        getMECData(mecPlMonth);
        fetchProjectMetadataInformation();
      })
      .catch(() => {
        logger.error(`Unable to submit request for the Pl Month of ${getPlMonthFormat(mecPlMonth)}`);
        displayFlashMessage('Unable to submit request.', 'error');
      });
  };

  const changeApprovalMode = (detail: any) => {
    setMecState({ ...mecState, mecApprovalMode: detail.checked });
  };

  const onConfirm = (actionType: ConfirmActionType) => {
    if (actionType === 'clearPostMec') {
      clearPostMEC();
    } else {
      mapMecEntries();
    }
  };

  const onCancel = () => {
    setMecConfirmModal(false);
  };

  const clearPostMEC = () => {
    setMecConfirmModal(false);
    clearMECMetadata(mecState.mecValue)
      .then(() => {
        logger.info(`clearPostMEC - Request submitted successfully for the Pl Month of ${getPlMonthFormat(mecState.mecValue)}`);
        displayFlashMessage('Request submitted successfully.', 'success');
      })
      .catch((err: any) => {
        logger.error(`clearPostMEC - Unable to submit request for the Pl Month of ${getPlMonthFormat(mecState.mecValue)}`, { error: err });
        displayFlashMessage('Unable to submit request.', 'error');
      });
  };

  const mapMecEntries = () => {
    setMecConfirmModal(false);
    mapMECAdjustmentData(mecState.mecValue)
      .then(() => {
        logger.info(`mapMecEntries - Request submitted successfully for the Pl Month of ${getPlMonthFormat(mecState.mecValue)}`);
        displayFlashMessage('Request submitted successfully.', 'success');
      })
      .catch((err) => {
        logger.error(`mapMecEntries - Unable to submit request for the Pl Month of ${getPlMonthFormat(mecState.mecValue)}`, { error: err });
        displayFlashMessage('Unable to submit request.', 'error');
      });
  };

  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => window.location.reload()}>
        <MECConfirmModal
          mecConfirmModal={mecConfirmModal}
          onConfirmingTheCancel={onCancel}
          onConfirmingTheDelete={(action) => onConfirm(action)}
          confirmActionType={actionType}
        ></MECConfirmModal>
        <AppLayout
          navigationHide={true}
          tools={<MecToolBar />}
          toolsOpen={toolsOpen}
          onToolsChange={({ detail }) => setToolsOpen(detail.open)}
          stickyNotifications={true}
          notifications={<Flashbar items={flashbarItems} />}
          splitPanelOpen={splitPanelOpen}
          splitPanelPreferences={splitPanelPrefs}
          onSplitPanelPreferencesChange={onSplitPanelPreferencesChange}
          onSplitPanelToggle={onSplitPanelToggle}
          onSplitPanelResize={onSplitPanelResize}
          splitPanelSize={300}
          splitPanel={
            <SplitPanel className="fda-split-panel" hidePreferencesButton header={panelHeader} i18nStrings={SPLIT_PANEL_I18NSTRINGS}>
              {panelBody}
            </SplitPanel>
          }
          maxContentWidth={Number.MAX_VALUE}
          contentType="default"
          content={
            <>
              <MecContext.Provider value={{ mecData, setMecData, mecMetadata }}>
                <SpaceBetween size="m" direction="vertical">
                  <Container
                    header={
                      <Header
                        actions={
                          <SpaceBetween size="m" direction="horizontal">
                            <Button
                              disabled={
                                isDifferenceMoreThanAMonth(mecPlMonth) ||
                                !mecState.mecApprovalMode ||
                                !userDetails.isAdmin ||
                                mecState.mecDataSubmitInProgress ||
                                mecState.mecDataLoading ||
                                !mecState.mecDataFetched
                              }
                              onClick={() => {
                                setActionType('clearPostMec');
                                setMecConfirmModal(true);
                              }}
                            >
                              Clear Post Month Entries
                            </Button>
                            <Button
                              disabled={
                                isDifferenceMoreThanAMonth(mecPlMonth) ||
                                !mecState.mecApprovalMode ||
                                !userDetails.isAdmin ||
                                mecState.mecDataSubmitInProgress ||
                                mecState.mecDataLoading ||
                                !mecState.mecDataFetched
                              }
                              onClick={() => {
                                setActionType('mapMecToProduction');
                                setMecConfirmModal(true);
                              }}
                            >
                              Map MEC Entries to Monthly Production
                            </Button>
                          </SpaceBetween>
                        }
                      >
                        <Box fontWeight="normal" variant="h2">
                          {MEC_ADJUSTMENTS_TITLE}
                        </Box>
                      </Header>
                    }
                  >
                    <Grid gridDefinition={[{ colspan: 4 }, { colspan: 2 }]}>
                      <FormField label="PL Month">
                        <DatePicker
                          expandToViewport
                          onChange={({ detail }) => {
                            dateSelectionChanged(detail);
                          }}
                          value={mecPlMonth}
                          openCalendarAriaLabel={(selectedDate) => 'Choose Date' + (selectedDate ? `, selected date is ${selectedDate}` : '')}
                          nextMonthAriaLabel="Next month"
                          placeholder="YYYY/MM/DD"
                          isDateEnabled={(date) => date.getDate() === 1} // Enabling only start of the month
                          previousMonthAriaLabel="Previous month"
                          todayAriaLabel="Today"
                        />
                      </FormField>
                      <div>
                        {userDetails.isAdmin && (
                          <Toggle
                            onChange={({ detail }) => {
                              changeApprovalMode(detail);
                            }}
                            checked={mecState.mecApprovalMode}
                            disabled={isDifferenceMoreThanAMonth(mecPlMonth) || splitPanelOpen || !mecState.mecDataFetched}
                          >
                            {mecState.mecApprovalMode ? 'Approval Mode' : 'Normal Mode'}
                          </Toggle>
                        )}
                      </div>
                    </Grid>
                  </Container>
                  <Container disableContentPaddings>
                    <MecTable
                      mecState={mecState}
                      updateMecState={(newState) => updateMecState(newState)}
                      submitMECData={submitMECData}
                      editData={editData}
                      newData={newData}
                      deleteRow={deleteRow}
                      displayFlashMessage={displayFlashMessage}
                      mecMetadata={mecMetadata}
                      resetMec={resetMec}
                      splitPanelOpen={splitPanelOpen}
                    ></MecTable>
                  </Container>
                </SpaceBetween>
              </MecContext.Provider>
            </>
          }
        />
      </ErrorBoundary>
    </>
  );
};
